<div class="row message">
  <!-- Client only code -->
  <div class="container">
    <ngb-alert *ngIf="successMessage && clientOnlyRun" (click)="alertClicked()" type="success" (close)="alertClicked();">
      <i class="fa fa-check-circle fa-3x" aria-hidden="true"></i> {{ successMessage }}
        <button class="actionButton transparentButton" [routerLink]="['/compra/carrito']">VER TODOS</button>
    </ngb-alert>
  </div>
</div>
<div class="container paquete">
  <!-- Client only code -->
  <div class="paquete__header" style="position: relative;" *ngIf="clientOnlyRun">
    <h1 [ngStyle]="{'max-width': paquete.oferta == false && paquete.novedad == false ? 'unset' : 'calc(100% - 60px)' }">{{paquete["titulo"] + ', ' + paquete["titulo"] + ' al por mayor'}}</h1>
  </div>
  <!-- Server only code -->
  <div class="paquete__header" style="position: relative;" *ngIf="serverOnlyRun">
    <h1 style="max-width: unset;">{{ tituloPaquete }}</h1>
  </div>
  <br>
  <div class="row">
    <div class="badges" style="z-index: 9;">
      <div *ngIf="paquete.oferta == true" class="oferta">
        <h3 class="badge">OFERTA DEL MES</h3>
      </div>
      <!-- Novedades -->
      <div *ngIf="paquete.novedad == true" class="imperdible">
        <h3 class="badge">NOVEDAD</h3>
      </div>
    </div>
    <!-- Client only code -->
    <div class="col-12 col-md-6 imagen__visor position-relative" style="display: flex;justify-content: center;" *ngIf="clientOnlyRun">
      <!-- Imagen unica -->
      <img *ngIf="!(paquete.imagenes && paquete.imagenes.length > 0) && !paquete.youtubeId" width="450" height="300" style="max-width: 100%; height: max-content;" [ngClass]="'lazyload'" [attr.data-src]="paquete['urlImagen']" onerror="this.src='/assets/images/producto/picGenerica.webp'" alt="Foto">
      <div *ngIf="(paquete.imagenes && paquete.imagenes.length > 0) || paquete.youtubeId" class="w-100 d-flex flex-column gap-2 paquete__carousel">
        <!-- SwiperJS -->
        <!-- TODO: Tal vez deberiamos mostrar la foto principal de cada insumo en este carrusel -->
        <swiper-container
          #swipercarouselsinapaquete
          id="paquete-swiper-imagenes"
          init="false"
          class="w-100"
        >
          <swiper-slide class="d-flex justify-content-center align-items-center" style="height: 93vw; max-height: 450px;">
            <img width="450" height="300" *ngIf="paquete['urlImagen']" style="max-width: 100%; height: auto;" [ngClass]="'lazyload'" [attr.data-src]="paquete['urlImagen']" onerror="this.src='/assets/images/producto/picGenerica.webp'" alt="Foto">
            <img width="450" height="300" *ngIf="!paquete['urlImagen']" style="max-width: 100%; height: auto;" src='/assets/images/producto/picGenerica.webp' alt="">
          </swiper-slide>
          <swiper-slide *ngFor="let i of paquete.imagenes" class="d-flex justify-content-center align-items-center" style="height: 93vw; max-height: 450px;">
            <img width="450" height="450" style="max-width: 100%; height: max-content;" [ngClass]="'lazyload'" [attr.data-src]="i.url_imagen" onerror="this.src='/assets/images/producto/picGenerica.webp'" alt="Foto">
          </swiper-slide>
          <swiper-slide *ngIf="paquete.youtubeId">
            <iframe width="100%" height="450" [src]="'https://www.youtube.com/embed/'+paquete.youtubeId | safeUrlPaquete" title="Video - Paquete Sina" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </swiper-slide>
        </swiper-container>
        <!-- ThumbsSwiperJS -->
        <!-- TODO: Tal vez deberiamos mostrar la foto principal de cada insumo en este carrusel -->
        <swiper-container
          #swipercarouselsinapaquetethumbnails
          id="paquete-swiper-thumbnails"
          init="false"
          class="w-100"
        >
          <swiper-slide class="w-auto d-flex justify-content-center align-items-center">
            <img width="135" height="90" *ngIf="paquete['urlImagen']" [ngClass]="'lazyload'" [attr.data-src]="paquete['urlImagen']" onerror="this.src='/assets/images/producto/picGenerica.webp'" alt="Thumbnail">
            <img width="135" height="90" *ngIf="!paquete['urlImagen']" src='/assets/images/producto/picGenerica.webp' alt="">
          </swiper-slide>
          <swiper-slide *ngFor="let i of paquete.imagenes" class="w-auto d-flex justify-content-center align-items-center">
            <img width="90" height="90" [ngClass]="'lazyload'" [attr.data-src]="i.url_imagen" onerror="this.src='/assets/images/producto/picGenerica.webp'" alt="Thumbnail">
          </swiper-slide>
          <swiper-slide *ngIf="paquete.youtubeId">
            <img width="90" height="90" [ngClass]="'lazyload'" [attr.data-src]="'/assets/images/producto/video_thumbnail.webp'" onerror="this.src='/assets/images/producto/picGenerica.webp'" alt="Video thumbnail">
          </swiper-slide>
        </swiper-container>
      </div>
      <div *ngIf="paquete.habilitado == '1' && paquete.sustentable == '1'" class="paqueteSustentable">
        <img width="75" height="76" [ngClass]="'lazyload'" data-src="./assets/images/iconos/sustentable2.webp" alt="Sustentable">
      </div>
      <div *ngIf="paquete.habilitado == '0'" class="sinStock">
        <img width="75" height="41" [ngClass]="'lazyload'" data-src="./assets/images/iconos/sin-stock.webp" alt="Sin stock">
      </div>
    </div>
    <!-- Server only code -->
    <div class="col-12 col-md-6 imagen__visor" style="max-height: 300px; display: flex;" *ngIf="serverOnlyRun">
      <!-- Loading spinner -->
      <div class="spinner">
        <div class="dot1"></div>
        <div class="dot2"></div>
      </div>
    </div>
    <div class="col-12 col-md-6 paquete__descripcion">
      <!-- Client only code -->
      <div class="title" *ngIf="clientOnlyRun">
        <p *ngIf="paquete.nombreMostrar">{{ paquete.nombreMostrar }}</p>
        <p *ngIf="!paquete.nombreMostrar">{{ paquete['titulo'] }} - {{ paquete['tituloAdicional'] }}</p>
        <div class="social">
          <i [shareButton]="'facebook'" [url]="full_link" class="fa fa-facebook" aria-hidden="true"></i>
          <i [shareButton]="'twitter'"  [url]="full_link" class="fa fa-twitter"  aria-hidden="true"></i>
        </div>
      </div>
      <!-- Server only code -->
      <div class="title" *ngIf="serverOnlyRun">
        <p>Cargando...</p>
        <div class="social">
          <i [shareButton]="'facebook'" [url]="full_link" class="fa fa-facebook" aria-hidden="true"></i>
          <i [shareButton]="'twitter'"  [url]="full_link" class="fa fa-twitter"  aria-hidden="true"></i>
        </div>
      </div>
      <div class="row codigo">
        <p>Código: {{paquete['codInterno']}}</p>
        <p style="margin-bottom: 0px;">Código de barras: {{paquete['codBarra']}}</p>
        <p class="mb-0">Presentación: {{paquete["unidadMedida"] || 'Paquete de compras'}}</p>
        <p class="mb-0" *ngIf="paquete['largo'] && paquete['unidadLargo']">Largo: {{paquete["largo"] + ' ' + paquete["unidadLargo"]}}</p>
        <p class="mb-0" *ngIf="paquete['alto'] && paquete['unidadAlto']">Alto: {{paquete["alto"] + ' ' + paquete["unidadAlto"]}}</p>
        <p class="mb-0" *ngIf="paquete['profundidad'] && paquete['unidadProfundidad']">Profundidad: {{paquete["profundidad"] + ' ' + paquete["unidadProfundidad"]}}</p>
        <p class="mb-0" *ngIf="paquete['peso'] && paquete['unidadPeso']">Peso: {{paquete["peso"] + ' ' + paquete["unidadPeso"]}}</p>
        <p class="mb-0" *ngIf="paquete['volumen'] && paquete['unidadVolumen']">Volumen: {{paquete["volumen"] + ' ' + paquete["unidadVolumen"]}}</p>
        <p class="mb-0 mt-1" *ngIf="paquete['largo'] || paquete['alto'] || paquete['profundidad'] || paquete['peso'] || paquete['volumen']" style="font-size:12px;">Medidas aproximadas</p>
        <a class="mt-2 mb-2" *ngIf="paquete.linkFicha" style="color:#777" [href]="paquete.linkFicha" target="_blank" rel="noopener noreferrer">Descargar Ficha Técnica</a>
      </div>
      <div *ngIf="config.switchComprasActivo" class="precio__container justify-content-start mx-2">
        <div class="row paquete__precio gap-1 gap-lg-3" *ngIf="paquete.precio">
          <div class="titled div-oferta text-danger" *ngIf="paquete.oferta == true && paquete.show_descuento && paquete.descuento">{{ paquete.descuento }}% OFF</div>
          <div class="titled">
            <p class="m-0">${{ paquete.precio | number : '.2' }}</p>
          </div>
          <div class="titled fw-light precio-oferta" *ngIf="paquete.oferta && paquete.precio_mostrado > 0 && paquete.precio_base > 0 && paquete.precio_base > paquete.precio">
            <p class="m-0">${{ paquete.precio_base | number : '.2' }}</p>
          </div>
          <div class="precio__container__mobile" *ngIf="paquete.precio && iva_usuario">
            <p>{{iva_usuario}}</p>
          </div>
        </div>
        <div style="position: relative;"class="row paquete__cartel" *ngIf="paquete.precio && iva_usuario">
          <p class="p-0">{{iva_usuario}}</p>
        </div>
      </div>
      <div *ngIf="config.switchComprasActivo" class="d-flex flex-md-wrap gap-2 button">
        <div class="row paquete__compra col-md-12 m-0 w-auto">
          <div class="cantidad p-0">
            <p style="margin-right: 10px; margin-bottom: 0;">Cantidad:</p>
            <input *ngIf="paquete.cantPack === '1'" name="cantidad" aria-label="Cantidad" (keyup.enter)="enterCheck()" type="number" min="1" max="999999" [(ngModel)]="paquete.cantidad" (keyup)="revisarCantidad($event)" [disabled]="paquete.habilitado == '0'">
            <select *ngIf="paquete.cantPack !== '1'" name="cantidad" aria-label="Cantidad" [(ngModel)]="paquete.cantidad" [disabled]="paquete.habilitado == '0'">
              <option *ngFor="let cant of arrayCants" [value]="cant">{{cant}}</option>
            </select>
          </div>
        </div>
        <button class="agregar actionButton w-auto m-0" *ngIf="paquete.habilitado == '1' && paquete.comprado == false" (click)="newMessage(paquete)">AGREGAR AL CARRITO</button>
        <button class="bloqButton w-auto m-0" *ngIf="paquete.habilitado == '0'">SIN STOCK</button>
      </div>
      <div *ngIf="paquete.descripcion" class="paquete__descripcion mt-4 ql-editor px-0 h-auto" [innerHTML]='paquete.descripcion | safeHtmlPaquete'></div>
    </div>
  </div>
  <div class="row paquete__relacionados pt-3 px-3 justify-content-center">
    <div class="p-0">
      <h2 class="wiggle fs-1">Artículos relacionados</h2>
    </div>
    <!-- Client only code -->
    <div class="row resultado px-0" *ngIf="clientOnlyRun">
      <div class="container">
        <!-- Client only code -->
        <div *ngIf="clientOnlyRun" class="mt-4 pb-4 mt-md-5 pb-md-5" style="border-bottom: 1px solid #cccccc;">
          <!-- SwiperJS -->
          <swiper-container
          #swipercarouselsinapaqueterelacionados
          id="paquete-relacionados-swiper-productos-sina"
          init="false"
          >
            <swiper-slide *ngFor="let item of paquete.insumos">
              <app-producto-item [item]="item" [sesion]="loginStatus" [mensaje]="_success"></app-producto-item>
            </swiper-slide>
          </swiper-container>
        </div>
      </div>
    </div>
    <!-- Server only code -->
    <div class="row resultado px-0" *ngIf="serverOnlyRun">
      <div class="resultado__item col-6 col-md-3" *ngFor="let item of [0,1,2,3]">
        <!-- Loading spinner -->
        <div class="spinner">
          <div class="dot1"></div>
          <div class="dot2"></div>
        </div>
      </div>
    </div>
  </div>
</div>
