<div class="row message">
  <div class="container">
    <ngb-alert *ngIf="successMessage" (click)="alertClicked()" type="success" (close)="alertClicked();">
        <i class="fa fa-check-circle fa-3x" aria-hidden="true"></i> {{ successMessage }}
        <button class="actionButton transparentButton" [routerLink]="['/compra/carrito']">VER TODOS</button>
    </ngb-alert>
  </div>
</div>
<div class="container combos paquetes">
  <!-- Combos -->
  <div *ngIf="combos && combos.length > 0">
    <div class="combos text-center">
      <div class="mt-4 pb-4 mt-md-5 pb-md-5 w-100 banner-category">
        <img class="w-100 d-none d-md-block"  src="https://apisina-v1.leren.com.ar/storage/images/productos_sina/Pens%C3%A1-en-Sina-Pens%C3%A1-en-ahorro-14102024_101518.png" [alt]="'Banner Combos Desktop'" title="Sina">
        <img class="w-100 d-block d-md-none"  src="https://apisina-v1.leren.com.ar/storage/images/productos_sina/Pens%C3%A1-en-Sina-Pens%C3%A1-en-ahorro-14102024_101518.png" [alt]="'Banner Combos Mobile'" title="Sina">
      </div>
      <h1>Combos Sina</h1>
    </div>
    <div class="container">
      <!-- Client only code -->
      <div *ngIf="clientOnlyRun" class="mt-4 pb-4 mt-md-5 pb-md-5" style="border-bottom: 1px solid #cccccc;">
        <!-- SwiperJS -->
        <swiper-container
        #swipercarouselsinacombos
        id="combos-swiper-productos-sina"
        init="false"
        >
          <swiper-slide *ngFor="let item of combos">
            <app-producto-item [item]="item" [combo]="true" [sesion]="loginStatus" [mensaje]="_success"></app-producto-item>
          </swiper-slide>
        </swiper-container>
      </div>
    </div>
  </div>
  <!-- Paquetes -->
  <div *ngIf="paquetes && paquetes.length > 0">
    <div class="paquetes text-center">
      <div class="mt-4 pb-4 mt-md-5 pb-md-5 w-100 banner-category">
        <img class="w-100 d-none d-md-block"  src="https://apisina-v1.leren.com.ar/storage/images/productos_sina/Pens%C3%A1-en-Sina-Pens%C3%A1-en-ahorro-14102024_101518.png" [alt]="'Banner Combos Desktop'" title="Sina">
        <img class="w-100 d-block d-md-none"  src="https://apisina-v1.leren.com.ar/storage/images/productos_sina/Pens%C3%A1-en-Sina-Pens%C3%A1-en-ahorro-14102024_101518.png" [alt]="'Banner Combos Mobile'" title="Sina">
      </div>
      <h1>Paquetes Sina</h1>
    </div>
    <div class="container">
      <!-- Client only code -->
      <div *ngIf="clientOnlyRun" class="mt-4 pb-4 mt-md-5 pb-md-5" style="border-bottom: 1px solid #cccccc;">
        <!-- SwiperJS -->
        <swiper-container
        #swipercarouselsinapaquetes
        id="paquetes-swiper-productos-sina"
        init="false"
        >
          <swiper-slide *ngFor="let item of paquetes">
            <app-paquete-item [item]="item" [sesion]="loginStatus" [mensaje]="_success"></app-paquete-item>
          </swiper-slide>
        </swiper-container>
      </div>
    </div>
  </div>
</div>
