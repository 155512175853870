import { CommonModule } from '@angular/common';
import { Injectable, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms'
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

import * as Hammer from '@egjs/hammerjs';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent, BusquedaCuentaPipe } from './app.component';
import { AutenticacionService } from './autenticacion.service';
import { SharedService } from './shared.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CategoriasComponent } from './categorias/categorias.component';
import { ArraySortIntPipe, ArraySortPipe, ArraySortProductosPipe, CompraComponent } from './compra/compra.component';
import { CombosComponent } from './combos/combos.component';
import { ConfirmacionComponent } from './confirmacion/confirmacion.component';
import { ConfirmarDatosComponent } from './confirmar-datos/confirmar-datos.component';
import { CuentaComponent } from './cuenta/cuenta.component';
import { FilterComponent } from './filter/filter.component';
import { FooterComponent } from './footer/footer.component';
import { GoogleMapsComponent } from './google-maps/google-maps.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { MenuService } from './menu.service';
import { ProductoComponent, SafeHtmlPipeProducto, SafeUrlPipeProducto } from './producto/producto.component';
import { HighlightSearchPipe, ProductoItemComponent } from './producto-item/producto-item.component';
import { PaqueteComponent, SafeHtmlPipePaquete, SafeUrlPipePaquete } from './paquete/paquete.component';
import { HighlightSearchPipePaquete, PaqueteItemComponent } from './paquete-item/paquete-item.component';
import { RecuperarPassComponent } from './recuperar-pass/recuperar-pass.component';
import { LoadingComponent } from './shared/loading/loading.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FilterPipe } from './filter.pipe';
import { MyFilterPipe } from './my-filter.pipe';
import { SafeHtmlPipeHome, SafePipe, SafeUrlPipeHome } from './home/home.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClientModule } from '@angular/common/http';
import { SidebarModule } from 'ng-sidebar';
import { ShareModule } from 'ngx-sharebuttons';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  overrides = {
    "swipe": { "direction": Hammer.DIRECTION_HORIZONTAL },
    "pinch": { "enable": false },
    "rotate": { "enable": false }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    CategoriasComponent,
    CompraComponent,
    ConfirmacionComponent,
    ConfirmarDatosComponent,
    CuentaComponent,
    FilterComponent,
    FooterComponent,
    GoogleMapsComponent,
    HeaderComponent,
    HomeComponent,
    CombosComponent,
    ProductoComponent,
    ProductoItemComponent,
    PaqueteComponent,
    PaqueteItemComponent,
    RecuperarPassComponent,
    LoadingComponent,
    SidebarComponent,
    SafeHtmlPipeHome,
    SafePipe,
    SafeUrlPipeHome,
    HighlightSearchPipePaquete,
    SafeHtmlPipePaquete,
    SafeUrlPipePaquete,
    HighlightSearchPipe,
    SafeHtmlPipeProducto,
    SafeUrlPipeProducto,
    MyFilterPipe,
    FilterPipe,
    BusquedaCuentaPipe,
    ArraySortIntPipe,
    ArraySortPipe,
    ArraySortProductosPipe,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    CommonModule,
    FormsModule,
    HammerModule,
    HttpClientModule,
    MatSnackBarModule,
    NgbModule,
    NgSelectModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    ShareModule,
    SidebarModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatMenuModule,
  ],
  providers: [
    SharedService,
    AutenticacionService,
    MenuService,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig
    },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
