import { Component, Inject, Input, OnDestroy, OnInit, Optional, Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AutenticacionService } from '../autenticacion.service';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { FacebookPixelService } from '../facebook-pixel.service';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-paquete-item',
  templateUrl: './paquete-item.component.html',
  styleUrls: ['./paquete-item.component.scss']
})
export class PaqueteItemComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  @Input() item: any;
  @Input() sesion: any;
  @Input() mensaje: any;
  @Input() relacionado: any;
  @Input() itemcarousel: any;
  config: any;
  public iva_usuario: string = '';
  public search_term = '';
  constructor(
    private data: SharedService,
    private auth: AutenticacionService,
    private route: ActivatedRoute,
    private googleAnalyticsService: GoogleAnalyticsService,
    private facebookPixelService: FacebookPixelService,
    @Optional() @Inject('serverUrl') protected serverUrl: string
  ) {}
  private replaceHash($entrada: string) {
    return $entrada ? $entrada.replace(new RegExp('/'), '~') : $entrada;
  }
  arrayCants: any[] = [];
  ngOnInit() {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: any) => {
      const path = typeof window !== typeof undefined ? window.location.href : (environment.APP_WEB_BASE + this.serverUrl?.slice(1));
      if (path.indexOf('/busqueda/') !== -1) {
        if (!params['id2'] && !params['padre']) {
          this.search_term = params['id'];
        } else {
          this.search_term = '';
        }
      } else {
        this.search_term = '';
      }
    });

    this.item.fullLink = '/paquetes/' + this.replaceHash(this.item.id);
    this.item.comprado    = false;
    this.item.cantidad    = this.item.cantSugerida ? parseInt(this.item.cantSugerida) : 1;
    if (this.item.cantPack !== '1') {
      this.item.cantidad  = this.item.cantPack ? parseInt(this.item.cantPack) : 1;
      for (let i = 0; i < 20; i++) {
        this.arrayCants[i] = this.item.cantidad * (i + 1);
      }
      for (let i = 0; i < 50; i++) {
        this.arrayCants[i + 20] = this.item.cantidad * (i + 3) * 10;
      }
    }
    this.item.oferta      = this.item.oferta == '1' ? true : false;
    this.item.imperdible  = this.item.oferta == false ? (this.item.novedad == '1' ? true : false) : false;
    this.item.precio_mostrado = this.item.precio ? this.item.precio.toString().replace(',', '.') : '0';
    this.item.precio_base = this.item.precioLista || this.item.precioListaBase;
    if (this.item.precio_base > 0 && this.item.precio_base > this.item.precio) {
      this.item.descuento = this.calcularDescuento(this.item.precio_base, this.item.precio_mostrado);
      // Decidimos si mostrar el descuento a partir numero mayor o igual a 5% OFF
      if (this.item.descuento >= 5) {
        this.item.show_descuento = true;
      } else {
        this.item.show_descuento = false;
      }
    } else {
      this.item.descuento = undefined;
      this.item.show_descuento = false;
    }

    this.data.currentUser.pipe(takeUntil(this.destroy$)).subscribe(($user: any) => {
      if ($user && $user['c'] == '1') {
        this.iva_usuario = '';
      } else {
        if ($user) {
          switch ($user['codCategoriaIva']) {
            case 'CF': this.iva_usuario = 'UNIT I.V.A. incluido'; break;
            case 'INR': this.iva_usuario = 'UNIT I.V.A. incluido'; break;
            case 'RS': this.iva_usuario = 'UNIT I.V.A. incluido'; break;
            case 'RSS': this.iva_usuario = 'UNIT I.V.A. incluido'; break;
            case 'RI': this.iva_usuario = 'UNIT + I.V.A.'; break;
            case 'EX': this.iva_usuario = 'UNIT I.V.A. incluido'; break;
            case 'PCE': this.iva_usuario = 'UNIT I.V.A. incluido'; break;
            case 'PCS': this.iva_usuario = 'UNIT I.V.A. incluido'; break;
            case 'EXE': this.iva_usuario = 'UNIT Final'; break;
            case 'SNC': this.iva_usuario = 'UNIT I.V.A. incluido'; break;
            default: this.iva_usuario = 'UNIT + I.V.A.';
          }
        }
      }
    });

    // subscribing to config change
    this.data.currentConfig.pipe(takeUntil(this.destroy$)).subscribe(
      (configuracion: any) => {
        this.config = configuracion;
      },
    );
  }

  calcularDescuento(precioBase: number, precioMostrado: number): number {
    const cleanPrecioBase = Number(precioBase.toString().replace(/[^0-9.-]+/g,""));
    const cleanPrecioMostrado = Number(precioMostrado.toString().replace(/[^0-9.-]+/g,""));

    return Math.floor((cleanPrecioBase - cleanPrecioMostrado) / cleanPrecioBase * 100);
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  enterCheck() {
    this.newMessage(this.item);
  }
  newMessage(msg: any) {
    if (this.sesion === true) {
      if (msg && msg.insumos?.length > 0) {
        const body = new URLSearchParams();
        body.set('productos', JSON.stringify(msg.insumos?.filter((p: any) => p.habilitado == "1" && p.enStock == "1" && p.precio > 0)?.map((p: any) => { return { id: p.id, cantidad: p.cantidad }})));

        this.auth.post('carrito/agregar_varios_item', body)
        .then(($response) => {
          if (environment.production) {
            this.googleAnalyticsService.addToCart({ sku: msg.codInterno, descripcion: msg.titulo, categoria: msg.categorias ? msg.categorias[0]?.nombre : undefined, precio: msg.precio, cantidad: msg.cantidad });
            this.facebookPixelService.addToCart({ sku: msg.codInterno, descripcion: msg.titulo, categoria: msg.categorias ? msg.categorias[0]?.nombre : undefined, precio: msg.precio, cantidad: msg.cantidad });
          }
          this.data.log('response carritoagregarvariositem paquete-item', $response);

          for (const p of msg.insumos) {
            if (p.habilitado == "1" && p.enStock == "1" && p.precio > 0) {
              const response = this.data.addMessage(p);
              if (response.value) {
                this.mensaje.next(response.text);
              }
            }
          }
        })
        .catch(($error) => {
          this.data.log('error carritoagregarvariositem paquete-item', $error);
        });
      }
    } else {
      this.data.toggleLoginModal();
    }
  }

  public revisarCantidad(e: any) {
    if (e.target && parseInt(e.target.value) < parseInt(e.target.min)) {
      e.target.value = e.target.min;
    }
  }

}

@Pipe({
  name: 'highlight',
  pure: true // true makes it pure and false makes it impure
})
export class HighlightSearchPipePaquete implements PipeTransform {

  transform(text: string, search: any): string {
    if (search && text) {
      let pattern = search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
      pattern = pattern.split(' ').filter((t: any) => {
        return t.length > 0;
      }).join('|');
      const regex = new RegExp(pattern, 'gi');

      return text.replace(regex, (match) => `<span class="search-highlight">${match}</span>`);
    } else {
      return text;
    }
  }

}
