import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, Pipe, PipeTransform, PLATFORM_ID, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AutenticacionService } from '../autenticacion.service';
import { ProductoItemComponent } from '../producto-item/producto-item.component';
import { SharedService } from '../shared.service';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { register, SwiperContainer } from 'swiper/element/bundle';
register();

export class Link {
  active!: boolean;
  url!:    string;
  texto!:  string;
  urlImg?: string;
}
const LINKS: Link[] = [
  { active: true, url: 'nosotros',   texto: 'Nosotros' },
  { active: true, url: 'envios',   texto: 'Envíos' },
  { active: true, url: 'como-comprar',   texto: 'Como comprar' },
];
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [ProductoItemComponent],
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('ngcarousel', { static: true }) ngCarousel!: NgbCarousel;
  autoSlideEventCount = -1;

  initialHomeArray: any[] = [
    {
      'head': {
        'background_color': 'rgb(0, 201, 115)',
        'icono_color': 'azul',
        'texto_color': 'white',
        'texto': 'OFERTAS',
        'filter': '/ofertas',
      },
    },
    {
      'head': {
        'background_color': 'rgb(0, 201, 115)',
        'icono_color': 'blanco',
        'texto_color': 'white',
        'texto': 'NOVEDADES',
        'filter': '/novedades',
      },
    },
  ];

  private destroy$: Subject<void> = new Subject<void>();
  public _success = new Subject<string>();
  public iva_usuario: string = '';
  staticAlertClosed = false;
  successMessage!: string;
  loginStatus: boolean = false;
  listaResultados: any;
  LinkListSubMenu: any = LINKS;
  productosSina: any = {
    lista: [],
    // head: {
    //   texto: undefined,
    //   texto_color: undefined,
    //   imagen: undefined,
    //   background_color: undefined,
    //   icono_color: undefined,
    // }
  };
  listadoProductos: any[] = [];
  public imageSources: any[] = [];
  public bannerSinaDesktopImagen: any;
  public bannerSinaDesktopTitulo: any;
  public bannerSinaMobileImagen: any;
  public bannerSinaMobileTitulo: any;
  message!: string;
  UserLog!: boolean;
  config: any;
  environment: any;
  flagYaRegistrado: boolean = false;
  listaPreciosImagen = true;

  fileDownloadProgressValue: string = '0';

  clientOnlyRun: boolean = false;
  serverOnlyRun: boolean = false;

  _existDesktop: boolean = false;
  _existMobile: boolean = false;

  loading: boolean = false;
  contenido: any = {
    items: [
      {
        id: null,
        id_item: 1,
        content: 'Cargando...'
      },
      {
        id: null,
        id_item: 2,
        content: 'Cargando...'
      }
    ]
  };

  constructor(
    public data: SharedService,
    private http: HttpClient,
    private auth: AutenticacionService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {

    if (typeof window !== typeof undefined) {
      if (window.innerWidth <= 992) {
        this._existDesktop = false;
        this._existMobile = true;
      } else {
        this._existDesktop = true;
        this._existMobile = false;
      }
    } else {
      this._existDesktop = true;
      this._existMobile = true;
    }

    this.environment = environment;
    this.data.updatePageTitle();

    if (isPlatformBrowser(this.platformId)) {
      this.clientOnlyRun = true;
    } else {
      this.serverOnlyRun = true;
    }
  }
  mapOrder(array: any, order: any, key: any) {

    array.sort((a: any, b: any) => {
      const A = a[key];
      const B = b[key];

      if (order.indexOf(A) > order.indexOf(B)) {
        return 1;
      } else {
        return -1;
      }

    });

    return array;
  }
  sliderOrder = [];

  ngOnInit(): void {
    setTimeout(() => this.staticAlertClosed = true, 5000);
    this._success.pipe(takeUntil(this.destroy$)).subscribe((message) => this.successMessage = message);
    this._success.pipe(takeUntil(this.destroy$), debounceTime(5000)).subscribe(() => this.successMessage = '');

    // subscribing to data on loginStatus
    this.data.currentLogin.pipe(takeUntil(this.destroy$)).subscribe(
      (status) => {
        this.loginStatus = status;
        // Client only code.
        if (this.clientOnlyRun) {
          const $public = this.loginStatus ? '' : 'public/';

          // Obtengo productos Sina para el carousel principal
          this.auth.get($public + 'producto/listado/home/sina')
          .then(($response)  => {
            const productos = $response.response.slice(0, 20);
            this.productosSina.lista = JSON.parse(JSON.stringify(productos));

            // TODO: Ver que ponemos en la config para esta seccion (por ejemplo banner desktop, banner mobile y alt...)
            // if (this.config) {
            //   this.productosSina.head.texto = this.config.bannerOfertasTitulo;
            //   this.productosSina.head.texto_color = '#fff';
            //   this.productosSina.head.imagen = this.config.bannerOfertasImagen;
            //   this.productosSina.head.background_color = '#057aff';
            //   this.productosSina.head.icono_color = 'azul';
            // }

            // TODO: parece que no es necesario pero asi se refreshearia el swiper si haciera falta
            // try {
            //   // swiper element
            //   const swiperEl: SwiperContainer | null = document.querySelector('#home-swiper-productos-sina');
            //   if (swiperEl && swiperEl.swiper) {
            //     setTimeout(() => {
            //       swiperEl.swiper.update()
            //     }, 500);
            //   } else {
            //     this.data.log('Home Swiper or .swiper not found');
            //   }
            // } catch (error) {
            //   this.data.log('Home Swiper reloading error:', error);
            // }
          })
          .catch(($error) => {
            this.data.log('getproductossina error home:', $error);
          });

          // getting data via get request
          this.http.get(environment.APP_WEB_BASE + 'assets/data/resultadosHome.json')
          .pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
            this.listaResultados = res['Resultados'];

            if (this.config) {
              this.listaResultados[0].head.texto = this.config.bannerOfertasTitulo;
              this.listaResultados[0].head.texto_color = '#fff';
              this.listaResultados[0].head.imagen = this.config.bannerOfertasImagen;
              this.listaResultados[0].head.background_color = '#057aff';
              this.listaResultados[0].head.icono_color = 'azul';
              this.listaResultados[1].head.texto = this.config.bannerOfertasSemanalesTitulo;
              this.listaResultados[1].head.texto_color = '#fff';
              this.listaResultados[1].head.imagen = this.config.bannerOfertasSemanalesImagen;
              this.listaResultados[1].head.background_color = '#057aff';
              this.listaResultados[1].head.icono_color = 'blanco';
              this.listaResultados[2].head.texto = this.config.bannerNovedadesTitulo;
              this.listaResultados[2].head.texto_color = '#fff';
              this.listaResultados[2].head.imagen = this.config.bannerNovedadesImagen;
              this.listaResultados[2].head.background_color = '#057aff';
              this.listaResultados[2].head.icono_color = 'azul';
              this.listaResultados[3].head.texto = this.config.bannerCampaniasTitulo;
              this.listaResultados[3].head.texto_color = '#fff';
              this.listaResultados[3].head.imagen = this.config.bannerCampaniasImagen;
              this.listaResultados[3].head.background_color = '#057aff';
              this.listaResultados[3].head.icono_color = 'blanco';
              this.listaResultados[4].head.texto = this.config.bannerReciclablesTitulo;
              this.listaResultados[4].head.texto_color = '#fff';
              this.listaResultados[4].head.imagen = this.config.bannerReciclablesImagen;
              this.listaResultados[4].head.background_color = '#057aff';
              this.listaResultados[4].head.icono_color = 'azul';
            }

            // this.listaResultados[2].lista = [];
            this.auth.get($public + 'producto/listado/home/ofertas')
            .then(($response)  => {
              const ofertas1 = $response.response.slice(0, 4);
              // let ofertas2 = $response.response.slice(0, 2);
              this.listaResultados[0].lista = JSON.parse(JSON.stringify(ofertas1));
              // this.listaResultados[2].lista[0] = JSON.parse(JSON.stringify(ofertas2[0]));
              // this.listaResultados[2].lista[1] = JSON.parse(JSON.stringify(ofertas2[1]));

              if (this.config) {
                this.listaResultados[0].head.texto = this.config.bannerOfertasTitulo;
                this.listaResultados[0].head.texto_color = '#fff';
                this.listaResultados[0].head.imagen = this.config.bannerOfertasImagen;
                this.listaResultados[0].head.background_color = '#057aff';
                this.listaResultados[0].head.icono_color = 'azul';
                this.listaResultados[1].head.texto = this.config.bannerOfertasSemanalesTitulo;
                this.listaResultados[1].head.texto_color = '#fff';
                this.listaResultados[1].head.imagen = this.config.bannerOfertasSemanalesImagen;
                this.listaResultados[1].head.background_color = '#057aff';
                this.listaResultados[1].head.icono_color = 'blanco';
                this.listaResultados[2].head.texto = this.config.bannerNovedadesTitulo;
                this.listaResultados[2].head.texto_color = '#fff';
                this.listaResultados[2].head.imagen = this.config.bannerNovedadesImagen;
                this.listaResultados[2].head.background_color = '#057aff';
                this.listaResultados[2].head.icono_color = 'azul';
                this.listaResultados[3].head.texto = this.config.bannerCampaniasTitulo;
                this.listaResultados[3].head.texto_color = '#fff';
                this.listaResultados[3].head.imagen = this.config.bannerCampaniasImagen;
                this.listaResultados[3].head.background_color = '#057aff';
                this.listaResultados[3].head.icono_color = 'blanco';
                this.listaResultados[4].head.texto = this.config.bannerReciclablesTitulo;
                this.listaResultados[4].head.texto_color = '#fff';
                this.listaResultados[4].head.imagen = this.config.bannerReciclablesImagen;
                this.listaResultados[4].head.background_color = '#057aff';
                this.listaResultados[4].head.icono_color = 'azul';
              }
            })
            .catch(($error) => {
              this.data.log('getlistadoofertas error home:', $error);
            });
            this.auth.get($public + 'producto/listado/home/ofertassemanales')
            .then(($response)  => {
              const ofertas1 = $response.response.slice(0, 4);
              // let ofertas2 = $response.response.slice(0, 2);
              this.listaResultados[1].lista = JSON.parse(JSON.stringify(ofertas1));
              // this.listaResultados[2].lista[0] = JSON.parse(JSON.stringify(ofertas2[0]));
              // this.listaResultados[2].lista[1] = JSON.parse(JSON.stringify(ofertas2[1]));

              if (this.config) {
                this.listaResultados[0].head.texto = this.config.bannerOfertasTitulo;
                this.listaResultados[0].head.texto_color = '#fff';
                this.listaResultados[0].head.imagen = this.config.bannerOfertasImagen;
                this.listaResultados[0].head.background_color = '#057aff';
                this.listaResultados[0].head.icono_color = 'blanco';
                this.listaResultados[1].head.texto = this.config.bannerOfertasSemanalesTitulo;
                this.listaResultados[1].head.texto_color = '#fff';
                this.listaResultados[1].head.imagen = this.config.bannerOfertasSemanalesImagen;
                this.listaResultados[1].head.background_color = '#057aff';
                this.listaResultados[1].head.icono_color = 'blanco';
                this.listaResultados[2].head.texto = this.config.bannerNovedadesTitulo;
                this.listaResultados[2].head.texto_color = '#fff';
                this.listaResultados[2].head.imagen = this.config.bannerNovedadesImagen;
                this.listaResultados[2].head.background_color = '#057aff';
                this.listaResultados[2].head.icono_color = 'blanco';
                this.listaResultados[3].head.texto = this.config.bannerCampaniasTitulo;
                this.listaResultados[3].head.texto_color = '#fff';
                this.listaResultados[3].head.imagen = this.config.bannerCampaniasImagen;
                this.listaResultados[3].head.background_color = '#057aff';
                this.listaResultados[3].head.icono_color = 'blanco';
                this.listaResultados[4].head.texto = this.config.bannerReciclablesTitulo;
                this.listaResultados[4].head.texto_color = '#fff';
                this.listaResultados[4].head.imagen = this.config.bannerReciclablesImagen;
                this.listaResultados[4].head.background_color = '#057aff';
                this.listaResultados[4].head.icono_color = 'blanco';
              }
            })
            .catch(($error) => {
              this.data.log('getlistadoofertassemanales error home:', $error);
            });
            this.auth.get($public + 'producto/listado/home/novedades')
            .then(($response)  => {
              const ofertas1 = $response.response.slice(0, 4);
              // const ofertas2 = $response.response.slice(0, 2);
              this.listaResultados[2].lista = JSON.parse(JSON.stringify(ofertas1));
              // this.listaResultados[2].lista[2] = JSON.parse(JSON.stringify(ofertas2[0]));
              // this.listaResultados[2].lista[3] = JSON.parse(JSON.stringify(ofertas2[1]));

              if (this.config) {
                this.listaResultados[0].head.texto = this.config.bannerOfertasTitulo;
                this.listaResultados[0].head.texto_color = '#fff';
                this.listaResultados[0].head.imagen = this.config.bannerOfertasImagen;
                this.listaResultados[0].head.background_color = '#057aff';
                this.listaResultados[0].head.icono_color = 'blanco';
                this.listaResultados[1].head.texto = this.config.bannerOfertasSemanalesTitulo;
                this.listaResultados[1].head.texto_color = '#fff';
                this.listaResultados[1].head.imagen = this.config.bannerOfertasSemanalesImagen;
                this.listaResultados[1].head.background_color = '#057aff';
                this.listaResultados[1].head.icono_color = 'blanco';
                this.listaResultados[2].head.texto = this.config.bannerNovedadesTitulo;
                this.listaResultados[2].head.texto_color = '#fff';
                this.listaResultados[2].head.imagen = this.config.bannerNovedadesImagen;
                this.listaResultados[2].head.background_color = '#057aff';
                this.listaResultados[2].head.icono_color = 'blanco';
                this.listaResultados[3].head.texto = this.config.bannerCampaniasTitulo;
                this.listaResultados[3].head.texto_color = '#fff';
                this.listaResultados[3].head.imagen = this.config.bannerCampaniasImagen;
                this.listaResultados[3].head.background_color = '#057aff';
                this.listaResultados[3].head.icono_color = 'blanco';
                this.listaResultados[4].head.texto = this.config.bannerReciclablesTitulo;
                this.listaResultados[4].head.texto_color = '#fff';
                this.listaResultados[4].head.imagen = this.config.bannerReciclablesImagen;
                this.listaResultados[4].head.background_color = '#057aff';
                this.listaResultados[4].head.icono_color = 'blanco';
              }
            })
            .catch(($error) => {
              this.data.log('getlistadonovedades error home:', $error);
            });
            this.auth.get($public + 'producto/listado/home/campania')
            .then(($response)  => {
              const ofertas1 = $response.response.slice(0, 4);
              this.listaResultados[3].lista = JSON.parse(JSON.stringify(ofertas1));

              if (this.config) {
                this.listaResultados[0].head.texto = this.config.bannerOfertasTitulo;
                this.listaResultados[0].head.texto_color = '#fff';
                this.listaResultados[0].head.imagen = this.config.bannerOfertasImagen;
                this.listaResultados[0].head.background_color = '#057aff';
                this.listaResultados[0].head.icono_color = 'blanco';
                this.listaResultados[1].head.texto = this.config.bannerOfertasSemanalesTitulo;
                this.listaResultados[1].head.texto_color = '#fff';
                this.listaResultados[1].head.imagen = this.config.bannerOfertasSemanalesImagen;
                this.listaResultados[1].head.background_color = '#057aff';
                this.listaResultados[1].head.icono_color = 'blanco';
                this.listaResultados[2].head.texto = this.config.bannerNovedadesTitulo;
                this.listaResultados[2].head.texto_color = '#fff';
                this.listaResultados[2].head.imagen = this.config.bannerNovedadesImagen;
                this.listaResultados[2].head.background_color = '#057aff';
                this.listaResultados[2].head.icono_color = 'blanco';
                this.listaResultados[3].head.texto = this.config.bannerCampaniasTitulo;
                this.listaResultados[3].head.texto_color = '#fff';
                this.listaResultados[3].head.imagen = this.config.bannerCampaniasImagen;
                this.listaResultados[3].head.background_color = '#057aff';
                this.listaResultados[3].head.icono_color = 'blanco';
                this.listaResultados[4].head.texto = this.config.bannerReciclablesTitulo;
                this.listaResultados[4].head.texto_color = '#fff';
                this.listaResultados[4].head.imagen = this.config.bannerReciclablesImagen;
                this.listaResultados[4].head.background_color = '#057aff';
                this.listaResultados[4].head.icono_color = 'blanco';
              }
            })
            .catch(($error) => {
              this.data.log('getlistadocampania error home:', $error);
            });
            this.auth.get($public + 'producto/listado/home/sustentables')
            .then(($response)  => {
              const sustentables = $response.response.slice(0, 4);
              // const ofertas2 = $response.response.slice(0, 2);
              this.listaResultados[4].lista = JSON.parse(JSON.stringify(sustentables));
              // this.listaResultados[2].lista[2] = JSON.parse(JSON.stringify(ofertas2[0]));
              // this.listaResultados[2].lista[3] = JSON.parse(JSON.stringify(ofertas2[1]));

              if (this.config) {
                this.listaResultados[0].head.texto = this.config.bannerOfertasTitulo;
                this.listaResultados[0].head.texto_color = '#fff';
                this.listaResultados[0].head.imagen = this.config.bannerOfertasImagen;
                this.listaResultados[0].head.background_color = '#057aff';
                this.listaResultados[0].head.icono_color = 'blanco';
                this.listaResultados[1].head.texto = this.config.bannerOfertasSemanalesTitulo;
                this.listaResultados[1].head.texto_color = '#fff';
                this.listaResultados[1].head.imagen = this.config.bannerOfertasSemanalesImagen;
                this.listaResultados[1].head.background_color = '#057aff';
                this.listaResultados[1].head.icono_color = 'blanco';
                this.listaResultados[2].head.texto = this.config.bannerNovedadesTitulo;
                this.listaResultados[2].head.texto_color = '#fff';
                this.listaResultados[2].head.imagen = this.config.bannerNovedadesImagen;
                this.listaResultados[2].head.background_color = '#057aff';
                this.listaResultados[2].head.icono_color = 'blanco';
                this.listaResultados[3].head.texto = this.config.bannerCampaniasTitulo;
                this.listaResultados[3].head.texto_color = '#fff';
                this.listaResultados[3].head.imagen = this.config.bannerCampaniasImagen;
                this.listaResultados[3].head.background_color = '#057aff';
                this.listaResultados[3].head.icono_color = 'blanco';
                this.listaResultados[4].head.texto = this.config.bannerReciclablesTitulo;
                this.listaResultados[4].head.texto_color = '#fff';
                this.listaResultados[4].head.imagen = this.config.bannerReciclablesImagen;
                this.listaResultados[4].head.background_color = '#057aff';
                this.listaResultados[4].head.icono_color = 'blanco';
              }
            })
            .catch(($error) => {
              this.data.log('getlistadosustentables error home:', $error);
            });


          });
        }
      },
    );
    this.data.currentUser.pipe(takeUntil(this.destroy$)).subscribe(($user: any) => {
      this.data.log('actualizacion de usuario home');
      if ($user && $user['c'] === '1') {
        this.iva_usuario = 'LOS PRECIOS SON UNITARIOS Y ESTÁN SUJETOS A SU CONDICIÓN HABITUAL';
      } else {
        if ($user) {
          switch ($user['codCategoriaIva']) {
            case 'CF': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS INCLUYEN IVA'; break;
            case 'INR': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS INCLUYEN IVA'; break;
            case 'RS': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS INCLUYEN IVA'; break;
            case 'RSS': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS INCLUYEN IVA'; break;
            case 'RI': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS NO INCLUYEN IVA'; break;
            case 'EX': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS INCLUYEN IVA'; break;
            case 'PCE': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS INCLUYEN IVA'; break;
            case 'PCS': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS INCLUYEN IVA'; break;
            case 'EXE': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS SON FINALES'; break;
            case 'SNC': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS INCLUYEN IVA'; break;
            default: this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS NO INCLUYEN IVA';
          }
        }
      }
    });

    // subscribing to config change
    this.data.currentConfig.pipe(takeUntil(this.destroy$)).subscribe(
      (configuracion: any) => {
        this.config = configuracion;

        if (this.config.bannerUnoActivo) {
          this.imageSources.push({imagen: this.config.bannerUnoImagen, link: this.config.bannerUnoLink});
        }
        if (this.config.bannerDosActivo) {
          this.imageSources.push({imagen: this.config.bannerDosImagen, link: this.config.bannerDosLink});
        }
        if (this.config.bannerTresActivo) {
          this.imageSources.push({imagen: this.config.bannerTresImagen, link: this.config.bannerTresLink});
        }
        if (this.config.bannerCuatroActivo) {
          this.imageSources.push({imagen: this.config.bannerCuatroImagen, link: this.config.bannerCuatroLink});
        }
        if (this.config.bannerCincoActivo) {
          this.imageSources.push({imagen: this.config.bannerCincoImagen, link: this.config.bannerCincoLink});
        }
        if (this.config.bannerSeisActivo) {
          this.imageSources.push({imagen: this.config.bannerSeisImagen, link: this.config.bannerSeisLink});
        }

        this.bannerSinaDesktopImagen = this.config.bannerSinaDesktopImagen;
        this.bannerSinaDesktopTitulo = this.config.bannerSinaDesktopTitulo;
        this.bannerSinaMobileImagen = this.config.bannerSinaMobileImagen;
        this.bannerSinaMobileTitulo = this.config.bannerSinaMobileTitulo;
      },
    );

    this.data.updatePageTitle();
    this.loading = true;

    // Reading content data
    this.auth.get('public/contenido/home')
    .then((data: any) => {
      if (data.status && data.response) {
        const jsonResponseItems = JSON.stringify(data.response.items);
        let replacedJsonResponseItems = jsonResponseItems.replace(/\[BOTON_VER_MAS\]/g, "<a class='actionButton text-decoration-none text-uppercase' href='https://www.youtube.com/@sinaargentina' target='_blank' rel='noopener noreferrer'>Ver más <i class='fa fa-external-link'></i></a>");
        const replacedResponseItems = JSON.parse(replacedJsonResponseItems);
        if (replacedResponseItems) {
          this.contenido.items = replacedResponseItems;
        }
      }
      this.loading = false;
    })
    .catch((error: any) => {
      this.data.log('/public/contenido/home error home:', error)
    });
  }

  ngAfterViewInit() {
    // Client only code.
    if (this.clientOnlyRun) {
      // Inicializo Swiper
      try {
        // swiper element
        const swiperEl: SwiperContainer | null = document.querySelector('#home-swiper-productos-sina');

        if (swiperEl) {
          // swiper parameters
          const swiperParams = {
            init: true,
            navigation: true,
            pagination: false,
            scrollbar: false,
            autoplay: {
              enabled: true,
              pauseOnMouseEnter: true,
            },
            loop: true,
            slidesPerView: 1,
            breakpoints: {
              768: {
                slidesPerView: 2,
              },
              992: {
                slidesPerView: 4,
              },
            },
            observeParents: true,
            observeSlideChildren: true,
            observer: true,
            // on: {
            //   init() {
            //     // ...
            //   },
            // },
          };

          // now we need to assign all parameters to Swiper element
          Object.assign(swiperEl, swiperParams);

          // and now initialize it
          swiperEl.initialize();
        } else {
          this.data.log('Home Swiper not found');
        }
      } catch (error) {
        this.data.log('Home Swiper initialization error:', error);
      }

      try {
        // Instafeed
        const Instafeed = require('instafeed.js');
        var feed = new Instafeed({
          accessToken: 'IGAAHhe0109HVBZAFB3RGo1YTlzTXhTd1IwSkUwNFJTWEs2TTBSYmxQWW9EVjVldXBjRXRVcUJ6cWJXd2ZAIb2JScDlWZAHNla29fcTFZAd2NwMy1rd0pvRGFaQzRrODJ5SDZAQOGtNNV9ySXdacVVGM2RVVllobXQ1ZA3V0ZAGNwSXFrVQZDZD',
          limit: 6,
          template: `
            <div class="col-6 col-md-4 col-lg-2 p-0">
              <a href="{{link}}" target="_blank" rel="noopener noreferrer">
                <img width="160" height="160" style="height: fit-content" class="w-100 lazyload instapic" alt="Instagram feed" title="{{caption}}" src="./assets/images/home/insta-placeholder.webp" data-src="{{image}}"/>
              </a>
            </div>
          `
        });
        feed.run();
      } catch (error) {
        this.data.log('Home Instagram feed initialization error:', error);
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  alertClicked() {
    this.successMessage = '';
    this.data.toggleCarritoShow();
  }
  registrar() {
    if (!this.loginStatus) {
      this.data.toggleLoginModal();

    }
    else {
      this.flagYaRegistrado = true;
      setTimeout(() => {
        this.flagYaRegistrado = false;
      }, 5000);
    }
  }

  public fileFormatSelector: boolean = false;

  toggleFileFormat() {
    this.fileFormatSelector = !this.fileFormatSelector;
  }

  descargarLista() {
    if (this.fileFormatSelector) {
      this.descargarListaPDF();
    } else {
      this.descargarListaXLSX();
    }
  }

  async descargarListaXLSX() {
    if (this.loginStatus) {
      this.fileDownloadProgressValue = '0';

      for (const key in document.querySelectorAll('#loaderFile')) {
        if (Object.prototype.hasOwnProperty.call(document.querySelectorAll('#loaderFile'), key)) {
          const element = document.querySelectorAll('#loaderFile')[key];
          (element as HTMLElement).style.display = 'flex';
          (element as HTMLElement).style.flexDirection = 'column';
        }
      }
      for (const key in document.querySelectorAll('#loaderFileMsg')) {
        if (Object.prototype.hasOwnProperty.call(document.querySelectorAll('#loaderFileMsg'), key)) {
          const element = document.querySelectorAll('#loaderFileMsg')[key];
          (element as HTMLElement).style.display = 'flex';
          (element as HTMLElement).style.flexDirection = 'column';
        }
      }
      this.auth.get('producto/listaPrecios')
      .then(async ($response: any)  => {
        if ($response.response) {
          this.listadoProductos = $response.response;
          let listadoProductosOrdenados: any[] = [];
          for (const producto of this.listadoProductos) {
            if (Array.isArray(listadoProductosOrdenados[producto.familia])) {
              if (Array.isArray(listadoProductosOrdenados[producto.familia][producto.categoria])) {
                if (Array.isArray(listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria])) {
                  listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria].push(producto);
                } else {
                  listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria] = new Array();
                  listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria].push(producto);
                }
              } else {
                listadoProductosOrdenados[producto.familia][producto.categoria] = new Array();
                listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria] = new Array();
                listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria].push(producto);
              }
            } else {
              listadoProductosOrdenados[producto.familia] = new Array();
              listadoProductosOrdenados[producto.familia][producto.categoria] = new Array();
              listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria] = new Array();
              listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria].push(producto);
            }
          }

          const print: any[] = [];
          if (this.listadoProductos[0].precio) {
            print.push(
              [
                'Foto',
                'Código',
                'Descripción',
                'Código de barras',
                'Unidad de medida',
                'Precio'+(this.iva_usuario.includes('NO INCLUYE') ? ' + I.V.A.' : (this.iva_usuario.includes('INCLUYE') ? ' I.V.A. Incluido' : (this.iva_usuario.includes('FINAL') ? ' Final' : ''))),
              ]
            );
          } else {
            print.push(
              [
                'Foto',
                'Código',
                'Descripción',
                'Código de barras',
                'Unidad de medida',
                'Precio 1',
                'Precio 2',
              ]
            );
          }
          print.push([]);

          let i = 3;
          let merges: any[] = [];
          let arrayProductImages: any[] = [];
          let productImgsPromises: Promise<unknown>[] = [];
          let productBlobsPromises: Promise<unknown>[] = [];
          let productImagesPromises: Promise<unknown>[] = [];
          let productImgs: any[] = [];
          let productBlobs: any[] = [];
          let productImages: any[] = [];

          for (const key in listadoProductosOrdenados) {
            if (Object.prototype.hasOwnProperty.call(listadoProductosOrdenados, key)) {
              merges.push({ row: i, cant: 3 });
              i = i + 3;
              print.push([key]);
              print.push([]);
              print.push([]);
              const familias = listadoProductosOrdenados[key];
              for (const key in familias) {
                if (Object.prototype.hasOwnProperty.call(familias, key)) {
                  merges.push({ row: i, cant: 2 });
                  i = i + 2;
                  print.push([key]);
                  print.push([]);
                  const categorias = familias[key];
                  for (const key in categorias) {
                    if (Object.prototype.hasOwnProperty.call(categorias, key)) {
                      merges.push({ row: i, cant: 1 });
                      i = i + 1;
                      print.push([key]);
                      const subcategorias = categorias[key];
                      for (const key in subcategorias) {
                        if (Object.prototype.hasOwnProperty.call(subcategorias, key)) {
                          i = i + 1;
                          const producto = subcategorias[key];

                          if(this.listaPreciosImagen) {
                            (function (index) {
                              arrayProductImages.push({ i: index, producto, imagen: producto.imagen.replace('.webp', '.jpg') });
                            })(i-1.75); // (i-2.75-minF-minC-minS)
                          }

                          if (producto.precio) {
                            print.push(
                              [
                                null,
                                producto.codigo_interno,
                                producto.nombre + ' - ' + producto.nombre_adicional,
                                producto.codigo_barras,
                                producto.unidad_medida,
                                producto.precio,
                              ],
                            );
                          } else {
                            print.push(
                              [
                                null,
                                producto.codigo_interno,
                                producto.nombre + ' - ' + producto.nombre_adicional,
                                producto.codigo_barras,
                                producto.unidad_medida,
                                producto.precio_1,
                                producto.precio_2,
                              ],
                            );
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          const hoy = new Date();
          const hoyString = ('0' + hoy.getDate()).slice(-2) + '-' + ('0' + (hoy.getMonth() + 1)).slice(-2) + '-' + hoy.getFullYear();

          const Excel = require('exceljs');
          const FileSaver = require('file-saver');

          // Workbook and properties

          const wb = new Excel.Workbook();
          wb.creator = 'SINA';
          wb.lastModifiedBy = 'SINA';
          wb.created = hoy;
          wb.modified = hoy;
          wb.lastPrinted = hoy;
          wb.properties.date1904 = true;
          wb.views = [
            {
              x: 0, y: 0, width: 10000, height: 20000,
              firstSheet: 0, activeTab: 1, visibility: 'visible'
            }
          ]

          // Worksheet and properties

          const ws = wb.addWorksheet(environment.APP_DOMAIN+'_Lista-de-precios',
            {
              properties: { tabColor: { argb:'FF057AFF' } },
              pageSetup: { paperSize: 9, orientation: 'portrait', fitToPage: true, fitToWidth: 1, fitToHeight: 0, printTitlesRow: '1:2' },
              headerFooter: { oddHeader: 'Lista de precios '+environment.APP_DOMAIN+' al ' + hoyString + ' - ' + this.iva_usuario, oddFooter: "&LPágina &P de &N" }
            }
          );
          ws.state = 'visible';
          ws.properties.defaultRowHeight = 50;
          ws.autoFilter = 'B:C';

          // Adding Rows

          ws.addRows(print);

          // Styles

          // Iterate over all rows that have values in a worksheet to set custom height
          ws.eachRow(function(row: any, rowNumber: number) {
            row.height = 50;
          });

          // Columna precio alineada a la derecha

          ws.getColumn('A').alignment = { vertical: 'middle', horizontal: 'center' };
          ws.getColumn('F').alignment = { vertical: 'middle', horizontal: 'right' };
          if (this.listadoProductos[0]?.precio_2) {
            ws.getColumn('G').alignment = { vertical: 'middle', horizontal: 'right' };
          }

          // Anchos de columna

          ws.getColumn('A').width = 12;
          ws.getColumn('B').width = 12;
          ws.getColumn('C').width = 47;
          ws.getColumn('D').width = 15;
          ws.getColumn('E').width = 15;
          ws.getColumn('F').width = 14;
          if (this.listadoProductos[0]?.precio_2) {
            ws.getColumn('G').width = 14;
          }

          // Borde izquierdo primer columna
          // Borde derecho a cada columna

          ws.getColumn('A').border = { left: { style: 'thin' }, right: { style: 'thin' } };
          ws.getColumn('B').border = { right: { style: 'thin' } };
          ws.getColumn('C').border = { right: { style: 'thin' } };
          ws.getColumn('D').border = { right: { style: 'thin' } };
          ws.getColumn('E').border = { right: { style: 'thin' } };
          ws.getColumn('F').border = { right: { style: 'thin' } };
          if (this.listadoProductos[0]?.precio_2) {
            ws.getColumn('F').border = { right: { style: 'mediumDashed' } };
            ws.getColumn('G').border = { right: { style: 'thin'} };
          }

          // Borde inferior a la ultima fila
          ws.getCell('A' + ws.rowCount).border = { left: { style: 'thin' }, right: { style: 'thin' }, bottom: { style: 'thin' } };
          ws.getCell('B' + ws.rowCount).border = { right: { style: 'thin' }, bottom: { style: 'thin' } };
          ws.getCell('C' + ws.rowCount).border = { right: { style: 'thin' }, bottom: { style: 'thin' } };
          ws.getCell('D' + ws.rowCount).border = { right: { style: 'thin' }, bottom: { style: 'thin' } };
          ws.getCell('E' + ws.rowCount).border = { right: { style: 'thin' }, bottom: { style: 'thin' } };
          ws.getCell('F' + ws.rowCount).border = { right: { style: 'thin' }, bottom: { style: 'thin' } };
          if (this.listadoProductos[0]?.precio_2) {
            ws.getCell('F' + ws.rowCount).border = { right: { style: 'mediumDashed', bottom: { style: 'thin' } } };
            ws.getCell('G' + ws.rowCount).border = { right: { style: 'thin'}, bottom: { style: 'thin' } };
          }

          // Cabeceras centradas

          const alignMiddleCenter = { vertical: 'middle', horizontal: 'center', wrapText: true };
          ws.getCell('A1').alignment = alignMiddleCenter;
          ws.getCell('B1').alignment = alignMiddleCenter;
          ws.getCell('C1').alignment = alignMiddleCenter;
          ws.getCell('D1').alignment = alignMiddleCenter;
          ws.getCell('E1').alignment = alignMiddleCenter;
          ws.getCell('F1').alignment = alignMiddleCenter;
          if (this.listadoProductos[0]?.precio_2) {
            ws.getCell('G1').alignment = alignMiddleCenter;
          }

          // Cabeceras Arial 12 y negrita

          const fontArial12Bold = { name: 'Arial', size: 12, bold: true };
          ws.getCell('A1').font = fontArial12Bold;
          ws.getCell('B1').font = fontArial12Bold;
          ws.getCell('C1').font = fontArial12Bold;
          ws.getCell('D1').font = fontArial12Bold;
          ws.getCell('E1').font = fontArial12Bold;
          ws.getCell('F1').font = fontArial12Bold;
          if (this.listadoProductos[0]?.precio_2) {
            ws.getCell('G1').font = fontArial12Bold;
          }

          // Cabeceras color de fondo gris

          const fillSolidGris = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFEAEAEA' } };
          ws.getCell('A1').fill = fillSolidGris;
          ws.getCell('B1').fill = fillSolidGris;
          ws.getCell('C1').fill = fillSolidGris;
          ws.getCell('D1').fill = fillSolidGris;
          ws.getCell('E1').fill = fillSolidGris;
          ws.getCell('F1').fill = fillSolidGris;
          if (this.listadoProductos[0]?.precio_2) {
            ws.getCell('G1').fill = fillSolidGris;
          }

          // Cabeceras con borde

          const bordeSimple = {
            top: {style:'thin'},
            left: {style:'thin'},
            bottom: {style:'thin'},
            right: {style:'thin'}
          };
          ws.getCell('A1').border = bordeSimple;
          ws.getCell('B1').border = bordeSimple;
          ws.getCell('C1').border = bordeSimple;
          ws.getCell('D1').border = bordeSimple;
          ws.getCell('E1').border = bordeSimple;
          ws.getCell('F1').border = bordeSimple;
          if (this.listadoProductos[0]?.precio_2) {
            ws.getCell('G1').border = bordeSimple;
          }

          // Mergeo cada cabecera con su row de abajo

          ws.mergeCells('A1', 'A2');
          ws.mergeCells('B1', 'B2');
          ws.mergeCells('C1', 'C2');
          ws.mergeCells('D1', 'D2');
          ws.mergeCells('E1', 'E2');
          ws.mergeCells('F1', 'F2');
          if (this.listadoProductos[0]?.precio_2) {
            ws.mergeCells('G1', 'G2');
          }

          // Merge de las rows que obtuve antes
          const fillSolidAzul = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF00B0F0' } };

          for (const m of merges) {
            let idCeldaInicio = 'A' + m.row;
            let idCeldaFin = 'F' + (m.row + (m.cant - 1));
            if (this.listadoProductos[0]?.precio_2) {
              idCeldaFin = 'G' + (m.row + (m.cant - 1));
            }
            ws.getCell(idCeldaInicio).font = fontArial12Bold;
            ws.getCell(idCeldaInicio).border = bordeSimple;
            ws.getCell(idCeldaInicio).alignment = alignMiddleCenter;
            if (m.cant === 3) {
              ws.getCell(idCeldaInicio).fill = fillSolidAzul;
            }
            ws.mergeCells(idCeldaInicio, idCeldaFin);
          }

          try {
            let c = 0;
            const totalImgs = arrayProductImages.length;
            const totalImgsChunks = totalImgs / 25;
            while (arrayProductImages.length > 0) {
              productImgsPromises = [];
              const arrayImg: any[] = arrayProductImages.splice(0, 25);
              for (const arrayData of arrayImg) {
                productImgsPromises.push(new Promise((resolve, reject) => {
                  fetch(arrayData.producto.imagen.replace('.webp', '.jpg'))
                  .then((img: any) => {
                    resolve({ i: arrayData.i, producto: arrayData.producto, img });
                  })
                  .catch((error: any) => {
                    resolve({ i: arrayData.i, producto: arrayData.producto });
                  })
                }));
              }

              const productImgsResolved = await Promise.all(productImgsPromises);
              productImgs.push(...productImgsResolved);
              c++;
              this.fileDownloadProgressValue = Math.min(Math.max(((c * 100) / totalImgsChunks), 0), 100).toFixed(0); // Clamp entre 0 y 100
            }

            for (const imgData of productImgs) {
              if (imgData.img) productBlobsPromises.push(new Promise((resolve, reject) => {
                imgData.img.blob()
                .then((blob: any) => {
                  resolve({ i: imgData.i, producto: imgData.producto, blob });
                })
                .catch((error: any) => {
                  resolve({ i: imgData.i, producto: imgData.producto });
                })
              }));
            }

            productBlobs = await Promise.all(productBlobsPromises);
            for (const blobData of productBlobs) {
              if (blobData.i && blobData.producto && blobData.blob) {
                productImagesPromises.push(new Promise((resolve, reject) => {
                  const reader = new FileReader();
                  reader.onloadend = () => {
                    resolve({ producto: blobData.producto.codigo_interno, base64: reader.result, col: 0.31, row: blobData.i });
                  };
                  reader.onerror = () => {
                    reject(this);
                  };
                  reader.readAsDataURL(blobData.blob);
                }));
              }
            }

            productImages = await Promise.all(productImagesPromises);
            for (const pi of productImages) {
              // Add image to workbook by base64
              const imgID = wb.addImage({
                base64: pi.base64,
                extension: 'jpg',
              });

              ws.addImage(imgID, {
                tl: { col: pi.col, row: pi.row },
                ext: { width: 48, height: 32 }
              });
            }
          } catch (error) {
            this.data.log('Error adding products images at excel download, exception:', error);
          }

          // Descarga del archivo

          await wb.xlsx.writeBuffer().then((data: any) => {
            const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
            FileSaver.saveAs(blob, 'Lista de precios '+environment.APP_DOMAIN+' al ' + hoyString + '.xlsx');
          });
        }

        for (const key in document.querySelectorAll('#loaderFile')) {
          if (Object.prototype.hasOwnProperty.call(document.querySelectorAll('#loaderFile'), key)) {
            const element = document.querySelectorAll('#loaderFile')[key];
            (element as HTMLElement).style.display = 'none';
          }
        }
        for (const key in document.querySelectorAll('#loaderFileMsg')) {
          if (Object.prototype.hasOwnProperty.call(document.querySelectorAll('#loaderFileMsg'), key)) {
            const element = document.querySelectorAll('#loaderFileMsg')[key];
            (element as HTMLElement).style.display = 'none';
          }
        }
      })
      .catch(($error) => {
        this.data.log('descargarlista error home:', $error);
      });
    } else {
      this.data.toggleLoginModal();
    }
  }

  public showPDFModal: boolean = false;
  public precioUnico: boolean = true;

  descargarListaPDF() {
    this.showPDFModal = true;
    this.fileDownloadProgressValue = '0';

    for (const key in document.querySelectorAll('#loaderFile')) {
      if (Object.prototype.hasOwnProperty.call(document.querySelectorAll('#loaderFile'), key)) {
        const element = document.querySelectorAll('#loaderFile')[key];
        (element as HTMLElement).style.display = 'flex';
        (element as HTMLElement).style.flexDirection = 'column';
      }
    }
    this.auth.get('producto/listaPrecios')
    .then(async ($response: any)  => {
      if ($response.response) {
        const hoy = new Date();
        const hoyString = ('0' + hoy.getDate()).slice(-2) + '-' + ('0' + (hoy.getMonth() + 1)).slice(-2) + '-' + hoy.getFullYear();

        const productos = $response.response;
        this.listadoProductos = $response.response;
        this.precioUnico = this.listadoProductos[0]?.precio_2 ? false : true;
        let listadoProductosOrdenados: any[] = [];

        let productImgsPromises: Promise<any>[] = [];
        let productBlobsPromises: Promise<any>[] = [];
        let productImagesPromises: Promise<any>[] = [];
        let productImgsResolved: any[] = [];
        let productBlobs: any[] = [];
        let productImages: any[] = [];

        try {
          let c = 0;
          const totalImgs = productos.length;
          const totalImgsChunks = totalImgs / 25;
          while (productos.length > 0) {
            productImgsPromises = [];
            productBlobsPromises = [];
            productImagesPromises = [];
            productImgsResolved = [];
            productBlobs = [];
            productImages = [];

            const arrayProducts: any[] = productos.splice(0, 25);
            if(this.listaPreciosImagen) {
              for (const p of arrayProducts) {
                productImgsPromises.push(new Promise((resolve, reject) => {
                  fetch(p.imagen.replace('.webp', '.jpg'))
                  .then((img: any) => {
                    resolve({ ...p, img });
                  })
                  .catch((error: any) => {
                    resolve(p);
                  })
                }));
              }

              productImgsResolved = await Promise.all(productImgsPromises);
              for (const p of productImgsResolved) {
                if (p.img) {
                  productBlobsPromises.push(new Promise((resolve, reject) => {
                    p.img.blob()
                    .then((blob: any) => {
                      resolve({ ...p, blob });
                    })
                    .catch((error: any) => {
                      resolve(p);
                    })
                  }));
                }
                else {
                  productBlobsPromises.push(new Promise((resolve, reject) => { resolve(p); }));
                }
              }

              productBlobs = await Promise.all(productBlobsPromises);
              for (const p of productBlobs) {
                if (p && p.blob) {
                  productImagesPromises.push(new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                      resolve({ ...p, base64: reader.result });
                    };
                    reader.onerror = () => {
                      resolve(p);
                    };
                    reader.readAsDataURL(p.blob);
                  }));
                }
                else {
                  productImagesPromises.push(new Promise((resolve, reject) => { resolve(p); }));
                }
              }
            }

            productImages = this.listaPreciosImagen ? await Promise.all(productImagesPromises) : arrayProducts;
            for (const producto of productImages) {
              if (Array.isArray(listadoProductosOrdenados[producto.familia])) {
                if (Array.isArray(listadoProductosOrdenados[producto.familia][producto.categoria])) {
                  if (Array.isArray(listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria])) {
                    listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria].push(producto);
                  } else {
                    listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria] = new Array();
                    listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria].push(producto);
                  }
                } else {
                  listadoProductosOrdenados[producto.familia][producto.categoria] = new Array();
                  listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria] = new Array();
                  listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria].push(producto);
                }
              } else {
                listadoProductosOrdenados[producto.familia] = new Array();
                listadoProductosOrdenados[producto.familia][producto.categoria] = new Array();
                listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria] = new Array();
                listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria].push(producto);
              }
            }
            c++;
            this.fileDownloadProgressValue = Math.min(Math.max((((c * 100) / totalImgsChunks) / 2), 0), 50).toFixed(0); // Clamp entre 0 y 50
          }
        } catch (error) {
          this.data.log('Error adding products at PDF download, exception:', error);
        }

        productImgsPromises = [];
        productBlobsPromises = [];
        productImagesPromises = [];
        productImgsResolved = [];
        productBlobs = [];
        productImages = [];

        const pdfModalMain = document.getElementById('pdf-modal-main');

        if (pdfModalMain) {
          let currFamily = '';
          for (const key in listadoProductosOrdenados) {
            if (Object.prototype.hasOwnProperty.call(listadoProductosOrdenados, key)) {
              currFamily = key;
              pdfModalMain.querySelector(`#${currFamily.split(' ').join('-')}`)?.insertAdjacentHTML('beforeend', `<div>
                <p class="fs-2">${key}</p>
              </div>`);

              const familias = listadoProductosOrdenados[key];
              for (const key in familias) {
                if (Object.prototype.hasOwnProperty.call(familias, key)) {
                  pdfModalMain.querySelector(`#${currFamily.split(' ').join('-')}`)?.insertAdjacentHTML('beforeend', `<div>
                    <p class="fs-4">${key}</p>
                  </div>`);

                  const categorias = familias[key];
                  for (const key in categorias) {
                    if (Object.prototype.hasOwnProperty.call(categorias, key)) {
                      pdfModalMain.querySelector(`#${currFamily.split(' ').join('-')}`)?.insertAdjacentHTML('beforeend', `<div>
                        <p class="fs-6">${key}</p>
                      </div>`);

                      const subcategorias = categorias[key];
                      let rows = "";
                      for (const p of subcategorias) {
                        rows += `<tr>
                          <td>` + (this.listaPreciosImagen ? `<div class="flex justify-content-center align-items-center"><img src="${p.base64}" title="F" alt="F" width="24" height="16"></div>` : '') + `</td>
                          <td>${p.codigo_interno}</td>
                          <td>${p.nombre}</td>
                          <td>${p.codigo_barras}</td>
                          <td>${p.unidad_medida}</td>
                          <td>${this.precioUnico ? p.precio : p.precio_1}</td>
                          ${!this.precioUnico ? ('<td>' + p.precio_2 + '</td>') : ''}
                        </tr>`;
                      }
                      pdfModalMain.querySelector(`#${currFamily.split(' ').join('-')}`)?.insertAdjacentHTML('beforeend', `<div>
                        <table>
                          <thead>
                            <tr>
                              <th>Foto</th>
                              <th>Código</th>
                              <th>Descripción</th>
                              <th>Código de barras</th>
                              <th>Unidad de medida</th>
                              <th>${this.precioUnico ? ('Precio'+(this.iva_usuario.includes('NO INCLUYE') ? ' + I.V.A.' : (this.iva_usuario.includes('INCLUYE') ? ' I.V.A. Incluido' : (this.iva_usuario.includes('FINAL') ? ' Final' : '')))) : 'Precio 1'}</th>
                              ${!this.precioUnico ? '<th>Precio 2</th>' : ''}
                            </tr>
                          </thead>
                          <tbody>
                            ${rows}
                          </tbody>
                        </table>
                      </div>`);
                    }
                  }
                }
              }
            }
          }

          const doc = new jsPDF();
          const children = Array.from(pdfModalMain.children);
          let count = 1;
          for (const div of children) {
            count++;
            const canvas: HTMLCanvasElement = await html2canvas(div as HTMLElement, { useCORS: true, allowTaint: false, logging: true });
            document.body.insertAdjacentHTML('beforeend', '<div id="canvas-placeholder' + div.id + '" style="visibility: hidden"></div>');
            document.getElementById('canvas-placeholder' + div.id)?.append(canvas);
            const totalWidth = canvas.clientWidth * window.devicePixelRatio;
            const totalHeigth = canvas.clientHeight * window.devicePixelRatio;
            document.getElementById('canvas-placeholder' + div.id)?.remove();
            const pageHeight = totalWidth * (297/206);
            const ctx = canvas.getContext('2d');
            if (ctx) {
              let i = 0;
              let currY = 0;
              do {
                i++;
                const miniCanvasData = ctx.getImageData(0, 1 + currY, totalWidth, pageHeight);
                currY += pageHeight;
                const newMiniCanvas = document.createElement('canvas');
                newMiniCanvas.width = totalWidth;
                newMiniCanvas.height = pageHeight;
                const newCtx = newMiniCanvas.getContext('2d');
                if (newCtx && miniCanvasData) {
                  newCtx.putImageData(miniCanvasData, 0, 0);
                  const img = newMiniCanvas.toDataURL("image/jpeg", 0.8);
                  // document.body.append(newMiniCanvas)

                  try {
                    doc.addImage(img, 'JPEG', 2, 0, 206, 297);
                    doc.addPage();
                  } catch (error) {
                    this.data.log('descargarlistapdf error pdf:', error);
                  }
                }
              } while ((pageHeight * i) <= totalHeigth)
            }
            this.fileDownloadProgressValue = Math.min(Math.max(((100 * ((count-1) / children.length) / 2) + 50), 0), 100).toFixed(0); // Clamp entre 0 y 100
          }

          doc.deletePage(doc.internal.pages.length-1);
          doc.save('Lista de precios '+environment.APP_DOMAIN+' al ' + hoyString + '.pdf');
        }
      }

      for (const key in document.querySelectorAll('#loaderFile')) {
        if (Object.prototype.hasOwnProperty.call(document.querySelectorAll('#loaderFile'), key)) {
          const element = document.querySelectorAll('#loaderFile')[key];
          (element as HTMLElement).style.display = 'none';
        }
      }
      for (const key in document.querySelectorAll('#loaderFileMsg')) {
        if (Object.prototype.hasOwnProperty.call(document.querySelectorAll('#loaderFileMsg'), key)) {
          const element = document.querySelectorAll('#loaderFileMsg')[key];
          (element as HTMLElement).style.display = 'none';
        }
      }

      this.showPDFModal = false;
    })
    .catch(($error) => {
      this.data.log('descargarlistapdf error home:', $error);
      this.showPDFModal = false;
    });
  }

  slideActivate(slideEvent: NgbSlideEvent) {
    if (this.ngCarousel) {
      if (slideEvent && slideEvent.source === NgbSlideEventSource.TIMER) {
        this.autoSlideEventCount++;
      } else {
        this.autoSlideEventCount = -1;
      }
    }
  }
  slideSwipeLeft(e: any) {
    this.autoSlideEventCount = -1;
    this.ngCarousel.next();
  }
  slideSwipeRight(e: any) {
    this.autoSlideEventCount = -1;
    this.ngCarousel.prev();
  }
  slideMouseenter(e: any) {
    this.autoSlideEventCount = -1;
  }
  slideMouseleave(e: any) {
    this.autoSlideEventCount = -1;
  }
}

@Pipe({
  name: 'safe',
})
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer){}
  transform(style: any) {
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }
}

@Pipe({
  name: 'safeHtmlHome',
})
export class SafeHtmlPipeHome implements PipeTransform {
  constructor(private sanitizer: DomSanitizer){}
  transform(html: any) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}

@Pipe({
  name: 'safeUrlHome',
})
export class SafeUrlPipeHome implements PipeTransform {
  constructor(private sanitizer: DomSanitizer){}
  transform(value: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }
}
