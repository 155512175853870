<div class="row message">
  <div class="container">
      <ngb-alert *ngIf="successMessage" (click)="alertClicked()" type="success" (close)="alertClicked();">
          <i class="fa fa-check-circle fa-3x" aria-hidden="true"></i> {{ successMessage }}
          <button class="actionButton transparentButton" [routerLink]="['/compra/carrito']">VER TODOS</button>
      </ngb-alert>
  </div>
</div>
<div class="container subheader">
  <div *ngIf="_existDesktop" class="sub-menu">
    <mat-nav-list>
      <a mat-list-item routerLink="/ofertas"><button mat-button >Ofertas</button></a>
      <a mat-list-item routerLink="/ofertas-semanales"><button mat-button >Ofertas semanales</button></a>
      <a mat-list-item routerLink="/novedades"><button mat-button >Novedades</button></a>
      <a mat-list-item routerLink="/ofertas-y-novedades"><button mat-button >Los más vendidos</button></a>
      <a mat-list-item routerLink="/sustentables"><button mat-button >Sustentables</button></a>
      <!-- <a mat-list-item routerLink="/combos"><button mat-button >Combos</button></a> -->
   </mat-nav-list>
    <mat-toolbar color="primary">
      <mat-menu #appMenu="matMenu">
        <button mat-menu-item *ngFor="let item of LinkListSubMenu">
          <a class="text-decoration-none" routerLink="{{item.url}}">
            {{item.texto}}
          </a>
        </button>
        <button mat-menu-item>
          <a class="text-decoration-none" (click)="registrar()">
            Nuevo cliente
          </a>
        </button>
        <button mat-menu-item>
          <a class="text-decoration-none" routerLink="preguntas-frecuentes">
            Preguntas frecuentes
          </a>
        </button>
        <button mat-menu-item>
          <a class="text-decoration-none" routerLink="contacto">
            Contacto
          </a>
        </button>
      </mat-menu>
      <button class="sub-menu-button" mat-button [matMenuTriggerFor]="appMenu">Conocé más <i class="fa fa-caret-down"></i></button>
    </mat-toolbar>
  </div>
  <h1 class="title">Artículos de limpieza por mayor, artículos de bazar</h1>
</div>
<div class="container-fluid px-0">
  <!-- Client only code -->
  <div *ngIf="clientOnlyRun">
    <ngb-carousel
      #ngcarousel
      id="main_carousel"
      class="carousel-fade"
      [ngClass]="{ 'ocultar-controles': autoSlideEventCount > 0 }"
      (slide)="slideActivate($event)"
      (swipeleft)="slideSwipeLeft($event)"
      (swiperight)="slideSwipeRight($event)"
      (mouseenter)="slideMouseenter($event)"
      (mouseleave)="slideMouseleave($event)"
      [showNavigationArrows]="true"
      [showNavigationIndicators]="true"
      [animation]="false"
      [interval]="config.carouselHomeInterval"
      [keyboard]="true"
      [pauseOnHover]="true"
      [pauseOnFocus]="false"
      [wrap]="true"
    >
      <ng-template ngbSlide *ngFor="let item of imageSources; let $index = index" [id]="'pos'+$index">
        <div>
          <a *ngIf="item.link" [href]="item.link">
            <img [src]="item.imagen" [alt]="'slide-'+$index" title="Banner Slide" width="320" height="170">
          </a>
          <img *ngIf="!item.link" [src]="item.imagen" [alt]="'slide-'+$index" title="Banner Slide" width="320" height="170">
        </div>
      </ng-template>
    </ngb-carousel>
  </div>
  <!-- Server only code -->
  <div *ngIf="serverOnlyRun">
    <!-- Loading spinner -->
    <div class="spinner">
      <div class="dot1"></div>
      <div class="dot2"></div>
    </div>
  </div>
</div>
<div class="container">
  <!-- Client only code -->
  <div *ngIf="clientOnlyRun" class="mt-4 pb-4 mt-md-5 pb-md-5" style="border-bottom: 1px solid #cccccc;">
    <div class="w-100 mb-3">
      <a routerLink="/nuestros-productos">
        <!-- TODO: traerlos por api con la config (src y alt) -->
        <img class="w-100 d-none d-md-block" [src]="bannerSinaDesktopImagen" [alt]="bannerSinaDesktopTitulo" title="Sina">
        <img class="w-100 d-block d-md-none" [src]="bannerSinaMobileImagen" [alt]="bannerSinaMobileTitulo" title="Sina">
      </a>
    </div>
    <!-- SwiperJS -->
    <swiper-container
      #swipercarouselsinauno
      id="home-swiper-productos-sina"
      init="false"
    >
      <swiper-slide *ngFor="let item of productosSina.lista">
        <app-producto-item [item]="item" [sesion]="loginStatus" [mensaje]="_success"></app-producto-item>
      </swiper-slide>
      <swiper-slide class="h-auto">
        <div
          style="background-color: rgb(5, 121, 255);"
          class="w-100 h-100 m-auto d-flex justify-content-center align-items-center"
        >
          <div>
            <div class="resultado__head">
              <div class="texto text-white">
                <p>NUESTROS PRODUCTOS</p>
              </div>
              <div class="link">
                <a routerLink="/nuestros-productos">
                  <button type="button">VER TODOS</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper-container>
  </div>
  <!-- Client only code -->
  <div *ngIf="clientOnlyRun">
    <div class="resultado row" style="margin: 50px 0px;" *ngFor="let row of listaResultados">
        <div class="col-6 custom__col-md-15 img-resultado">
          <a class="card-category" routerLink="{{row.head.filter}}">
                <div class="img">
                    <img style="width:100%;" [ngClass]="'lazyload'" [src]="row.head.imagen" alt="Sina">
                </div>
          </a>
        </div>
        <div class="resultado__item col-sm-12 col-md-6 custom__col-md-15" *ngFor="let item of row.lista">
          <app-producto-item [item]="item" [sesion]="loginStatus" [mensaje]="_success"></app-producto-item>
        </div>
      </div>
  </div>
  <!-- Server only code -->
  <div *ngIf="serverOnlyRun">
    <div class="resultado row" style="margin: 50px 0px;" *ngFor="let row of initialHomeArray">
      <div class="col-6 custom__col-md-15">
        <div class="resultado__head" [style.background-color]="row.head.background_color" >
          <div class="img">
            <img width="32" height="32" [ngClass]="'lazyload'" src="./assets/images/header/logo_mobile_{{row.head.icono_color}}.webp" alt="Sina">
          </div>
          <div class="texto" [style.color]="row.head.texto_color">
            <p>{{row.head.texto}}</p>
          </div>
          <div class="link">
            <a routerLink="{{row.head.filter}}">
              <button>VER TODOS</button>
            </a>
          </div>
          <div class="url">
            <p>WWW.<strong>SINA</strong>.COM.AR</p>
          </div>
        </div>
      </div>
      <div class="resultado__item col-6 custom__col-md-15" *ngFor="let item of [0,1,2,3]">
        <!-- Loading spinner -->
        <div class="spinner">
          <div class="dot1"></div>
          <div class="dot2"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<section>
  <div class="col-12" *ngIf="loading; else content">
    <!-- Loading spinner -->
    <div class="spinner">
      <div class="dot1"></div>
      <div class="dot2"></div>
    </div>
  </div>
  <ng-template #content>
    <div class="container mb-4">
      <div class="row" >
        <div class="col-12 col-md-6 align-self-center text-center" *ngFor="let item of contenido.items">
          <div *ngIf="item.type === 'Texto'" [innerHTML]="item.contenido | safeHtmlHome"></div>
          <iframe *ngIf="item.type === 'Link'" width="100%" height="300" [src]="'https://www.youtube.com/embed/'+item.contenido | safeUrlHome" title="Video - Sina" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </ng-template>
</section>

<!-- Client only code -->
<div *ngIf="clientOnlyRun">
  <div class="container">
    <div class="banner_final row">
      <a class="p-0" routerLink="preguntas-frecuentes">
        <img width="200" height="180" [ngClass]="'lazyload'" data-src="./assets/images/home/a4.png" alt="Preguntas frecuentes" title="Preguntas frecuentes" />
      </a>
      <a class="p-0" routerLink="envios">
        <img width="200" height="180" [ngClass]="'lazyload'" data-src="./assets/images/home/a2.png" alt="Envíos" title="Envíos" />
      </a>
      <a class="p-0" (click)="registrar()">
        <img width="200" height="180" [ngClass]="'lazyload'" data-src="./assets/images/home/a1.png" alt="Registrarse" title="Registrarse" />
        <div class="already_registered" *ngIf="flagYaRegistrado">Hay una sesión iniciada actualmente.</div>
      </a>
      <div class="col-md-3 divLista">
        <div id="lista-precios">
          <a *ngIf="config.switchComprasActivo" class="p-0" (click)="descargarLista()">
            <img width="200" height="180" id="rel1" [ngClass]="'lazyload'" data-src="./assets/images/home/a3.png" alt="Descargar lista de precios" title="Descargar lista de precios"/>
          </a>
          <div *ngIf="data.statusLogin" class="d-flex gap-2 justify-content-center my-2" style="color: #057aff;">
            <input type="checkbox" role="switch" class="custom-switch" id="toggleFormat1" [checked]="fileFormatSelector" (click)="toggleFileFormat()"/>
            <label for="toggleFormat1">Formato: {{ fileFormatSelector ? 'PDF' : 'Excel' }}</label>
          </div>
          <div *ngIf="data.statusLogin" class="d-flex d-md-block px-2 px-md-0">
            <input type="checkbox"  [(ngModel)]="listaPreciosImagen" name="lista_precios_imagen" aria-label="Lista de precios con Imagenes" class="mt-md-2">
            <label for="lista_precios_imagen" class="p-2" style="color:#057aff; font-size:12px;">Incluir fotos de productos</label>
          </div>
          <div id="loaderFile" style="display: none; position: relative; margin: auto; margin-top: 10px; max-width: 100px;">
            <span style="color:#057aff; font-size:11px;">% {{ fileDownloadProgressValue }}</span>
            <span style="color:#057aff; font-size:11px;">Generando archivo</span>
            <span style="color:#057aff; font-size:11px;">Por favor espere...</span>
            <img width="50" height="40" style="width: 40px; margin: auto;" id="rel2" [ngClass]="'lazyload'" data-src="./assets/images/loader/ajax-loader.gif"/>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="padding: 15px;">
      <p class="iva__custom__border w-auto">{{iva_usuario}}</p>
    </div>
  </div>

  <dialog open *ngIf="showPDFModal" id="pdf-modal">
    <div id="pdf-modal-content">
      <div><p>Estamos generando el archivo PDF...</p></div>
      <div>
        <span style="color:#0579ff; font-size:11px;">Por favor espere...</span>
        <span style="color:#0579ff; font-size:11px; margin-left: 10px;">% {{ fileDownloadProgressValue }}</span>
      </div>
      <div id="pdf-modal-main">
        <div id="Limpieza" class="d-flex flex-column justify-content-start align-items-center">
          <div>
            <img class="my-2" title="imagen" src="/assets/images/header/logo_azul.webp" width="166.5" height="51" alt="Logo SINA">
          </div>
        </div>
        <div id="Bazar" class="d-flex flex-column justify-content-start align-items-center"></div>
        <div id="Textil" class="d-flex flex-column justify-content-start align-items-center"></div>
        <div id="Liquidos" class="d-flex flex-column justify-content-start align-items-center"></div>
        <div id="Jardin-y-riego" class="d-flex flex-column justify-content-start align-items-center"></div>
        <div id="Profesional" class="d-flex flex-column justify-content-start align-items-center"></div>
        <div id="Mas-productos" class="d-flex flex-column justify-content-start align-items-center"></div>
      </div>
    </div>
  </dialog>

  <div class="instafeed container-fluid m-auto mb-2">
    <div class="text-center fs-3 fw-bold text-primary mb-2">
      <a href="https://www.instagram.com/sina.argentina/" class="d-inline-block text-decoration-none" style="font-family: 'Montserrat';" target="_blank" rel="noopener noreferrer">
        <i class="fa fa-instagram pe-2" aria-hidden="true"></i>¡Seguinos en Instagram!
      </a>
    </div>
    <div id="instafeed" class="row"></div>
  </div>

  <div class="row nuevocliente" *ngIf="!data.statusLogin">
    <div class="container">
        <div class="contain text-center">
            <h1>Nuevo Cliente</h1>
            <p>Compra más rápido y seguro.</p>
            <p>Sólo tenés que completar la solicitud de alta.</p>
            <p  *ngIf="!UserLog">
                <button class="whiteButton" type="button" (click)="registrar()" >REGISTRATE</button>
                <!-- PREVIO
                <button class="actionButton"> {{"Registrarse"}} </button>
                -->
            </p>
        </div>
    </div>
  </div>
</div>

<nav class="container links__de__interes">
  <a target="_blank" rel="noopener" href="https://articulosdelimpiezapormayor.com.ar/" class="actionButton">Limpieza por mayor <i class="fa fa-external-link"></i></a>
  <a target="_blank" rel="noopener" href="https://traposdepiso.com.ar/" class="actionButton">Trapos de piso <i class="fa fa-external-link"></i></a>
  <a target="_blank" rel="noopener" href="https://articulosdebazar.com.ar/" class="actionButton">Bazar por mayor <i class="fa fa-external-link"></i></a>
  <a target="_blank" rel="noopener" href="https://escobaspormayor.com.ar/" class="actionButton">Escobas por mayor <i class="fa fa-external-link"></i></a>
</nav>
