<ng-sidebar-container>
  <ng-sidebar [(opened)]="_opened" [mode]="_MODES[_option]" *ngIf="_existMobile">
      <app-sidebar></app-sidebar>
  </ng-sidebar>
  <div ng-sidebar-content class="ventana" #ventana (click)="eventoclick($event)">
      <!-- Client only code -->
      <div *ngIf="actualRoute.indexOf('compra') === -1 && clientOnlyRun" class="sticky_socials_container">
          <ul class="sticky_socials">
                <a href="www.sina.com.ar" target="_blank" rel="noopener noreferrer">
                    <li>
                    <span class="img_span"><img width="24" height="24" style="margin-left: 5px;" [ngClass]="'lazyload'" data-src="./assets/images/header/email.svg" title="Email"></span>
                    </li>
                </a>
              <ng-container *ngFor="let item of SocialList">
                <a href="{{item.url}}" target="_blank" rel="noopener noreferrer">
                    <li *ngIf=item.activo>
                      <span class="img_span"><img width="24" height="24" [ngClass]="'lazyload'" [attr.data-src]="item.urlImg" title="{{item.texto}}"></span>
                    </li>
                </a>
              </ng-container>
          </ul>
      </div>
      <app-header
          [greetMessage]="childmessage"
          [headerStatus]="menuStatus"
      ></app-header>
      <router-outlet></router-outlet>
      <!-- Client only code -->
      <div class="modal__container" [ngClass]="{'modalHide': loginStatus}" (click)="closeFull($event)" *ngIf="!loginStatus && clientOnlyRun">
        <!-- Overlay de Mensaje Adicional -->
        <div *ngIf="mostrarAltaMensajeAdicional" class="overlay-msj-adicional">
            <div class="msj-adicional-card">
            <h2 class="registro-title">{{config.mensajeAdicionalRegistroTitulo}}</h2>
            <p class="msj-adicional-p">{{config.mensajeAdicionalRegistroMensaje}}</p>
            <button class="btn btn-outline-dark actionButton" (click)="cerrarAltaMensajeAdicional()">Aceptar</button>
            </div>
        </div>
        <div role="document" class="modal-dialog">
            <div class="loading" [ngClass]="{'loaded': !loginLoading}">
              <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
            <div class="modal-content d-block">
              <div class="modal-header d-block">
                  <img width="150" height="66" class="desktop" [ngClass]="'lazyload'" data-src="./assets/images/header/logo_azul.webp" alt="Sina">
                  <img width="45" height="45" class="mobile" [ngClass]="'lazyload'" data-src="./assets/images/header/logo_mobile_azul.webp" alt="Sina">
                  <h4 class="modal-title desktop">{{registrarStatus ? (config.switchAltasActivo ? "Registrando nuevo usuario" : "&#x26A0; Altas de usuarios suspendidas temporalmente &#x26A0;") : ("Bienvenido | Bienvenida")}}</h4>
                  <h4 class="modal-title movil">{{registrarStatus ? (config.switchAltasActivo ? "Completá los datos para crear tu cuenta" : "&#x26A0; Altas suspendidas temporalmente &#x26A0;") : ("Bienvenido | Bienvenida")}}</h4>
                  <button aria-label="Close" class="close p-0 bg-transparent border-0 fs-5 fw-bold opacity-25" type="button" (click)= "closeModal()">
                    <span style="color: red;" aria-hidden="true">X</span>
                  </button>
              </div>
              <div class="modal-body">
                  <form>
                    <div class="row" style="display: flex; align-items: center;" *ngIf="!registrarStatus">
                      <div class="col-12 col-md-6 modal__texto">
                          <img width="322" height="276" [ngClass]="'lazyload'" data-src="./assets/images/home/logo1.png" alt="Sina" style="cursor: pointer;" [routerLink]="['/']" (click)="_toggleRegistrarStatus()">
                      </div>
                      <div class="col-12 col-md-6 modal__registrar" *ngIf="!recuperarClave">
                          <p>Si ya tenes usuario:</p>
                          <input type="text" [(ngModel)]="login.user" name="user" aria-label="Usuario" placeholder="Usuario" (keydown)="enterLogin($event)" autocomplete="username">
                          <!-- <input type="password" [(ngModel)]="login.pass" name="pass" placeholder="Contraseña" (keydown)="enterLogin($event)" autocomplete="current-password"> -->
                          <div>
                              <input class="password__input" [type]="password_type" [(ngModel)]="login.pass" name="pass" aria-label="Password" placeholder="Contraseña" (keydown)="enterLogin($event)" autocomplete="current-password">
                              <button class="password__eye" type="button" *ngIf="password_type === 'password'" (click)="togglePasswordType()" aria-label="Mostrar Password"><i class="fa fa-eye-slash" aria-hidden="true"></i></button>
                              <button class="password__eye" type="button" *ngIf="password_type === 'text'" (click)="togglePasswordType()" aria-label="Ocultar Password"><i class="fa fa-eye" aria-hidden="true"></i></button>
                          </div>
                          <button class="btn btn-outline-dark actionButton" type="button"(click)="loginModal(login.user, login.pass)">INGRESAR</button>
                          <p style="color: #F44336; margin-top: 5px;" *ngIf="login.error">{{login.errorMsg || "Error en el logeo"}}</p>
                          <a style="cursor: pointer;" (click)="recuperarClave = true">Olvide la contraseña</a>
                      </div>
                      <div class="col-12 col-md-6 modal__registrar" *ngIf="recuperarClave">
                          <p *ngIf="!recuperarOk">Ingrese su email para recuperar la contraseña de su cuenta:</p>
                          <input *ngIf="!recuperarOk" type="text" [(ngModel)]="login.user" name="user" aria-label="Email" placeholder="Email" (keydown)="enterRecuperar($event)" autocomplete="username">
                          <p style="color: #057aff; margin-top: 5px; text-align: center;" *ngIf="recuperarOk">{{recuperarOk}}</p>
                          <p style="color: #F44336; margin-top: 5px;" *ngIf="recuperarError">{{recuperarError}}</p>
                          <button *ngIf="!recuperarOk" class="btn btn-outline-dark actionButton" type="button"(click)="enterRecuperarEvento()">RECUPERAR CONTRASEÑA</button>
                          <a style="cursor: pointer;" (click)="recuperarClave = false; recuperarOk = ''; recuperarError = ''">Iniciar Sesión</a>
                      </div>
                    </div>
                  </form>
                    <div class="row position-relative" *ngIf="registrarStatus && config.switchAltasActivo" >
                      <div class="steps desktop">
                        <h1 class="registro-title mt-2">Completá los datos para crear tu cuenta</h1>
                      </div>
                      <div class="row stepsContent position-relative" [ngClass]="{'focusingPassword': focusingPassword}" (scroll)="stepUnoScroll($event)">
                        <div *ngIf="showScrollPromptUno" class="scroll-prompt w-auto"><div class="scroll-prompt-shape"></div></div>
                          <form class="notProcessing d-flex flex-wrap px-0" *ngIf="!processing.started">
                              <div class="col-12 col-md-6 modal__registrar" style="padding: 5px">
                                <input #input_razon_social [ngClass]="{'noValidado': validador?.razon_social}" type="text" placeholder="Nombre o Razón social*" [(ngModel)]="contacto.razon_social" name="razon_social" aria-label="Razon Social" (focus)="campoRegistroFocused()" (blur)="campoRegistroBlured()">
                                <input #input_email [ngClass]="{'noValidado': validador?.email}" type="text" placeholder="Email*" [(ngModel)]="contacto.email" name="email" aria-label="Email" (focus)="campoRegistroFocused()" (blur)="campoRegistroBlured()" autocomplete="username">
                                <span *ngIf="validationEmail" style="color: red;">Formato incorrecto de email</span>
                                <input #input_check_email [ngClass]="{'noValidado': validador?.checkemail}" type="text" placeholder="Repetir email*" [(ngModel)]="contacto.emailRepetido" name="emailRepetido" aria-label="Repetir Email" (focus)="campoRegistroFocused()" (blur)="campoRegistroBlured()" autocomplete="username">
                                <span *ngIf="validationCheckEmail" style="color: red;">Los emails no son iguales</span>
                                <ng-select #responsable labelForId="responsable_value" style="max-height: 32px; margin: 6px 0px;" [ngClass]="{'noValidado': validador?.cat_selected}"
                                  [clearable]="true"
                                  bindLabel="text"
                                  bindValue="codigo"
                                  (change)="refreshCUIT($event)"
                                  ng-model="cat_selected"
                                  placeholder="CAT. IVA.*"
                                  (focus)="focusResponsable()"
                                >
                                  <ng-option *ngFor="let country of data.reponsable_lista" [value]="country.codigo">
                                  {{country.text}}
                                  </ng-option>
                                </ng-select>

                                <!-- <select class="custom__select"
                                        #responsable
                                        ng-model="cat_selected"
                                        (change)="refreshCUIT($event.target.value)"
                                        placeholder="CAT. IVA.*"
                                        [ngClass]="{'noValidado': validador?.cat_selected}">
                                    <option value="0">CAT. IVA.*</option>
                                    <option *ngFor="let country of data.reponsable_lista"
                                            [value]="country.codigo"
                                            class="custom__select--option" value="1">
                                        {{ country.text }}
                                    </option>
                                </select> -->



                                <input #input_cuit [ngClass]="{'noValidado': validador?.cuit}" type="text" [placeholder]="cuit" [(ngModel)]="contacto.cuit" name="cuit" aria-label="Cuit" (focus)="campoRegistroFocused()" (blur)="campoRegistroBlured()">
                                <span *ngIf="validationCUIT && cuit === 'DNI (Solo numeros)*'" style="color: red;">Colocar 8 dígitos, sin puntos</span>
                                <span *ngIf="validationCUIT && cuit === 'CUIT (Solo numeros)*'" style="color: red;">Colocar 11 dígitos, sin guiones</span>
                                <span *ngIf="validationCUITverificador && cuit === 'CUIT (Solo numeros)*'" style="color: red;">Falló la comprobación del dígito verificador</span>
                                <input #input_telefono [ngClass]="{'noValidado': validador?.telefono}" type="text" placeholder="Teléfono*" [(ngModel)]="contacto.telefono" name="telefono" aria-label="Telefono" (focus)="campoRegistroFocused()" (blur)="campoRegistroBlured()">
                                <span *ngIf="validationTelefono" style="color: red; font-size: 0.9em;">Solo se permiten números y guiones</span>
                                <span *ngIf="validationCelular" style="color: red; font-size: 0.9em;">Solo se permiten números y guiones</span>
                              </div>
                              <div class="col-12 col-md-6 modal__registrar" style="padding: 5px">
                                <div class="contenedor_de_input" style="display: flex; justify-content: space-between">
                                  <input style="width: calc(100% - 110px);" type="text" placeholder="Domicilio" [(ngModel)]="contacto.domicilio_direccion" name="domicilio_direccion"  aria-label="Domicilio Direccion" (focus)="campoRegistroFocused()" (blur)="campoRegistroBlured()">
                                  <input style="width: 100px;" type="number" placeholder="Número" [(ngModel)]="contacto.domicilio_numero" name="domicilio_numero" aria-label="Domicilio Numero" (focus)="campoRegistroFocused()" (blur)="campoRegistroBlured()">
                                </div>
                                <div class="contenedor_de_input" style="display: flex; justify-content: space-between">
                                  <ng-select #provincia_value labelForId="provincia_value" [ngClass]="{'noValidado': validador?.domicilio_provincia}" style="max-height: 32px; margin: 6px 0px;width: calc(100% - 110px);"
                                  [clearable]="true"
                                  (change)="refreshProvincia($event)"
                                  ng-model="domicilio_provincia"
                                  placeholder="Provincia*"
                                  (focus)="focusProvincia()"
                                  >
                                    <ng-option *ngFor="let p of provincia" [value]="p.id">
                                      {{p.text}}
                                    </ng-option>
                                  </ng-select>
                                  <input style="width: 100px;" type="text" maxlength="8" placeholder="Código Postal"  [(ngModel)]="contacto.domicilio_codigo_postal" name="domicilio_codigo_postal" aria-label="Domicilio Codigo Postal" (focus)="campoRegistroFocused()" (blur)="campoRegistroBlured()">

                                </div>
                                <input #input_ciudad [ngClass]="{'noValidado': validador?.domicilio_ciudad}" type="text" placeholder="Localidad*" [(ngModel)]="contacto.domicilio_ciudad" name="domicilio_ciudad" aria-label="Domicilio Ciudad" (focus)="campoRegistroFocused()" (blur)="campoRegistroBlured()">

                                  <!-- <input #input_contrasena [ngClass]="{'noValidado': validador?.checkcontrasena, 'noValidado': validador?.contrasena}" [(ngModel)]="contacto.contrasena" name="contrasena" type="password" placeholder="Contraseña*" (focus)="passwordFocused()" (blur)="passwordBlured()" autocomplete="new-password"> -->
                                  <div>
                                    <input class="password__input" [type]="password_type" #input_contrasena [ngClass]="{'noValidado': validador?.checkcontrasena || validador?.contrasena}" [(ngModel)]="contacto.contrasena" name="contrasena" aria-label="Password" placeholder="Contraseña*" (focus)="passwordFocused()" (blur)="passwordBlured()" autocomplete="new-password">
                                    <button class="password__eye" type="button" *ngIf="password_type === 'password'" (click)="togglePasswordType()" aria-label="Mostrar Password"><i class="fa fa-eye-slash" aria-hidden="true"></i></button>
                                    <button class="password__eye" type="button" *ngIf="password_type === 'text'" (click)="togglePasswordType()" aria-label="Ocultar Password"><i class="fa fa-eye" aria-hidden="true"></i></button>
                                  </div>
                                  <span *ngIf="validationPassword" style="color: red; font-size: 0.9em;">La contraseña debe contener al menos 6 caracteres</span>
                                  <!-- <input #input_check_contrasena [ngClass]="{'noValidado': validador?.checkcontrasena}" [(ngModel)]="contacto.contrasenaRepetida" name="contrasenaRepetida" type="password" placeholder="Repetir contraseña*" (focus)="passwordFocused()" (blur)="passwordBlured()" autocomplete="new-password"> -->
                                  <div>
                                    <input class="password__input" [type]="password_type" #input_check_contrasena [ngClass]="{'noValidado': validador?.checkcontrasena}" [(ngModel)]="contacto.contrasenaRepetida" name="contrasenaRepetida" aria-label="Repetir Password" placeholder="Repetir contraseña*" (focus)="passwordFocused()" (blur)="passwordBlured()" autocomplete="new-password">
                                    <button class="password__eye" type="button" *ngIf="password_type === 'password'" (click)="togglePasswordType()" aria-label="Mostrar Password"><i class="fa fa-eye-slash" aria-hidden="true"></i></button>
                                    <button class="password__eye" type="button" *ngIf="password_type === 'text'" (click)="togglePasswordType()" aria-label="Ocultar Password"><i class="fa fa-eye" aria-hidden="true"></i></button>
                                  </div>
                                  <span *ngIf="validationCheckPassword" style="color: red;">Las contraseñas no son iguales</span>
                                  <div class="contenedor_de_input" style="display: flex; justify-content: space-between; flex-wrap: wrap; flex-direction: column; align-items: center; margin: 15px 0 0 0;">
                                    <label class="col-12" for="medio_contacto">¿Cómo te gustaría que nos comuniquemos con vos?</label>
                                    <div class="col-12" id="medio_contacto" style="display: flex;justify-content: center;">
                                      <div style="width: 31%; display: flex; flex-direction: column; justify-content: center;">
                                        <label for="contacto_por_telefono" class="fw-bold">Teléfono</label>
                                        <input type="checkbox" [(ngModel)]="contacto.contacto_por_telefono" name="contacto_por_telefono" aria-label="Contacto por Teléfono" (focus)="campoRegistroFocused()" (blur)="campoRegistroBlured()">
                                      </div>
                                      <div style="width: 31%; display: flex; flex-direction: column; justify-content: center;">
                                        <label for="contacto_por_whatsapp" class="fw-bold">Whatsapp</label>
                                        <input type="checkbox" [(ngModel)]="contacto.contacto_por_whatsapp" name="contacto_por_whatsapp" aria-label="Contacto por Whatsapp" (focus)="campoRegistroFocused()" (blur)="campoRegistroBlured()">
                                      </div>
                                      <div style="width: 31%; display: flex; flex-direction: column; justify-content: center;">
                                        <label for="contacto_por_mail" class="fw-bold">Email</label>
                                        <input type="checkbox" [(ngModel)]="contacto.contacto_por_mail" name="contacto_por_mail" aria-label="Contacto por Email" (focus)="campoRegistroFocused()" (blur)="campoRegistroBlured()">
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="contenedor_de_input gap-2 retiro-inputs">
                                  <div class="col-12 col-md-11"><input class="w-100" type="text" placeholder="¿En qué días podés recibir tu pedido?" [(ngModel)]="contacto.recepcion_dias" name="recepcion_dias" aria-label="¿En qué días podes recibir tu pedido?" (focus)="campoRegistroFocused()" (blur)="campoRegistroBlured()"></div>
                                  <div class="col-12 col-md-11 mb-1"><input class="w-100" type="text" placeholder="¿En qué horarios podés recibir tu pedido?" [(ngModel)]="contacto.recepcion_horarios" name="recepcion_horarios" aria-label="¿En qué horarios podées recibir tu pedido?" (focus)="campoRegistroFocused()" (blur)="campoRegistroBlured()"></div>
                                </div>
                              <div class="col-12" style="padding-left: 10px">
                                <label for="actividad">¡Contanos a qué te dedicás!</label>
                                <textarea [(ngModel)]="contacto.actividad" name="actividad" aria-label="Breve Descripcion Actividad" id="actividad" cols="30" rows="2" placeholder="BREVE DESCRIPCIÓN DE ACTIVIDAD"></textarea>
                              </div>
                          </form>
                          <div class="processing" *ngIf="processing.started">
                            <div class="loading" *ngIf="processing.right_now">
                              <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                            </div>
                            <div class="response" *ngIf="!processing.right_now">
                              <img width="50" height="50" class="processing__icono" *ngIf="!response.error" [ngClass]="'lazyload'" data-src="./assets/images/registro/confirmacion.webp" alt="Sina">
                              <img width="50" height="50" class="processing__icono" *ngIf="response.error" [ngClass]="'lazyload'" data-src="./assets/images/registro/error.png" alt="Sina">
                              <pre *ngIf="!response.error" style="border: 2px solid #00c973;">{{response.mensaje}}</pre>
                              <pre *ngIf="response.error" style="border: 2px solid red;">{{response.mensaje}}</pre>
                              <button  *ngIf="response.error" class="whiteButton" style="width: 50%;" type="button" (click)="response.value()">{{response.action}}</button>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="registrarStatus && !config.switchAltasActivo">
                      Las altas de nuevos usuarios se encuentran suspendidas temporalmente.
                    </div>
                  </div>
                  <div class="modal-footer d-block">
                      <div class="row m-0" *ngIf="!registrarStatus">
                          <div class="col-12 col-md-6 footer__col position-relative">
                              <p style="text-align: left; margin-top: 5px;">Aun no tenes usuario:</p>
                              <button class="whiteButton" type="button" [routerLink]="['/']" (click)="_toggleRegistrarStatus()">REGISTRATE</button>
                          </div>
                          <div class="col-12 col-md-6 footer__col">
                              <button type="button" class="facebook"> CONTINUAR CON FACEBOOK </button>
                          </div>
                      </div>
                      <div class="row" *ngIf="!registrarStatus && config.mensajeModalLoginActivo">
                          <div class="col-12 no-padding">
                              <div class="mensaje__login" [innerHTML]="config.mensajeModalLoginMensaje"></div>
                          </div>
                      </div>
                      <div class="row d-block" *ngIf="registrarStatus && !processing.started">
                          <button class="btn btn-outline-dark actionButton w-100" style="margin-bottom: 5px;" type="button" (click)="confirmarAlta()" *ngIf="config.switchAltasActivo">REGISTRARME</button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <!-- Client only code -->
      <div class="modal__container mobile__modal__container" [ngClass]="{'modalHide': carritoStatus}" (click)="carritoModal()" *ngIf="!carritoStatus && clientOnlyRun">
          <div role="document" class="modal-dialog" style="width: 950px;">
              <div class="modal-content">
                  <div class="modal-header mobile_padding-top_extra_60 justify-content-center">
                      <img width="150" height="66" [ngClass]="'lazyload'" data-src="./assets/images/header/logo_azul.webp" alt="Sina">
                      <h4 class="modal-title mobile_padding-top_extra_25">Tiene un carrito pendiente</h4>
                      <button aria-label="Close" class="close p-0 bg-transparent border-0 fs-5 fw-bold opacity-25" type="button" (click)= "carritoModal()">
                          <span style="color: red;" aria-hidden="true">X</span>
                      </button>
                  </div>
                  <div class="modal-body">
                      <div class="row">
                          <div class="card p-0 rounded-0">
                              <h3 class="card-header">{{childmessage.length}} compras pendientes</h3>
                              <div #inputCantidad class="card-block" style="overflow-y: scroll; height: 305px; overflow-x: hidden;">
                                  <div class="row carrito__header">
                                      <div class="custom__col-1">
                                          <div class="border-bottom fs-6 fw-bold">
                                          <p class="mb-2">Cantidad</p>
                                          </div>
                                      </div>
                                      <div class="custom__col-2 mobile_padding-left_extra">
                                          <div class="border-bottom fs-6 fw-bold">
                                              <p class="mb-2">Descripcion</p>
                                          </div>
                                      </div>
                                      <div class="custom__col-3">
                                          <div class="border-bottom fs-6 fw-bold">
                                          <p class="mb-2">Precio U.</p>
                                          </div>
                                      </div>
                                      <div class="custom__col-4 mobile_padding-left_extra">
                                          <div class="border-bottom fs-6 fw-bold">
                                          <p class="mb-2">Subtotal</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="row carrito__content" *ngFor="let compra of childmessage">
                                      <div class="custom__col-1">
                                          <input *ngIf="compra.cantPack == 1" name="cantidad" aria-label="Cantidad" type="number" min="1" max="999999" [(ngModel)]="compra.cantidad" value="{{compra.cantidad}}" (keyup)="revisarCantidad($event)">
                                          <select *ngIf="compra.cantPack != 1" name="cantidad" aria-label="Cantidad" [(ngModel)]="compra.cantidad">
                                              <option *ngFor="let cant of compra.arrayCants" [value]="cant">{{cant}}</option>
                                          </select>
                                      </div>
                                      <div class="custom__col-2">
                                          <p>{{compra.descripcion}}</p>
                                      </div>
                                      <div class="custom__col-3">
                                          <p>${{formatMoney(compra.precio)}}</p>
                                      </div>
                                      <div class="custom__col-4">
                                          <p>${{updatePrecio(compra.precio, compra.cantidad)}}</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="modal-footer carrito d-block">
                      <div class="row">
                          <div class="col-12 col-md-6 footer__col">
                              <button class="actionButton" type="button" (click)="carritoModal()">CONTINUAR COMPRA</button>
                          </div>
                          <div class="col-12 col-md-6 footer__col">
                              <button type="button" class="whiteButton" (click)="carritoCancelModal()">CANCELAR COMPRA</button>
                          </div>
                      </div>
                  </div>
              </div>
              </div>
      </div>
      <!-- Client only code -->
      <div class="modal__container" [ngClass]="{'modalHide': !actualEmergenteFlag }" (click)="closeEmergente($event)" *ngIf="!isLoggedIn && config.ventanaEmergenteActivo && actualEmergenteFlag && clientOnlyRun">
          <div role="document" class="modal-dialog">
              <div class="modal-content">
                  <div class="modal-header" style="padding: 0;">
                      <img width="300" height="400" [src]="config.ventanaEmergenteImagen || ''" alt="Sina" style="position: relative; bottom: 0px; left: 0px; width: 100%; height: auto;">
                      <button aria-label="Close" class="close p-0 bg-transparent border-0 fs-5 fw-bold opacity-25" type="button" (click)= "closeEmergente2()">
                      <span style="color: red;" aria-hidden="true">X</span>
                  </button>
                  </div>
              </div>
          </div>
      </div>
      <div class="modal__container" [ngClass]="{'modalHide': !actualEmergenteFlag }" (click)="closeEmergente($event)" *ngIf="isLoggedIn && hasCompras && config.ventanaEmergenteActivoLogged && actualEmergenteFlag && clientOnlyRun">
          <div role="document" class="modal-dialog">
              <div class="modal-content">
                  <div class="modal-header" style="padding: 0;">
                      <img width="300" height="400" [src]="config.ventanaEmergenteImagenLogged || ''" alt="Sina" style="position: relative; bottom: 0px; left: 0px; width: 100%; height: auto;">
                      <button aria-label="Close" class="close p-0 bg-transparent border-0 fs-5 fw-bold opacity-25" type="button" (click)= "closeEmergente2()">
                      <span style="color: red;" aria-hidden="true">X</span>
                  </button>
                  </div>
              </div>
          </div>
      </div>
      <div class="modal__container" [ngClass]="{'modalHide': !actualEmergenteFlag }" (click)="closeEmergente($event)" *ngIf="isLoggedIn && !hasCompras && config.ventanaEmergenteActivoSinCompra && actualEmergenteFlag && clientOnlyRun">
          <div role="document" class="modal-dialog">
              <div class="modal-content">
                  <div class="modal-header" style="padding: 0;">
                      <img width="300" height="400" [src]="config.ventanaEmergenteImagenSinCompra || ''" alt="Sina" style="position: relative; bottom: 0px; left: 0px; width: 100%; height: auto;">
                      <button aria-label="Close" class="close p-0 bg-transparent border-0 fs-5 fw-bold opacity-25" type="button" (click)= "closeEmergente2()">
                      <span style="color: red;" aria-hidden="true">X</span>
                  </button>
                  </div>
              </div>
          </div>
      </div>
      <!-- Client only code -->
      <div class="modal__container" [ngClass]="{'modalHide': representarStatus}" *ngIf="!representarStatus && clientOnlyRun"> <!-- (click)="closeFullRepresentar($event)"> -->
          <div class="loading" [ngClass]="{'loaded': !loginLoading}">
              <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
          </div>
          <div role="document" class="modal-dialog">
              <div class="modal-content">
              <div class="modal-header">
                  <img width="150" height="66" [ngClass]="'lazyload'" data-src="./assets/images/header/logo_azul.webp" alt="Sina" class="show__logo__representar">
                  <h4 class="modal-title w-100">Cuentas disponibles para representación</h4>
                  <button aria-label="Close" class="close p-0 bg-transparent border-0 fs-5 fw-bold opacity-25" type="button" (click)= "cerrarRepresentarCuenta()">
                    <span style="color: red;" aria-hidden="true">X</span>
                  </button>
              </div>
              <div class="modal-body">
                  <div class="row">
                      <div class="card d-block px-0 rounded-0">
                          <h3 class="card-header">Seleccione alguna de las {{cantCuentasRepresentar}} cuentas que puede representar</h3>
                          <div class="buscador_cuenta">
                            <input type="text" placeholder="Escriba para filtrar por Cód. Cliente, Razón Social o CUIT" [(ngModel)]="filterBusqueda" (keydown)="enterBusqueda($event)"
                              style="width: 100%;
                                border: none;
                                padding: 5px 10px;"
                              name="filtroBusqueda" aria-label="Filtro por Codigo Cliente, Razon Social o CUIT"
                            >
                          </div>
                          <div class="buscador_btn">
                              <span>
                                  <select (change)="cambiarCantFiltro($event)" aria-label="Cantidad de cuentas a mostrar">
                                      <option value="20">20</option>
                                      <option value="50">50</option>
                                      <option value="100">100</option>
                                  </select>
                              </span>
                              <button class="btn" type="button" (click)="filtrarCuentas()" aria-label="Buscar cuentas">
                                  <i class="fa fa-search" aria-hidden="true"></i>
                              </button>
                          </div>
                          <hr style="margin-top: 5px; margin-bottom: 5px;">
                          <div class="card-block" style="max-height: 200px; overflow-y: scroll; overflow-x: hidden;">
                              <div class="row carrito__header">
                                  <div class="col-rep-3">
                                      <div class="border-bottom">
                                          <p class="show__web m-0 p-0 representar_cuentas_table_header">Selección</p>
                                          <p class="show__mobile m-0 p-0 representar_cuentas_table_header"><i class="fa fa-check" aria-hidden="true"></i></p>
                                      </div>
                                  </div>
                                  <div class="col-rep-4">
                                      <div class="border-bottom">
                                          <p class="m-0 p-0 representar_cuentas_table_header">CUIT</p>
                                      </div>
                                  </div>
                                  <div class="col-rep-5">
                                      <div class="border-bottom">
                                      <p class="m-0 p-0 representar_cuentas_table_header">Razón social</p>
                                      </div>
                                  </div>
                              </div>
                              <div class="row carrito__content py-1" *ngFor="let cuenta of cuentasRepresentar; let $index = index;">
                                  <div class="col-rep-3">
                                      <div>
                                          <input [value]="cuenta.id" [checked]="$index === 0" type="radio" [(ngModel)]="cuenta.seleccion" name="opcionCuenta" aria-label="Seleccion cuenta" (click)="seleccionarCuenta(cuenta)" style="padding: 0; margin: auto;">
                                      </div>
                                  </div>
                                  <div class="col-rep-4">
                                      <p class="m-0 p-0">{{cuenta.cuit}}</p>
                                  </div>
                                  <div class="col-rep-5">
                                      <p class="m-0 p-0">{{cuenta.razon_social}}</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <p style="font-weight: bold; color: red; margin-left: 35px; margin-top: 20px; margin-bottom: -10px;">{{cuentaRespuesta}}</p>
              <div class="modal-footer carrito d-block">
                  <div class="row">
                      <div class="col-12 col-md-6 footer__col">
                          <button class="actionButton" type="button" (click)="representarCuenta(cuentaSeleccionada)">REPRESENTAR CUENTA</button>
                      </div>
                      <div class="col-12 col-md-6 footer__col">
                          <button type="button" class="whiteButton" (click)="cerrarRepresentarCuenta()"> CERRAR SESIÓN </button>
                      </div>
                  </div>
              </div>
              </div>
          </div>
      </div>
      <!-- Client only code -->
      <div class="modal__container" [ngClass]="{'modalHide': migrandoStatus}" (click)="closeFull3($event)" *ngIf="!migrandoStatus && clientOnlyRun">
          <div role="document" class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <img width="150" height="66" [ngClass]="'lazyload'" data-src="./assets/images/header/logo_azul.webp" alt="Sina" style="bottom: 25px;">
                  <h4 class="modal-title w-100 mt-3">Migrando datos</h4>
                  <button aria-label="Close" class="close p-0 bg-transparent border-0 fs-5 fw-bold opacity-25" type="button" (click)= "migrandoCancelModal()">
                    <span style="color: red;" aria-hidden="true">X</span>
                  </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div *ngIf="!login.confirMsg" class="card px-0">
                            <h3 class="card-header">¡Bienvenido! Ingrese los datos de confirmación</h3>
                            <p class="col-12 mb-0" style="text-align: center; padding: 5px 0px;">Si algunos de tus datos no corresponde con los que se indican a continuación contactate con un asesor de Sina para actualizar tu información</p>
                            <form>
                                <div class="card-block d-flex flex-wrap">
                                    <div class="col-12 col-md-6 mt-1" style="display: flex;flex-direction: column;">
                                        <input style="width: 100%; margin-bottom: 10px;" type="text" [(ngModel)]="auth.email" name="authEmail" aria-label="Email" disabled="disabled" autocomplete="username">
                                        <input style="width: 100%;" type="text" [(ngModel)]="auth.razonsocial" name="authRazonSocial" aria-label="Razon Social" disabled="disabled" autocomplete="username">
                                    </div>
                                    <div class="col-12 col-md-6 mt-1" style="display: flex;flex-direction: column;">
                                        <!-- <input style="width: 100%; margin-bottom: 10px;" name="contrasena" type="password" placeholder="Nueva contraseña" [(ngModel)]="migracion.pass_original" autocomplete="new-password"> -->
                                        <div style="width: 100%; margin-bottom: 10px;">
                                            <input class="password__input" [type]="password_type" name="contrasena" aria-label="Password" placeholder="Nueva contraseña" [(ngModel)]="migracion.pass_original" autocomplete="new-password">
                                            <button class="confirm__password__eye" type="button" *ngIf="password_type === 'password'" (click)="togglePasswordType()" aria-label="Mostrar Password"><i class="fa fa-eye-slash" aria-hidden="true"></i></button>
                                            <button class="confirm__password__eye" type="button" *ngIf="password_type === 'text'" (click)="togglePasswordType()" aria-label="Ocultar Password"><i class="fa fa-eye" aria-hidden="true"></i></button>
                                        </div>
                                        <!-- <input style="width: 100%;" type="password" placeholder="Repetir contraseña" [(ngModel)]="migracion.pass_repetido" name="migracion_pass_repetido" autocomplete="new-password"> -->
                                        <div style="width: 100%;">
                                            <input class="password__input" [type]="password_type" placeholder="Repetir contraseña" [(ngModel)]="migracion.pass_repetido" name="migracion_pass_repetido" aria-label="Repetir Password" autocomplete="new-password">
                                            <button class="confirm__password__eye" type="button" *ngIf="password_type === 'password'" (click)="togglePasswordType()" aria-label="Mostrar Password"><i class="fa fa-eye-slash" aria-hidden="true"></i></button>
                                            <button class="confirm__password__eye" type="button" *ngIf="password_type === 'text'" (click)="togglePasswordType()" aria-label="Ocultar Password"><i class="fa fa-eye" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-block">
                                    <div class="col-12" style="float: none;">
                                        <p style="color: #F44336; margin-top: 5px;" *ngIf="login.error">{{login.errorMsg || "Error en el logeo"}}</p>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="card" *ngIf="login.confirMsg">
                            <h3 class="card-header">Mensaje de confirmación</h3>
                            <div class="card-block">
                                <p>{{login.confirMsg}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer carrito d-block py-0 px-3">
                    <div class="row">
                        <div class="col-12 col-md-6 footer__col">
                            <button *ngIf="!login.confirMsg" class="actionButton" type="button" (click)="migrandoModal()">CONFIRMAR DATOS</button>
                        </div>
                        <div class="col-12 col-md-6 footer__col">
                            <button type="button" class="whiteButton" (click)="migrandoCancelModal()"> {{login.confirMsg ? "CERRAR" : "CANCELAR"}} </button>
                        </div>
                    </div>
                </div>
              </div>
          </div>
      </div>
      <!-- Nuevo boton fixed de "Chatea con nosotros" -->
      <!-- Client only code -->
      <a *ngIf="SocialList && SocialList[1] && SocialList[1].activo && actualRoute.indexOf('compra') === -1 && clientOnlyRun" href="{{SocialList[1].url}}" target="_blank" rel="noopener noreferrer" class="d-block position-fixed bottom-0 py-2 px-3 text-decoration-none text-white chat-flotante">
        ¡Chateá con nosotros! <i class="fa fa-whatsapp" aria-hidden="true"></i>
      </a>
      <app-footer></app-footer>
  </div>
</ng-sidebar-container>
