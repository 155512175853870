import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, Pipe, PipeTransform, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AutenticacionService } from '../autenticacion.service';
import { Producto, ProductoCompleto } from '../data';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { FacebookPixelService } from '../facebook-pixel.service';
import { SharedService } from '../shared.service';
import { DomSanitizer } from '@angular/platform-browser';
import { register, SwiperContainer } from 'swiper/element/bundle';
register();

@Component({
  selector: 'app-paquete',
  templateUrl: './paquete.component.html',
  styleUrls: ['./paquete.component.scss']
})
export class PaqueteComponent implements OnInit, AfterViewInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  config: any;
  public _success = new Subject<string>();
  public iva_usuario: string = '';
  staticAlertClosed = false;
  successMessage!: string;
  loginStatus: boolean = false;
  fullPath: any[] = [];
  paquete: ProductoCompleto = new ProductoCompleto();
  relacionados!: Producto[];
  arrayCants: any[] = [];

  full_link: string = '';

  tituloPaquete: string = '';

  clientOnlyRun: boolean = false;
  serverOnlyRun: boolean = false;

  constructor(
    private data: SharedService,
    // private http: HttpClient,
    private route: ActivatedRoute,
    private auth: AutenticacionService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private facebookPixelService: FacebookPixelService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.clientOnlyRun = true;
    } else {
      this.serverOnlyRun = true;
    }
  }

  respuesta = ($response: any)  => {
    this.relacionados = $response.response.productosRelacionados; // TODO: Van a tener relacionados? podría ser 4 insumos random de los que tiene
    this.paquete = $response.response;

    this.tituloPaquete = this.paquete['titulo'] + ', ' + this.paquete['titulo'] + ' al por mayor';
    this.data.updatePageTitle(this.paquete['titulo'] + ', ' + this.paquete['titulo'] + ' al por mayor | '+environment.APP_DOMAIN, 'Encontrá la mayor variedad de ' + this.paquete['titulo'] + ' al por mayor y al mejor precio en '+environment.APP_DOMAIN);

    this.paquete.fullLink = '/paquetes/' + this.paquete.id;
    this.paquete.cantidad = this.paquete['cantSugerida'] ? parseInt(this.paquete['cantSugerida']) : 1;
    if (this.paquete['cantPack'] !== '1') {
      this.paquete.cantidad = this.paquete['cantPack'] ? parseInt(this.paquete['cantPack']) : 1;
      for (let i = 0; i < 20; i++) {
        this.arrayCants[i] = this.paquete.cantidad * (i + 1);
      }
      for (let i = 0; i < 50; i++) {
        this.arrayCants[i + 20] = this.paquete.cantidad * (i + 3) * 10;
      }
    }

    this.paquete.comprado = false;

    this.paquete.precio_mostrado = this.paquete.precio ? this.paquete.precio.toString().replace(',', '.') : '0';
    this.paquete.precio_base = this.paquete.precioLista || this.paquete.precioListaBase;
    if (this.paquete.precio_base > 0 && this.paquete.precio_base > this.paquete.precio) {
      this.paquete.descuento = this.calcularDescuento(this.paquete.precio_base, this.paquete.precio_mostrado);
      // Decidimos si mostrar el descuento a partir numero mayor o igual a 5% OFF
      if (this.paquete.descuento >= 5) {
        this.paquete.show_descuento = true;
      } else {
        this.paquete.show_descuento = false;
      }
    } else {
      this.paquete.descuento = undefined;
      this.paquete.show_descuento = false;
    }

    this.data.lista.forEach((articulo_carrito) => {
      if (articulo_carrito.id === this.paquete.id) {
        this.paquete.comprado = true;
      }
    });
    if (environment.production) {
      this.googleAnalyticsService.viewItem({ sku: this.paquete.codInterno, descripcion: this.paquete.titulo, categoria: this.paquete.categorias ? this.paquete.categorias[0]?.nombre : undefined, precio: this.paquete.precio, cantidad: this.paquete.cantidad });
      this.facebookPixelService.viewItem({ sku: this.paquete.codInterno, descripcion: this.paquete.titulo, categoria: this.paquete.categorias ? this.paquete.categorias[0]?.nombre : undefined, precio: this.paquete.precio, cantidad: this.paquete.cantidad });
    }
  }
  ngOnInit(): void {
    this.data.updatePageTitle(environment.APP_NAME, 'Comprá con entrega a todo el país, hacé tu pedido online!');
    // subscribing to data on loginStatus
    this.data.currentLogin.pipe(takeUntil(this.destroy$)).subscribe(
      (status) => {
        this.loginStatus = status;
        this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
          const id = (params['id']);
          // Client only code.
          if (this.clientOnlyRun) {
            const $public = this.loginStatus ? '' : 'public/';
            // TODO: Rehacer este GET para obtener un paquete
            this.auth.get($public + 'paquetes/detalles/' + id)
            .then(this.respuesta)
            .catch(($error) => {
              this.data.log('currentloginstatus error paquete', $error);
            });
          }
        });
      },
    );
    // Client only code.
    if (this.clientOnlyRun) {
      setTimeout(() => this.staticAlertClosed = true, 5000);
      this._success.pipe(takeUntil(this.destroy$)).subscribe((message) => this.successMessage = message);
      this._success.pipe(takeUntil(this.destroy$), debounceTime(5000)).subscribe(() => this.successMessage = '');
    }

    this.data.currentUser.pipe(takeUntil(this.destroy$)).subscribe(($user: any) => {
      if ($user && $user['c'] === '1') {
        this.iva_usuario = '';
      } else {
        if ($user) {
          switch ($user['codCategoriaIva']) {
            case 'CF': this.iva_usuario = 'UNIT I.V.A. incluido'; break;
            case 'INR': this.iva_usuario = 'UNIT I.V.A. incluido'; break;
            case 'RS': this.iva_usuario = 'UNIT I.V.A. incluido'; break;
            case 'RSS': this.iva_usuario = 'UNIT I.V.A. incluido'; break;
            case 'RI': this.iva_usuario = 'UNIT + I.V.A.'; break;
            case 'EX': this.iva_usuario = 'UNIT I.V.A. incluido'; break;
            case 'PCE': this.iva_usuario = 'UNIT I.V.A. incluido'; break;
            case 'PCS': this.iva_usuario = 'UNIT I.V.A. incluido'; break;
            case 'EXE': this.iva_usuario = 'UNIT Final'; break;
            case 'SNC': this.iva_usuario = 'UNIT I.V.A. incluido'; break;
            default: this.iva_usuario = 'UNIT + I.V.A.';
          }
        }
      }
    });

    // subscribing to config change
    this.data.currentConfig.pipe(takeUntil(this.destroy$)).subscribe(
      (configuracion: any) => {
        this.config = configuracion;
      },
    );
  }

  ngAfterViewInit() {
    // Client only code.
    if (this.clientOnlyRun) {
      let fotosIntervalAttemptsCount = 0;
      const intervalFotosId = setInterval(() => {
        if (fotosIntervalAttemptsCount > 9) {
          clearInterval(intervalFotosId);
        }

        // Inicializo Swiper fotos y thumnails del producto
        try {
          // swiper elements fotos
          const swiperThumbnailsEl: SwiperContainer | null = document.querySelector('#paquete-swiper-thumbnails');
          const swiperEl: SwiperContainer | null = document.querySelector('#paquete-swiper-imagenes');

          if (swiperThumbnailsEl && swiperEl) {
            clearInterval(intervalFotosId);

            // thumbnails swiper parameters
            const swiperThumbnailsParams = {
              init: true,
              scrollbar: false, // TODO: podria estar si ajustamos el css
              observeParents: true,
              observeSlideChildren: true,
              observer: true,
              slidesPerView: 'auto',
              spaceBetween: 5,
              freeMode: true,
              centerInsufficientSlides: true,
            }
            // swiper parameters
            const swiperParams = {
              init: true,
              navigation: true,
              pagination: false,
              scrollbar: false,
              slidesPerView: 1,
              // breakpoints: {
              //   768: {
              //     slidesPerView: 2,
              //   },
              //   992: {
              //     slidesPerView: 4,
              //   },
              // },
              observeParents: true,
              observeSlideChildren: true,
              observer: true,
              // on: {
              //   init() {
              //     // ...
              //   },
              // },
              autoHeight: true,
              thumbs: {
                swiper: swiperThumbnailsEl,
              },
            };

            // now we need to assign all parameters to Swiper element
            Object.assign(swiperThumbnailsEl, swiperThumbnailsParams);
            Object.assign(swiperEl, swiperParams);

            // and now initialize it
            swiperThumbnailsEl.initialize();
            swiperEl.initialize();
          } else {
            this.data.log('Paquete Swiper fotos/thumbnails not found');
          }
        } catch (error) {
          this.data.log('Paquete Swiper initialization error:', error);
        }

        fotosIntervalAttemptsCount++;
      }, 200);

      let relacionadosIntervalAttemptsCount = 0;
      const intervalRelacionadosId = setInterval(() => {
        if (fotosIntervalAttemptsCount > 9) {
          clearInterval(relacionadosIntervalAttemptsCount);
        }

        // Inicializo Swiper Paquete relacionados
        try {
          // swiper element relacionados
          const swiperRelacionadosEl: SwiperContainer | null = document.querySelector('#paquete-relacionados-swiper-productos-sina');

          if (swiperRelacionadosEl) {
            clearInterval(intervalRelacionadosId);

            // swiper parameters
            const swiperRelacionadosParams = {
              init: true,
              navigation: true,
              pagination: false,
              scrollbar: false,
              loop: true,
              slidesPerView: 1,
              breakpoints: {
                768: {
                  slidesPerView: 2,
                },
                992: {
                  slidesPerView: 4,
                },
              },
              observeParents: true,
              observeSlideChildren: true,
              observer: true,
              // on: {
              //   init() {
              //     // ...
              //   },
              // },
            };

            // now we need to assign all parameters to Swiper element
            Object.assign(swiperRelacionadosEl, swiperRelacionadosParams);

            // and now initialize it
            swiperRelacionadosEl.initialize();
          } else {
            this.data.log('Paquete relacionados Swiper not found');
          }
        } catch (error) {
          this.data.log('Paquete relacionados Swiper initialization error:', error);
        }

        relacionadosIntervalAttemptsCount++;
      }, 200);
    }
  }

  calcularDescuento(precioBase: number, precioMostrado: number): number {

    const cleanPrecioBase = Number(precioBase.toString().replace(/[^0-9.-]+/g,""));
    const cleanPrecioMostrado = Number(precioMostrado.toString().replace(/[^0-9.-]+/g,""));

    return Math.floor((cleanPrecioBase - cleanPrecioMostrado) / cleanPrecioBase * 100);
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  alertClicked() {
    this.successMessage = '';
    this.data.toggleCarritoShow();
  }

  enterCheck() {
    this.newMessage(this.paquete);
  }

  newMessage(msg: any) {
    if (this.loginStatus === true) {
      if (msg.cantidad) {
        const body = new URLSearchParams();
        body.set('productos', JSON.stringify(msg.insumos?.filter((p: any) => p.habilitado == "1" && p.enStock == "1" && p.precio > 0)?.map((p: any) => { return { id: p.id, cantidad: p.cantidad }})));

        this.auth.post('carrito/agregar_varios_item', body)
        .then(($response) => {
          if (environment.production) {
            this.googleAnalyticsService.addToCart({ sku: msg.codInterno, descripcion: msg.titulo, categoria: msg.categorias ? msg.categorias[0]?.nombre : undefined, precio: msg.precio, cantidad: msg.cantidad });
            this.facebookPixelService.addToCart({ sku: msg.codInterno, descripcion: msg.titulo, categoria: msg.categorias ? msg.categorias[0]?.nombre : undefined, precio: msg.precio, cantidad: msg.cantidad });
          }
          this.data.log('response carritoagregarvariositem paquete', $response);

          for (const p of msg.insumos) {
            if (p.habilitado == "1" && p.enStock == "1" && p.precio > 0) {
              const response = this.data.addMessage(p);
              if (response.value) {
                this._success.next(response.text);
              }
            }
          }
        })
        .catch(($error) => {
          this.data.log('error carritoagregarvariositem paquete', $error);
        });
      }
    } else {
      this.data.toggleLoginModal();
    }
  }

  public revisarCantidad(e: any) {
    if (e.target && parseInt(e.target.value) < parseInt(e.target.min)) {
      e.target.value = e.target.min;
    }
  }

}

@Pipe({
  name: 'safeHtmlPaquete',
})
export class SafeHtmlPipePaquete implements PipeTransform {
  constructor(private sanitizer: DomSanitizer){}
  transform(html: any) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
@Pipe({
  name: 'safeUrlPaquete',
})
export class SafeUrlPipePaquete implements PipeTransform {
  constructor(private sanitizer: DomSanitizer){}
  transform(value: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }
}
