<a class="paquete__link" routerLink="{{item.fullLink}}">
  <div class="resultado__item__content" [ngClass]="{'resultado__item__disabled': item.habilitado == '0'}">
    <div class="imgContainer">
      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      <div class="imagen">
        <img width="150" height="100" *ngIf="item.urlImagen" style="max-width: 100%; height: auto;" [ngClass]="'lazyload'" [attr.data-src]="item.urlImagen" onerror="this.src='/assets/images/producto/picGenerica.webp'" alt="">
        <img width="150" height="100" *ngIf="!item.urlImagen" style="max-width: 100%; height: auto;" src='/assets/images/producto/picGenerica.webp' alt="">
      </div>
    </div>
    <div class="tituloContainer">
      <p *ngIf="item.nombreMostrar" class="title" [innerHTML]='item.nombreMostrar | highlight : search_term'></p>
      <p *ngIf="!item.nombreMostrar" class="title" [innerHTML]='item.titulo | highlight : search_term'></p>
      <p class="description" [innerHTML]='item.tituloAdicional | highlight : search_term'></p>
    </div>
  </div>
</a>
<div class="cantidad" *ngIf="config.switchComprasActivo">
  <div class="cantidad--right" [class.alerta]="item.incompleto">
    <div class="precio" *ngIf="(sesion || item.oferta) && item.precio_mostrado > 0" [ngClass]="{'resultado__item__disabled': item.habilitado == '0'}">
      <p>${{item.precio_mostrado | number : '.2'}}</p>
      <span *ngIf="iva_usuario" style="margin-left: 5px">{{iva_usuario}}</span>
    </div>
    <div class="precio-oferta" *ngIf="item.oferta && item.precio_mostrado > 0 && item.precio_base > 0 && item.precio_base > item.precio">
      <p>${{item.precio_base | number : '.2'}}</p>
    </div>
    <!--div class="side">
      <p>{{item.unidadMedida}}</p>
    </div-->
  </div>
  <div class="cantidad--left" [ngClass]="{'resultado__item__disabled': item.habilitado == '0'}">
    <div class="custom-select" *ngIf="(sesion || item.oferta) && item.precio_mostrado > 0">
      <input *ngIf="item.cantPack === '1'" name="cantidad" aria-label="Cantidad" (keyup.enter)="enterCheck()" type="number" min="1" max="999999" [(ngModel)]="item.cantidad" (keyup)="revisarCantidad($event)" [disabled]="item.habilitado == '0'">
      <select *ngIf="item.cantPack !== '1'" name="cantidad" aria-label="Cantidad" [(ngModel)]="item.cantidad" [disabled]="item.habilitado == '0'">
        <option *ngFor="let cant of arrayCants" [ngValue]="cant">{{cant}}</option>
      </select>
    </div>
    <div class="resultado__item__comprar w-100" *ngIf="config.switchComprasActivo && !relacionado">
      <button type="button" class="actionButton" *ngIf="sesion && item.habilitado == '1' && item.comprado == false" (click)="newMessage(item)">
        <span class="span-comprar">COMPRAR</span>
        <span><i class="fa fa-cart-plus faButton" aria-hidden="true"></i></span>
      </button>
      <button type="button" class="actionButton" *ngIf="!sesion" (click)="newMessage(item)">
        <span class="span-ver-precio">VER PRECIO</span>
        <i class="fa fa-cart-plus faButton" aria-hidden="true"></i>
      </button>
    </div>
  </div>
</div>

<!-- Ofertas --> <!-- TODO: Capaz deberia estar siempre? -->
<div *ngIf="item.oferta" class="oferta">
  <h3 class="badge">OFERTA</h3>
  <span class="span-oferta-guion" *ngIf="item.show_descuento && item.descuento"> -</span>
  <span class="span-oferta text-danger" *ngIf="item.show_descuento && item.descuento">{{ item.descuento }}% OFF</span>
</div>
