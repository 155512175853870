import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, Pipe, PipeTransform, PLATFORM_ID, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectComponent } from '@ng-select/ng-select';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AutenticacionService } from '../autenticacion.service';
import { clienteActualizar, clientEnvioActualizarDatos, DatosTransaccion } from '../data';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { FacebookPixelService } from '../facebook-pixel.service';
import { cliente, SharedService } from '../shared.service';

@Component({
  selector: 'app-compra',
  templateUrl: './compra.component.html',
  styleUrls: ['./compra.component.scss'],
})
export class CompraComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('ngcarouselcomprauno', { static: false }) ngCarouselUno!: NgbCarousel;
  @ViewChild('ngcarouselcomprados', { static: false }) ngCarouselDos!: NgbCarousel;
  autoSlideEventCount = -1;

  environment: any;
  private destroy$: Subject<void> = new Subject<void>();
  @ViewChild('transporte') ngSelectTransporte!: NgSelectComponent;
  @ViewChild('provincia') ngSelectProvincia!: NgSelectComponent;
  @ViewChild('inputCantidad') inputCantidad!: ElementRef;
  @ViewChild('mensaje_error_retiro') mensajeErrorRetiro!: ElementRef;
  inputSub!: Subscription;
  public modalLoading: boolean = false;
  public showAgregado: boolean = false;
  idPedidoAgregado!: number;
  public showErrorAgregado: boolean = false;
  public responseErrorAgregado: string = '';
  public responseSuccessAgregado: any;
  iva_usuario: string = '';
  user!: cliente;
  representado: boolean = false;
  isDefaultRetiro: boolean = false;
  isSectorInterior: boolean = false;
  transaccion: DatosTransaccion;
  facturacion: any = {
    datos: new clienteActualizar(),
    cargar($cliente: any) {
      this.datos.codigoCliente = $cliente.codigo;
      this.datos.email = $cliente.email;
      this.datos.cuit = $cliente.cuit;
      this.datos.categoria_iva = $cliente.categoriaIva;
      this.datos.cod_categoria_iva = $cliente.codCategoriaIva;
      this.datos.razon_social          =    $cliente.razonSocial;
      this.datos.nombre_fantasia       = $cliente.nombreFantasia;
      this.datos.telefono  =    $cliente.telefono;
      this.datos.telefono_celular  =    $cliente.telefonoCelular;
      this.datos.nombre_responsable_compras = $cliente.nombreResponsableCompras;
      this.datos.facturacion_nombre_responsable = $cliente.nombreResponsableFacturacion;
      this.datos.facturacion_email = $cliente.emailFacturacion;
      this.datos.facturacion_telefono  =    $cliente.telefonoFacturacion;
      this.datos.domicilio_direccion = $cliente.domicilio.direccion;
      this.datos.domicilio_ciudad = $cliente.domicilio.ciudad;
      this.datos.domicilio_provincia = $cliente.domicilio.provincia;
      this.datos.domicilio_codigo_postal = $cliente.domicilio.codPostal;
      this.datos.dataFiscal = $cliente.dataFiscal;
    },
    update: () => {
      this.editingFacturacion = !this.editingFacturacion;
      return new Promise((resolve, reject) => {
        const body = new URLSearchParams();
        for (const dato in this.facturacion.datos) {
          if (dato) {
            if (dato.includes('razon_social')
            || dato.includes('nombre_fantasia')
            || dato.includes('nombre_responsable_compras')
            || dato.includes('facturacion_nombre_responsable')
            || dato.includes('domicilio_direccion')
            || dato.includes('domicilio_ciudad')
            || dato.includes('domicilio_provincia')) {
              body.set(dato, this.facturacion.datos[dato].toUpperCase ? this.facturacion.datos[dato].toUpperCase() : this.facturacion.datos[dato]);
            } else {
              body.set(dato, this.facturacion.datos[dato]);
            }
          }
        }
        this.auth.post('cliente/actualizar', body)
        .then(($response: any) => {
          resolve($response.body.response);
        })
        .catch(($error: any) => {
          this.data.log('updatecliente error compra:', $error);
          reject($error);
        });
      });
    },
  };
  successMessage!: string;
  public _success = new Subject<string>();
  loginStatus: boolean = true;
  public productosCarousel: any[] = [];
  alertClicked() {
    this.successMessage = '';
    this.data.toggleCarritoShow();
  }
  entrega: any = {
    transporte: {
      lista: [],
      array: [],
    },
    provincia: {
      lista: [],
      array: [],
    }
  };

  datos_envio: clientEnvioActualizarDatos = new clientEnvioActualizarDatos();

  carrito: any = {
    hora: '16 Sep 14:05',
    subtotal: 0,
    lista: [
    ],
    ventaSoloPorPedido: false,
  };
  datostransporte: any = {
    data: {
      calle: '',
      localidad: '',
      provincia: '',
      telefono: '',
      codigoTransporte: '',
      horarioEntrega: '',
      nombreTransporte: '',
    },
    form: {
      nombreTransporte: '',
      domicilioTransporte: '',
      telefonoTransporte: '',
    },
    cargar: ($calle: any, $localidad: any, $provincia: any, $telefono: any, $codigo: any)  => {
      this.datostransporte.data.calle     = $calle;
      this.datostransporte.data.localidad = $localidad;
      this.datostransporte.data.provincia = $provincia;
      this.datostransporte.data.telefono  = $telefono;
      this.datostransporte.data.codigoTransporte = $codigo;
      this.datosEnvio_flag = true;
    },
    update: () => {
      this.datosEnvio_flag = true;
      return new Promise((resolve, reject) => {
        const body = new URLSearchParams();
        const datos = this.datos_envio.enviar();
        Object.keys(datos).forEach((key) => {
          if (key.includes('domicilio_direccion')
          || key.includes('domicilio_ciudad')
          || key.includes('domicilio_provincia')) {
            body.set(key, datos[key].toUpperCase ? datos[key].toUpperCase() : datos[key]);
          } else {
            body.set(key, datos[key]);
          }
        });
        this.auth.post('cliente/envio/actualizar_datos', body)
        .then(($response: any) => {
          resolve($response.body.response);
        })
        .catch(($error: any) => {
          this.data.log('actualizardatosenvio error compra:', $error);
          reject($error);
        });
      });
    },
    add: () => {
      this.datosEnvio_flag = true;
      return new Promise((resolve, reject) => {
        const body = new URLSearchParams();
        Object.keys(this.datostransporte.form).forEach((key) => {
          body.set(key, this.datostransporte.form[key]);
        });
        this.auth.post('cliente/envio/nuevo_transporte', body)
        .then(($response: any) => {
          resolve($response.body.response);
        })
        .catch(($error: any) => {
          this.data.log('envionuevotransporte error compra:', $error);
          reject($error);
        });
      });
    },
  };
  retiro: boolean = false;
  flagHoraPasada: boolean = false;
  intDia: number = 1;
  dia: string = '';
  hoy = new Date();
  hoyFormatted = this.hoy.getFullYear() + '-' + ('0' + (this.hoy.getMonth() + 1)).slice(-2) + '-' + ('0' + this.hoy.getDate()).slice(-2);
  hoyCero = new Date(this.hoy.getFullYear(), this.hoy.getMonth(), this.hoy.getDate(), 0, 0);
  hoyMediodia = new Date(this.hoy.getFullYear(), this.hoy.getMonth(), this.hoy.getDate(), 13, 0);
  retiroHoraDate = new Date();
  retiroHora = this.hoyMediodia.getFullYear() + '-' + (this.hoyMediodia.getMonth() + 1) + '-' + this.hoyMediodia.getDate();
  retiroHora1 = new Date(this.hoyMediodia.getTime());
  inputFecha: string = this.hoyMediodia.getDate() + '/' + (this.hoyMediodia.getMonth() + 1) + '/' + this.hoyMediodia.getFullYear();
  inputHora: string = '12';
  inputMinuto: string = '00';
  dias = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
  diaRetiroInicialEncontrado: boolean = false;
  horariosDisabled: any = [];
  posiblesRetiros: any = {
    'Lunes': [
      "08", // De "08" a "09"
      "09", // De "09" a "10"
      "10", // De "10" a "11"
      "11", // De "11" a "12"
      // "12", // De "12" a "13"
      // "13", // De "13" a "14"
      "14", // De "14" a "15"
      "15", // De "15" a "16"
      "16", // De "16" a "17"
    ],
    'Martes': [
      "08", // De "08" a "09"
      "09", // De "09" a "10"
      "10", // De "10" a "11"
      "11", // De "11" a "12"
      // "12", // De "12" a "13"
      // "13", // De "13" a "14"
      "14", // De "14" a "15"
      "15", // De "15" a "16"
      "16", // De "16" a "17"
    ],
    'Miércoles': [
      "08", // De "08" a "09"
      "09", // De "09" a "10"
      "10", // De "10" a "11"
      "11", // De "11" a "12"
      // "12", // De "12" a "13"
      // "13", // De "13" a "14"
      "14", // De "14" a "15"
      "15", // De "15" a "16"
      "16", // De "16" a "17"
    ],
    'Jueves': [
      "08", // De "08" a "09"
      "09", // De "09" a "10"
      "10", // De "10" a "11"
      "11", // De "11" a "12"
      // "12", // De "12" a "13"
      // "13", // De "13" a "14"
      "14", // De "14" a "15"
      "15", // De "15" a "16"
      "16", // De "16" a "17"
    ],
    'Viernes': [
      "08", // De "08" a "09"
      "09", // De "09" a "10"
      "10", // De "10" a "11"
      "11", // De "11" a "12"
      // "12", // De "12" a "13"
      // "13", // De "13" a "14"
      "14", // De "14" a "15"
      "15", // De "15" a "16"
      "16", // De "16" a "17"
    ],
    'Sábado': [
      "08", // De "08" a "09"
      "09", // De "09" a "10"
      "10", // De "10" a "11"
      "11", // De "11" a "12"
      // "12", // De "12" a "13"
      // "13", // De "13" a "14"
      // "14", // De "14" a "15"
      // "15", // De "15" a "16"
      // "16", // De "16" a "17"
    ],
  };

  fechasFiltro = (d: Date | null): boolean => {
    const date = d || new Date();
    const day = date.getDay();
    // Prevent Sunday && disabled dates from being selected.
    return day !== 0 && this.diaHasAnyHorarioDisponible(date);
  };
  isHorarioDisabled(hora: string) {
    const diaString = this.retiroHoraDate?.toLocaleString()?.split(',')[0]?.split('/')?.map((t: string) => t.split(' ')[0])?.reverse()?.join('-');
    if (this.horariosDisabled && this.horariosDisabled.indexOf && this.horariosDisabled.indexOf(diaString + ' ' + hora + ':00:00') !== -1) {
      return true;
    }

    return false;
  }
  seleccionarPrimerDiaDisponible() {
    const initDate = new Date();

    while (!this.diaRetiroInicialEncontrado) {
      if (this.diaHasAnyHorarioDisponible(initDate)) {
        this.diaRetiroInicialEncontrado = true;
      } else {
        initDate.setDate(initDate.getDate() + 1);
      }
    }

    this.fechaUpdate({ value: initDate });
  }
  diaHasAnyHorarioDisponible(dia: Date): boolean {
    const diaString = dia?.toLocaleString()?.split(',')[0]?.split('/')?.map((t: string) => t.split(' ')[0])?.reverse()?.join('-');

    if (this.posiblesRetiros[this.dias[dia.getDay()]]?.length > 0) {
      for (const hora of this.posiblesRetiros[this.dias[dia.getDay()]]) {
        if (this.horariosDisabled && this.horariosDisabled.indexOf && this.horariosDisabled.indexOf(diaString + ' ' + hora + ':00:00') === -1) {
          return true;
        }
      }
    }

    return false;
  }
  seleccionarPrimerHorarioDisponible() {
    const diaString = this.retiroHoraDate?.toLocaleString()?.split(',')[0]?.split('/')?.map((t: string) => t.split(' ')[0])?.reverse()?.join('-');

    if (this.posiblesRetiros[this.dias[this.retiroHoraDate.getDay()]]?.length > 0) {
      for (const hora of this.posiblesRetiros[this.dias[this.retiroHoraDate.getDay()]]) {
        if (this.horariosDisabled && this.horariosDisabled.indexOf && this.horariosDisabled.indexOf(diaString + ' ' + hora + ':00:00') === -1) {
          return this.fechaUpdate1(hora);
        }
      }
    }
  }
  fechaUpdate($event: any) {
    this.flagHoraPasada = false;
    this.retiroHoraDate = $event.value;
    this.retiroHora = $event.value?.toLocaleString()?.split(',')[0]?.split('/')?.map((t: string) => t.split(' ')[0])?.reverse()?.join('-');
    const diaElegido = this.retiroHora?.split('-')[2];
    const mesElegido = this.retiroHora?.split('-')[1];
    const anoElegido = this.retiroHora?.split('-')[0];
    const dias = this.retiroHora?.split('-');
    if (dias) {
      this.dia = this.diaSemana();
      this.inputFecha = dias[2] + '/' + dias[1] + '/' + dias[0];
    }

    if (parseInt(anoElegido) < this.hoy.getFullYear()) {
      this.retiro = false;
      this.flagHoraPasada = true;
    } else if (parseInt(anoElegido) === this.hoy.getFullYear()) {
      if (parseInt(mesElegido) < (this.hoy.getMonth() + 1)) {
        this.retiro = false;
        this.flagHoraPasada = true;
      } else if (parseInt(mesElegido) === (this.hoy.getMonth() + 1)) {
        if (parseInt(diaElegido) < this.hoy.getDate()) {
          this.retiro = false;
          this.flagHoraPasada = true;
        } else if (parseInt(diaElegido) === this.hoy.getDate() && this.retiroHora1.getHours() < this.hoy.getHours()) {
          this.retiro = false;
          this.flagHoraPasada = true;
        } else if (parseInt(diaElegido) === this.hoy.getDate() && this.retiroHora1.getHours() === this.hoy.getHours()) {
          if (this.retiroHora1.getMinutes() <= this.hoy.getMinutes() + 10) {
            this.retiro = false;
            this.flagHoraPasada = true;
          } else {
            switch (this.dia) {
              case 'Domingo':
                this.retiro = false;
                break;
              case 'Sábado':
                if (this.retiroHora1.getHours() >= 8 && this.retiroHora1.getHours() < 12) {
                  this.retiro = true;
                } else if (this.retiroHora1.getHours() === 12) {
                  this.retiro = this.retiroHora1.getMinutes() === 0 ? true : false;
                } else {
                  this.retiro = false;
                }
                break;
              default:
                if (this.retiroHora1.getHours() >= 8 && this.retiroHora1.getHours() < 17) {
                  this.retiro = true;
                  if (this.retiroHora1.getHours() >= 12 && this.retiroHora1.getHours() < 14) {
                    if (this.retiroHora1.getHours() === 12) {
                      this.retiro = this.retiroHora1.getMinutes() === 0 ? true : false;
                    } else {
                      this.retiro = false;
                    }
                  } else {
                    this.retiro = true;
                  }
                } else if (this.retiroHora1.getHours() === 17) {
                  this.retiro = this.retiroHora1.getMinutes() === 0 ? true : false;
                } else {
                  this.retiro = false;
                }
                break;
            }
          }
        } else {
          switch (this.dia) {
            case 'Domingo':
              this.retiro = false;
              break;
            case 'Sábado':
              if (this.retiroHora1.getHours() >= 8 && this.retiroHora1.getHours() < 12) {
                this.retiro = true;
              } else if (this.retiroHora1.getHours() === 12) {
                this.retiro = this.retiroHora1.getMinutes() === 0 ? true : false;
              } else {
                this.retiro = false;
              }
              break;
            default:
              if (this.retiroHora1.getHours() >= 8 && this.retiroHora1.getHours() < 17) {
                this.retiro = true;
                if (this.retiroHora1.getHours() >= 12 && this.retiroHora1.getHours() < 14) {
                  if (this.retiroHora1.getHours() === 12) {
                    this.retiro = this.retiroHora1.getMinutes() === 0 ? true : false;
                  } else {
                    this.retiro = false;
                  }
                } else {
                  this.retiro = true;
                }
              } else if (this.retiroHora1.getHours() === 17) {
                this.retiro = this.retiroHora1.getMinutes() === 0 ? true : false;
              } else {
                this.retiro = false;
              }
              break;
          }
        }
      } else {
        switch (this.dia) {
          case 'Domingo':
            this.retiro = false;
            break;
          case 'Sábado':
            if (this.retiroHora1.getHours() >= 8 && this.retiroHora1.getHours() < 12) {
              this.retiro = true;
            } else if (this.retiroHora1.getHours() === 12) {
              this.retiro = this.retiroHora1.getMinutes() === 0 ? true : false;
            } else {
              this.retiro = false;
            }
            break;
          default:
            if (this.retiroHora1.getHours() >= 8 && this.retiroHora1.getHours() < 17) {
              this.retiro = true;
              if (this.retiroHora1.getHours() >= 12 && this.retiroHora1.getHours() < 14) {
                if (this.retiroHora1.getHours() === 12) {
                  this.retiro = this.retiroHora1.getMinutes() === 0 ? true : false;
                } else {
                  this.retiro = false;
                }
              } else {
                this.retiro = true;
              }
            } else if (this.retiroHora1.getHours() === 17) {
              this.retiro = this.retiroHora1.getMinutes() === 0 ? true : false;
            } else {
              this.retiro = false;
            }
            break;
        }
      }
    } else {
      switch (this.dia) {
        case 'Domingo':
          this.retiro = false;
          break;
        case 'Sábado':
          if (this.retiroHora1.getHours() >= 8 && this.retiroHora1.getHours() < 12) {
            this.retiro = true;
          } else if (this.retiroHora1.getHours() === 12) {
            this.retiro = this.retiroHora1.getMinutes() === 0 ? true : false;
          } else {
            this.retiro = false;
          }
          break;
        default:
          if (this.retiroHora1.getHours() >= 8 && this.retiroHora1.getHours() < 17) {
            this.retiro = true;
            if (this.retiroHora1.getHours() >= 12 && this.retiroHora1.getHours() < 14) {
              if (this.retiroHora1.getHours() === 12) {
                this.retiro = this.retiroHora1.getMinutes() === 0 ? true : false;
              } else {
                this.retiro = false;
              }
            } else {
              this.retiro = true;
            }
          } else if (this.retiroHora1.getHours() === 17) {
            this.retiro = this.retiroHora1.getMinutes() === 0 ? true : false;
          } else {
            this.retiro = false;
          }
          break;
      }
    }

    this.seleccionarPrimerHorarioDisponible();
  }
  fechaUpdate1($event: any) {
    this.flagHoraPasada = false;
    const diaElegido = this.retiroHora?.split('-')[2];
    const mesElegido = this.retiroHora?.split('-')[1];
    const anoElegido = this.retiroHora?.split('-')[0];
    const hora = $event;
    let horaInt = parseInt(hora, 10);
    if (!isNaN(hora) && !isNaN(horaInt) && horaInt >= 0 && horaInt < 24) {
      this.inputHora = hora;
      this.retiroHora1?.setHours(horaInt);
    } else {
      horaInt = parseInt(this.inputHora, 10);
    }

    if (parseInt(anoElegido) < this.hoy.getFullYear()) {
      this.retiro = false;
      this.flagHoraPasada = true;
    } else if (parseInt(anoElegido) === this.hoy.getFullYear()) {
      if (parseInt(mesElegido) < (this.hoy.getMonth() + 1)) {
        this.retiro = false;
        this.flagHoraPasada = true;
      } else if (parseInt(mesElegido) === (this.hoy.getMonth() + 1)) {
        if (parseInt(diaElegido) < this.hoy.getDate()) {
          this.retiro = false;
          this.flagHoraPasada = true;
        } else if (parseInt(diaElegido) === this.hoy.getDate() && horaInt < this.hoy.getHours()) {
          this.retiro = false;
          this.flagHoraPasada = true;
        } else if (parseInt(diaElegido) === this.hoy.getDate() && horaInt === this.hoy.getHours()) {
          if (this.retiroHora1.getMinutes() <= this.hoy.getMinutes() + 10) {
            this.retiro = false;
            this.flagHoraPasada = true;
          } else {
            switch (this.dia) {
              case 'Domingo':
                this.retiro = false;
                break;
              case 'Sábado':
                if (horaInt >= 8 && horaInt < 12) {
                  this.retiro = true;
                } else if (horaInt === 12) {
                  this.retiro = this.retiroHora1.getMinutes() === 0 ? true : false;
                } else {
                  this.retiro = false;
                }
                break;
              default:
                if (horaInt >= 8 && horaInt < 17) {
                  this.retiro = true;
                  if (horaInt >= 12 && horaInt < 14) {
                    if (horaInt === 12) {
                      this.retiro = this.retiroHora1.getMinutes() === 0 ? true : false;
                    } else {
                      this.retiro = false;
                    }
                  } else {
                    this.retiro = true;
                  }
                } else if (horaInt === 17) {
                  this.retiro = this.retiroHora1.getMinutes() === 0 ? true : false;
                } else {
                  this.retiro = false;
                }
                break;
            }
          }
        } else {
          switch (this.dia) {
            case 'Domingo':
              this.retiro = false;
              break;
            case 'Sábado':
              if (horaInt >= 8 && horaInt < 12) {
                this.retiro = true;
              } else if (horaInt === 12) {
                this.retiro = this.retiroHora1.getMinutes() === 0 ? true : false;
              } else {
                this.retiro = false;
              }
              break;
            default:
              if (horaInt >= 8 && horaInt < 17) {
                this.retiro = true;
                if (horaInt >= 12 && horaInt < 14) {
                  if (horaInt === 12) {
                    this.retiro = this.retiroHora1.getMinutes() === 0 ? true : false;
                  } else {
                    this.retiro = false;
                  }
                } else {
                  this.retiro = true;
                }
              } else if (horaInt === 17) {
                this.retiro = this.retiroHora1.getMinutes() === 0 ? true : false;
              } else {
                this.retiro = false;
              }
              break;
          }
        }
      } else {
        switch (this.dia) {
          case 'Domingo':
            this.retiro = false;
            break;
          case 'Sábado':
            if (horaInt >= 8 && horaInt < 12) {
              this.retiro = true;
            } else if (horaInt === 12) {
              this.retiro = this.retiroHora1.getMinutes() === 0 ? true : false;
            } else {
              this.retiro = false;
            }
            break;
          default:
            if (horaInt >= 8 && horaInt < 17) {
              this.retiro = true;
              if (horaInt >= 12 && horaInt < 14) {
                if (horaInt === 12) {
                  this.retiro = this.retiroHora1.getMinutes() === 0 ? true : false;
                } else {
                  this.retiro = false;
                }
              } else {
                this.retiro = true;
              }
            } else if (horaInt === 17) {
              this.retiro = this.retiroHora1.getMinutes() === 0 ? true : false;
            } else {
              this.retiro = false;
            }
            break;
        }
      }
    } else {
      switch (this.dia) {
        case 'Domingo':
          this.retiro = false;
          break;
        case 'Sábado':
          if (horaInt >= 8 && horaInt < 12) {
            this.retiro = true;
          } else if (horaInt === 12) {
            this.retiro = this.retiroHora1.getMinutes() === 0 ? true : false;
          } else {
            this.retiro = false;
          }
          break;
        default:
          if (horaInt >= 8 && horaInt < 17) {
            this.retiro = true;
            if (horaInt >= 12 && horaInt < 14) {
              if (horaInt === 12) {
                this.retiro = this.retiroHora1.getMinutes() === 0 ? true : false;
              } else {
                this.retiro = false;
              }
            } else {
              this.retiro = true;
            }
          } else if (horaInt === 17) {
            this.retiro = this.retiroHora1.getMinutes() === 0 ? true : false;
          } else {
            this.retiro = false;
          }
          break;
      }
    }
  }
  /* fechaUpdate2($event: any) {
    const diaElegido = this.retiroHora.split('-')[2];
    const mesElegido = this.retiroHora.split('-')[1];
    const anoElegido = this.retiroHora.split('-')[0];
    this.flagHoraPasada = false;
    const minuto = $event;
    this.inputMinuto = minuto;
    this.retiroHora1.setMinutes(parseInt(minuto));

    if (parseInt(anoElegido) < this.hoy.getFullYear()) {
      this.retiro = false;
      this.flagHoraPasada = true;
    } else if (parseInt(anoElegido) === this.hoy.getFullYear()) {
      if (parseInt(mesElegido) < (this.hoy.getMonth() + 1)) {
        this.retiro = false;
        this.flagHoraPasada = true;
      } else if (parseInt(mesElegido) === (this.hoy.getMonth() + 1)) {
        if (parseInt(diaElegido) < this.hoy.getDate()) {
          this.retiro = false;
          this.flagHoraPasada = true;
        } else if (parseInt(diaElegido) === this.hoy.getDate() && this.retiroHora1.getHours() < this.hoy.getHours()) {
          this.retiro = false;
          this.flagHoraPasada = true;
        } else if (parseInt(diaElegido) === this.hoy.getDate() && this.retiroHora1.getHours() === this.hoy.getHours()) {
          if (parseInt(minuto) <= this.hoy.getMinutes() + 10) {
            this.retiro = false;
            this.flagHoraPasada = true;
          } else {
            switch (this.dia) {
              case 'Domingo':
                this.retiro = false;
                break;
              case 'Sábado':
                if (this.retiroHora1.getHours() >= 8 && this.retiroHora1.getHours() < 12) {
                  this.retiro = true;
                } else if (this.retiroHora1.getHours() === 12) {
                  this.retiro = parseInt(minuto) === 0 ? true : false;
                } else {
                  this.retiro = false;
                }
                break;
              default:
                if (this.retiroHora1.getHours() >= 8 && this.retiroHora1.getHours() < 17) {
                  this.retiro = true;
                  if (this.retiroHora1.getHours() >= 12 && this.retiroHora1.getHours() < 14) {
                    if (this.retiroHora1.getHours() === 12) {
                      this.retiro = parseInt(minuto) === 0 ? true : false;
                    } else {
                      this.retiro = false;
                    }
                  } else {
                    this.retiro = true;
                  }
                } else if (this.retiroHora1.getHours() === 17) {
                  this.retiro = parseInt(minuto) === 0 ? true : false;
                } else {
                  this.retiro = false;
                }
                break;
            }
          }
        } else {
          switch (this.dia) {
            case 'Domingo':
              this.retiro = false;
              break;
            case 'Sábado':
              if (this.retiroHora1.getHours() >= 8 && this.retiroHora1.getHours() < 12) {
                this.retiro = true;
              } else if (this.retiroHora1.getHours() === 12) {
                this.retiro = parseInt(minuto) === 0 ? true : false;
              } else {
                this.retiro = false;
              }
              break;
            default:
              if (this.retiroHora1.getHours() >= 8 && this.retiroHora1.getHours() < 17) {
                this.retiro = true;
                if (this.retiroHora1.getHours() >= 12 && this.retiroHora1.getHours() < 14) {
                  if (this.retiroHora1.getHours() === 12) {
                    this.retiro = parseInt(minuto) === 0 ? true : false;
                  } else {
                    this.retiro = false;
                  }
                } else {
                  this.retiro = true;
                }
              } else if (this.retiroHora1.getHours() === 17) {
                this.retiro = parseInt(minuto) === 0 ? true : false;
              } else {
                this.retiro = false;
              }
              break;
          }
        }
      } else {
        switch (this.dia) {
          case 'Domingo':
            this.retiro = false;
            break;
          case 'Sábado':
            if (this.retiroHora1.getHours() >= 8 && this.retiroHora1.getHours() < 12) {
              this.retiro = true;
            } else if (this.retiroHora1.getHours() === 12) {
              this.retiro = parseInt(minuto) === 0 ? true : false;
            } else {
              this.retiro = false;
            }
            break;
          default:
            if (this.retiroHora1.getHours() >= 8 && this.retiroHora1.getHours() < 17) {
              this.retiro = true;
              if (this.retiroHora1.getHours() >= 12 && this.retiroHora1.getHours() < 14) {
                if (this.retiroHora1.getHours() === 12) {
                  this.retiro = parseInt(minuto) === 0 ? true : false;
                } else {
                  this.retiro = false;
                }
              } else {
                this.retiro = true;
              }
            } else if (this.retiroHora1.getHours() === 17) {
              this.retiro = parseInt(minuto) === 0 ? true : false;
            } else {
              this.retiro = false;
            }
            break;
        }
      }
    } else {
      switch (this.dia) {
        case 'Domingo':
          this.retiro = false;
          break;
        case 'Sábado':
          if (this.retiroHora1.getHours() >= 8 && this.retiroHora1.getHours() < 12) {
            this.retiro = true;
          } else if (this.retiroHora1.getHours() === 12) {
            this.retiro = parseInt(minuto) === 0 ? true : false;
          } else {
            this.retiro = false;
          }
          break;
        default:
          if (this.retiroHora1.getHours() >= 8 && this.retiroHora1.getHours() < 17) {
            this.retiro = true;
            if (this.retiroHora1.getHours() >= 12 && this.retiroHora1.getHours() < 14) {
              if (this.retiroHora1.getHours() === 12) {
                this.retiro = parseInt(minuto) === 0 ? true : false;
              } else {
                this.retiro = false;
              }
            } else {
              this.retiro = true;
            }
          } else if (this.retiroHora1.getHours() === 17) {
            this.retiro = parseInt(minuto) === 0 ? true : false;
          } else {
            this.retiro = false;
          }
          break;
      }
    }
  } */

  // Formato dia
  diaSemana(): string {
    this.intDia = this.retiroHoraDate.getDay();
    return this.dias[this.intDia];
  }
  /////////////
  mapa = false;
  datosCompra = {
    entrega: '0',
    medio: {
      nombre: '',
      telefono: '',
      direccion: '',
    },
    envio : {
      calle: '',
      localidad: '',
      provincia: '',
      telefono: '',
    },
    agregado: '0',
  };
  editingEnvio: boolean = false;
  editingTransporte: boolean = false;
  editingFacturacion: boolean = false;

  subtotal: number = 0;
  total: number = 0;
  facturacion__toogle = false;
  facturacion__envio = false;

  secciones = [
    'Mi compra',
    'Datos de envío',
    'Confirmación',
  ];
  initialLista: any[] = [];
  config: any;
  constructor(
    private data: SharedService,
    // private http: HttpClient,
    private auth: AutenticacionService,
    private router: Router,
    private location: Location,
    private googleAnalyticsService: GoogleAnalyticsService,
    private facebookPixelService: FacebookPixelService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.environment = environment;
    this.transaccion = new DatosTransaccion(0);
    this.dia = this.diaSemana();
    if (this.data.user) {
      this.user = this.data.user as cliente;
      this.auth.get('public/cliente/envio/getAll').then((result: any) => {
        if (result.responseT) {
          result.responseT.forEach((transporte: any) => {
            this.entrega.transporte.lista.push({
              id: transporte.codigo,
              text: transporte.nombre,
            });
            this.entrega.transporte.array[transporte.codigo] = transporte.nombre;
          });
          this.initialLista.push(this.entrega.transporte.lista.find((transporte: any) => {
            return transporte.id === this.user.datosEnvio.codigoTransporte;
          }));
        }
        if (result.responseP) {
          result.responseP.forEach((provincia: any) => {
            this.entrega.provincia.lista.push({
              id: provincia.codigo,
              text: provincia.nombre,
            });
            this.entrega.provincia.array[provincia.codigo] = provincia.nombre;
          });
        }
      }).catch((error: any) => this.data.log('public/cliente/envio/getAll error compra:', error));

      if (this.user.datosEnvio) {
        this.datos_envio.cargar(this.user.datosEnvio);
        this.datosEnvio_flag = true;
        if (this.user.datosEnvio.domicilioEntrega) {
          this.datostransporte.cargar(
            this.user.datosEnvio.domicilioEntrega.direccion,
            this.user.datosEnvio.domicilioEntrega.ciudad,
            this.user.datosEnvio.domicilioEntrega.provincia,
            this.user.datosEnvio.telefono,
            this.user.datosEnvio.codigoTransporte,
          );
        }else {
          this.datostransporte.cargar(
          '',
          '',
          '',
          this.user.datosEnvio.telefono,
          this.user.datosEnvio.codigoTransporte,
        );
        }
      }
      this.datosCompra = {
        entrega: '0',
        medio: {
          nombre: '',
          telefono: '',
          direccion: '',
        },
          envio:   {
          calle:      this.user.datosEnvio.domicilioEntrega ? this.user.datosEnvio.domicilioEntrega.direccion : '',
          localidad:  this.user.datosEnvio.domicilioEntrega ? this.user.datosEnvio.domicilioEntrega.ciudad : '',
          provincia:  this.user.datosEnvio.domicilioEntrega ? this.user.datosEnvio.domicilioEntrega.provincia : '',
          telefono:   this.user.telefonoFacturacion ? this.user.telefonoFacturacion : '',
        },
        agregado: '0',
      };
      this.facturacion.cargar(this.user);
    }

    // Client only code.
    if (isPlatformBrowser(this.platformId)) {
      setInterval(() => {
        this.hoy = new Date();
      }, 300000);
    }
  }

  cargarTransporte: boolean = false;
  datosEnvio_flag:       boolean = false;

  public refreshTransporte(value: any): void {
    this.datos_envio.cod_transporte = value;
    this.cargarTransporte = false;
  }
  public refreshProvincia(value: any): void {
    this.datos_envio.domicilio_provincia = value;
  }

  observaciones = '';
  pedido: boolean = true;
  completarCompraTexto = '';
  completarCompraTextoExtra = '';
  pedidoCorrecto = true;
  completarCompraLink = '';
  cupon: any = {
    codigo: ''
  };
  cuponLoading: boolean = false;
  cuponValido: boolean = false;
  showDescuentoCupon: boolean = false;
  descuentoCupon: number = 0;
  cuponSuccessMsg = '';
  cuponErrorMsg = '';

  async enterCupon(event: any) {
    if (event.keyCode == 13) {
      await this.submitCupon();
    }
  }
  public async submitCupon() {
    // this.data.log(`enviando cupon ${this.cupon.codigo}`)
    const data = new URLSearchParams();
    if (!this.cuponLoading) {
      if (this.cupon.codigo && this.cupon.codigo.toUpperCase) {
        this.cuponValido = false;
        this.cuponSuccessMsg = '';
        this.cuponErrorMsg = '';
        this.cuponLoading = true;
        this.cupon.codigo = this.cupon.codigo.toUpperCase();
        data.set("codigo_cupon", this.cupon.codigo);
        try {
          const validarCuponResponse = await this.auth.post('carrito/validar_cupon', data)
          if (validarCuponResponse && validarCuponResponse.body && validarCuponResponse.body.status) {
            this.cuponValido = true;
            this.cuponSuccessMsg = validarCuponResponse.body?.response?.msg;
            this.cuponErrorMsg = '';
            this.cupon = validarCuponResponse.body?.response?.cupon;
            setTimeout(() => {
              this.cuponLoading = false;
            }, 1000);
            this.setDescuentosCupon();
            this.data.log('validar cupon response compra', validarCuponResponse.body?.response || validarCuponResponse);

            return true;
          } else {
            this.cuponValido = false;
            this.cuponSuccessMsg = '';
            this.cuponErrorMsg = validarCuponResponse.body?.response;
            this.cupon = { codigo: '' };
            setTimeout(() => {
              this.cuponLoading = false;
            }, 1000);
            this.data.log('validar cupon error compra', validarCuponResponse.body?.response || validarCuponResponse);

            return false;
          }
        } catch (error: any) {
          this.cuponValido = false;
          this.cuponSuccessMsg = '';
          this.cuponErrorMsg = error.error?.response;
          this.cupon = { codigo: '' };
          setTimeout(() => {
            this.cuponLoading = false;
          }, 1000);
          this.data.log('validar cupon error exception compra', error.error?.response || error);

          return false;
        }
      } else {
        this.cuponValido = false;
        this.cuponSuccessMsg = '';
        this.cuponErrorMsg = '';
        this.cupon = { codigo: '' };
        setTimeout(() => {
          this.cuponLoading = false;
        }, 1000);

        return false;
      }
    }

    return false;
  }

  public setDescuentosCupon() {
    if (this.cupon && this.cuponValido) {
      switch (this.cupon.tipo) {
        case "Monto nominal":
          if (this.cupon.descuento_money) {
            this.descuentoCupon = this.cupon.descuento_money;
            this.showDescuentoCupon = true;
          }
          break;
        case "Porcentaje sobre la compra":
          if (this.cupon.descuento_percent) {
            this.descuentoCupon = this.carrito.subtotal * (this.cupon.descuento_percent / 100);
            this.showDescuentoCupon = true;
          }
          break;
        case "Porcentaje sobre producto/s":
          this.descuentoCupon = 0;
          if (this.cupon.descuentos_percent) {
            for (const p of this.carrito.lista) {
              if (p.id && this.cupon.descuentos_percent[p.id] && this.cupon.descuentos_percent[p.id] > 0) {
                // this.data.log('Descuento de %' + this.cupon.descuentos_percent[p.id] + ' para el Producto ID ' + p.id);
                const subtotal = p.precio * p.cantidad;
                p.descuento = subtotal * (this.cupon.descuentos_percent[p.id] / 100);
                this.descuentoCupon += p.descuento;
              }
            }
            this.showDescuentoCupon = true;
          }
          break;
        default:
          this.showDescuentoCupon = false;
          this.descuentoCupon = 0;
          break;
      }
    }
  }

  public async completarCompra() {
    let cuponStatus = false;
    if (this.cupon && this.cupon.codigo) {
      cuponStatus = await this.submitCupon();
    } else {
      cuponStatus = true;
    }
    // Si existe un cupon seteado y se valida de forma correcta avanzo con la confirmacion de la compra
    if (cuponStatus) {
      if (this.datosCompra.entrega === '0') {
        this.retiro = true;
      }
      if (!this.carritoLoading && this.retiro) {
        this.carritoLoading = true;
        this.completarCompraTexto = 'Procesando pedido...';
        const body = new URLSearchParams();
        if (this.cupon && this.cupon.id && this.cupon.codigo && this.cuponValido) {
          body.set('id_cupon', this.cupon.id);
          body.set('codigo_cupon', this.cupon.codigo);
          body.set('descuentos', this.formatMoney(this.descuentoCupon));
        }
        if (this.datosCompra.agregado === '1' && this.idPedidoAgregado && this.responseSuccessAgregado) {
          this.observaciones = this.observaciones + '\r\n' + ' - ' + '\r\n' + 'Agregado al pedido ID #' + this.idPedidoAgregado +
                              ' realizado el dia ' + this.responseSuccessAgregado.fechaPedido;
          body.set('es_agregado', this.datosCompra.agregado);
          body.set('ref_pedido', this.idPedidoAgregado.toString());
        }
        body.set('observaciones', this.observaciones);
        body.set('total', this.pedido ? this.updateTotal(this.carrito.subtotal) : '100.01');
        if (this.datosCompra.entrega === '1' && this.datosCompra.agregado !== '1') {
          body.set('retiro_tienda', this.datosCompra.entrega);
          const retiroDate = new Date(this.retiroHora.replaceAll('-', '/'));
          const diaRetiro = retiroDate.getUTCDate();
          const mesRetiro = retiroDate.getMonth() + 1;
          const anioRetiro = retiroDate.getFullYear();
          const fechaRetiro = diaRetiro + '/' + mesRetiro + '/' + anioRetiro + ' (' + this.dia + ')';
          const hora = this.retiroHora1.getHours();
          const minutos = this.retiroHora1.getMinutes();
          const horaRetiro = ('0' + hora).slice(-2) + ':' + ('0' + minutos).slice(-2);
          body.set('fecha_retiro', retiroDate.toISOString().slice(0,11).concat(horaRetiro));
          body.set('dia_retiro', fechaRetiro);
          body.set('hora_retiro', horaRetiro);
        }
        this.auth.post('pedido/confirmar', body)
        .then(($confirmado) => {
          let idPedido = false;
          const productos = this.carrito.lista;
          let respuesta;

          if ($confirmado.body) {
            respuesta = $confirmado.body.response;
          }

          if (respuesta) {
            idPedido = respuesta.id_pedido;
            this.total = respuesta.total;
            if (environment.production) {
              this.googleAnalyticsService.purchase(respuesta.id_pedido, respuesta.total, productos);
              this.facebookPixelService.purchase(respuesta.id_pedido, respuesta.total, productos);
            }
          }

          this.completarCompraTexto = $confirmado.body.response.message;
          this.completarCompraTextoExtra = $confirmado.body.response.extra_message;
          this.completarCompraLink = $confirmado.body.response.urlPdf;

          if (environment.production && environment.APP_MAILING_SUBSCRIBE) {
            // Lo suscribo el newsletter
            const body = new URLSearchParams();
            body.set('api_key', 'RCUuYdks5u0kwkTgCVlw ');
            body.set('name', this.user.razonSocial);
            body.set('email', this.user.email);
            body.set('list', 'cqW4SwUCeaOE36rhy8922C3A');
            body.set('country', 'AR');
            body.set('referrer', environment.APP_WEB_BASE);
            body.set('boolean', 'true');
            body.set('logueado', 'SI');

            this.auth.get('sendy/cliente/getDatosNewsletter')
            .then((result) => {
              this.data.log('response getDatosNewsletter compra', result);

              body.set('lista_precios', result.response.lista_precios);
              body.set('tipo_lista', result.response.tipo_lista);
              body.set('perfil', result.response.perfil);

              this.auth.post(environment.APP_MAILING_BASE + 'subscribe', body)
              .then(($response) => {
                this.data.log('response suscribir compra', $response);
              })
              .catch(($error) => {
                this.data.log('error suscribir compra', $error);
              });
            })
            .catch((error) => {
              this.data.log('error getDatosNewsletter compra', error);
            });
          }

          // Continuo el flujo normal de la compra
          setTimeout(() => {
            this.data.rutaActual = '/compra/finalizada';
            this.location.go('/compra/finalizada');
            this.transaccion.cambio(2);
            if (idPedido) {
              this.data.cleanCarrito();
            }
            this.carritoLoading = false;
          }, 1500);
        })
        .catch(($error) => {
          this.carritoLoading = false;
          try {
            this.pedidoCorrecto = false;
            this.completarCompraTexto = $error.error.response.message;
          }catch (error) {
            this.data.log('completarcompra error compra:', error, $error, '$error.error.response.message');
          }
          this.data.log('confirmarpedido error compra:', $error);
        });
      } else if (!this.retiro) {
        this.mensajeErrorRetiro?.nativeElement.focus();
      }
    } else {
      this.data.log('completarcompra error cupon, status:', cuponStatus, this.cupon, this.cuponErrorMsg, this.cuponSuccessMsg);
    }
  }

  ngOnInit() {
    this._success.pipe(takeUntil(this.destroy$)).subscribe((message) => this.successMessage = message);
    this._success.pipe(takeUntil(this.destroy$), debounceTime(5000)).subscribe(() => this.successMessage = '');
    this.data.updatePageTitle();
    // subscribing to config change
    this.data.currentConfig.pipe(takeUntil(this.destroy$)).subscribe(
      (configuracion) => {
        this.config = configuracion;
      },
    );
    this.data.currentMessage.pipe(takeUntil(this.destroy$)).subscribe(() => this.checkCarritoInit());

    // Obtengo los días/horarios NO disponibles para retiros
    this.auth.get('pedido/retiro/inhabilitados')
    .then(($response)  => {
      if ($response.status) {
        if ($response.response) this.horariosDisabled = $response.response;

        // Inhablito horarios de hoy que ya pasaron
        const ahora = new Date();
        const maniana = new Date();
        maniana.setDate(maniana.getDate() + 1);
        const ahoraString = ahora?.toLocaleString()?.split(',')[0]?.split('/')?.map((t: string) => t.split(' ')[0])?.reverse()?.join('-');
        const manianaString = maniana?.toLocaleString()?.split(',')[0]?.split('/')?.map((t: string) => t.split(' ')[0])?.reverse()?.join('-');
        if (ahora.getHours() < 8) {
          this.horariosDisabled.push(ahoraString + ' 08:00:00');
        }
        if (ahora.getHours() >= 8) {
          this.horariosDisabled.push(ahoraString + ' 08:00:00');
          this.horariosDisabled.push(ahoraString + ' 09:00:00');
        }
        if (ahora.getHours() >= 9) {
          this.horariosDisabled.push(ahoraString + ' 09:00:00');
          this.horariosDisabled.push(ahoraString + ' 10:00:00');
        }
        if (ahora.getHours() >= 10) {
          this.horariosDisabled.push(ahoraString + ' 10:00:00');
          this.horariosDisabled.push(ahoraString + ' 11:00:00');
        }
        if (ahora.getHours() >= 11) {
          this.horariosDisabled.push(ahoraString + ' 11:00:00');
          this.horariosDisabled.push(ahoraString + ' 12:00:00');
        }
        if (ahora.getHours() >= 12) {
          this.horariosDisabled.push(ahoraString + ' 12:00:00');
          this.horariosDisabled.push(ahoraString + ' 13:00:00');
        }
        if (ahora.getHours() >= 13) {
          this.horariosDisabled.push(ahoraString + ' 13:00:00');
          this.horariosDisabled.push(ahoraString + ' 14:00:00');
        }
        if (ahora.getHours() >= 14) {
          this.horariosDisabled.push(ahoraString + ' 14:00:00');
          this.horariosDisabled.push(ahoraString + ' 15:00:00');
        }
        if (ahora.getHours() >= 15) {
          this.horariosDisabled.push(ahoraString + ' 15:00:00');
          this.horariosDisabled.push(ahoraString + ' 16:00:00');
        }
        if (ahora.getHours() >= 16) {
          this.horariosDisabled.push(ahoraString + ' 16:00:00');
          this.horariosDisabled.push(ahoraString + ' 17:00:00');
        }
        if (ahora.getHours() >= 17) {
          this.horariosDisabled.push(manianaString + ' 08:00:00');
        }

        this.seleccionarPrimerDiaDisponible();
      }
    })
    .catch(($error) => {
      this.data.log('getdiashorariosinhabilitadosretiro error compra:', $error);
    });

    // Obtengo los articulos del carousel
    this.auth.get('producto/listado/carouselcompra')
    .then(($response)  => {
      if ($response.status) {
        this.productosCarousel = JSON.parse(JSON.stringify($response.response));
      }
    })
    .catch(($error) => {
      this.data.log('getresultadoscarouselcompra error compra:', $error);
    });

    this.data.currentUser.pipe(takeUntil(this.destroy$)).subscribe(($user: any) => {
      this.representado = typeof localStorage !== typeof undefined ? (JSON.parse(localStorage.getItem('login') || '')?.administrativo) : '';
      if ($user.sector) {
        if ($user.sector.codVendedor == "90") {
          this.isDefaultRetiro = true;
          if (this.datosCompra.agregado !== '1') {
            this.datosCompra.entrega = '1';
          }
        }
        switch ($user.sector.codVendedor) {
          case "10":
            this.isSectorInterior = true;
            break;
          case "25":
            this.isSectorInterior = true;
            break;
          case "60":
            this.isSectorInterior = true;
            break;
          case "75":
            this.isSectorInterior = true;
            break;
          case "81":
            this.isSectorInterior = true;
            break;

          default:
            this.isSectorInterior = false;
            break;
        }
      }
      if ($user && $user['c'] === '1') {
        this.iva_usuario = 'LOS PRECIOS SON UNITARIOS Y ESTÁN SUJETOS A SU CONDICIÓN HABITUAL';
      } else {
        if ($user) {
          switch ($user['codCategoriaIva']) {
            case 'CF': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS INCLUYEN IVA'; break;
            case 'INR': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS INCLUYEN IVA'; break;
            case 'RS': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS INCLUYEN IVA'; break;
            case 'RSS': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS INCLUYEN IVA'; break;
            case 'RI': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS NO INCLUYEN IVA'; break;
            case 'EX': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS INCLUYEN IVA'; break;
            case 'PCE': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS INCLUYEN IVA'; break;
            case 'PCS': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS INCLUYEN IVA'; break;
            case 'EXE': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS SON FINALES'; break;
            case 'SNC': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS INCLUYEN IVA'; break;
            default: this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS NO INCLUYEN IVA';
          }
        }
      }
    });

    this.checkCarritoInit(0);
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
  // debugInfo: string = '';
  async ngAfterViewInit() {
    // Client only code.
    /* if (isPlatformBrowser(this.platformId)) {
      setInterval(() => {
        const retiroDate = new Date(this.retiroHora.replaceAll('-', '/'));
        const diaRetiro = retiroDate.getUTCDate();
        const mesRetiro = retiroDate.getMonth() + 1;
        const anioRetiro = retiroDate.getFullYear();
        const fechaRetiro = diaRetiro + '/' + mesRetiro + '/' + anioRetiro + ' (' + this.dia + ')';
        const hora = this.retiroHora1.getHours();
        const minutos = this.retiroHora1.getMinutes();
        const horaRetiro = ('0' + hora).slice(-2) + ':' + ('0' + minutos).slice(-2);
        let fecha_retiro = '';
        try {
          fecha_retiro = retiroDate.toISOString().slice(0,11).concat(horaRetiro);
        } catch (error: any) {
          fecha_retiro = 'Error:' + error.name + ' - ' + error.message;
        }

        const info = {
          // this
          "this.hoyMediodia": this.hoyMediodia,
          "this.hoyMediodia.getDate()": this.hoyMediodia.getDate(),
          "this.hoyMediodia.toLocaleString('default', { month: 'long' })": this.hoyMediodia.toLocaleString('default', { month: 'long' }),
          "this.hoyMediodia.getFullYear()": this.hoyMediodia.getFullYear(),
          "this.hoyFormatted": this.hoyFormatted,
          "this.hoyCero": this.hoyCero,
          "this.intDia": this.intDia,
          "this.dia": this.dia,
          "this.dias[this.intDia]": this.dias[this.intDia],
          "this.inputFecha": this.inputFecha,
          "+this.inputHora": +this.inputHora,
          "+this.inputHora+1": +this.inputHora+1,
          "this.retiroHoraDate": this.retiroHoraDate,
          "this.retiroHora": this.retiroHora,
          "this.retiroHora1.getHours()": this.retiroHora1.getHours(),
          "this.retiroHora1.getMinutes()": this.retiroHora1.getMinutes(),
          // form body
          "retiroDate": retiroDate,
          "diaRetiro": diaRetiro,
          "mesRetiro": mesRetiro,
          "anioRetiro": anioRetiro,
          "hora": hora,
          "minutos": minutos,
          "dia_retiro": fechaRetiro,
          "hora_retiro": horaRetiro,
          "fecha_retiro": fecha_retiro,
        }

        this.debugInfo = JSON.stringify(info, null, 2);
      }, 1000);
    } */

    await this.auth.getCarrito();

    // reading config data
    this.auth.get('public/configuracion')
    .then(($response: any) => {
      this.data.log('response publicconfiguracion compra', $response);
      if ($response.response) {
        const ahora = new Date();
        const fechaDesde = $response.response.stickyHeaderDesde ? new Date($response.response.stickyHeaderDesde.date) : ahora;
        const fechaHasta = $response.response.stickyHeaderHasta ? new Date($response.response.stickyHeaderHasta.date) : ahora;
        const permanente = $response.response.stickyHeaderPermanente === '1' ? true : false;
        const marquee = $response.response.stickyHeaderMarquee === '1' ? true : false;
        const c = {
          montoMinimo: Number.parseInt($response.response.montoMinimo, 10),
          montoEnvio: Number.parseInt($response.response.montoEnvio, 10),
          montoEnvioGratis: Number.parseInt($response.response.montoEnvioGratis, 10),
          costoEnvio: Number.parseInt($response.response.costoEnvio, 10),
          stickyHeaderTitulo: $response.response.stickyHeaderTitulo,
          stickyHeaderCta: $response.response.stickyHeaderCta,
          stickyHeaderLink: $response.response.stickyHeaderLink,
          stickyHeaderFontColor: $response.response.stickyHeaderFontColor,
          stickyHeaderBackgroundColor: $response.response.stickyHeaderBackgroundColor,
          stickyHeaderScrolledFontColor: $response.response.stickyHeaderScrolledFontColor,
          stickyHeaderScrolledBackgroundColor: $response.response.stickyHeaderScrolledBackgroundColor,
          stickyHeaderButtonFontColor: $response.response.stickyHeaderButtonFontColor,
          stickyHeaderButtonBackgroundColor: $response.response.stickyHeaderButtonBackgroundColor,
          stickyHeaderScrolledButtonFontColor: $response.response.stickyHeaderScrolledButtonFontColor,
          stickyHeaderScrolledButtonBackgroundColor: $response.response.stickyHeaderScrolledButtonBackgroundColor,
          stickyHeaderCloseColor: $response.response.stickyHeaderCloseColor,
          stickyHeaderScrolledCloseColor: $response.response.stickyHeaderScrolledCloseColor,
          stickyHeaderDesde: fechaDesde,
          stickyHeaderHasta: fechaHasta,
          stickyHeaderActivo: permanente ? ($response.response.stickyHeaderActivo === '1' ? true : false) :
            ($response.response.stickyHeaderActivo === '1'  && ahora > fechaDesde && ahora < fechaHasta ? true : false),
          stickyHeaderPermanente: permanente,
          stickyHeaderMarquee: marquee,
          ventanaEmergenteTitulo: $response.response.ventanaEmergenteTitulo,
          ventanaEmergenteImagen: $response.response.ventanaEmergenteImagen,
          ventanaEmergenteActivo: $response.response.ventanaEmergenteActivo === '1' ? true : false,
          ventanaEmergenteTituloLogged: $response.response.ventanaEmergenteTituloLogged,
          ventanaEmergenteImagenLogged: $response.response.ventanaEmergenteImagenLogged,
          ventanaEmergenteActivoLogged: $response.response.ventanaEmergenteActivoLogged === '1' ? true : false,
          ventanaEmergenteTituloSinCompra: $response.response.ventanaEmergenteTituloSinCompra,
          ventanaEmergenteImagenSinCompra: $response.response.ventanaEmergenteImagenSinCompra,
          ventanaEmergenteActivoSinCompra: $response.response.ventanaEmergenteActivoSinCompra === '1' ? true : false,
          carouselHomeInterval: $response.response.carouselHomeInterval,
          carouselCompraInterval: $response.response.carouselCompraInterval,
          bannerUnoActivo: $response.response.bannerUnoActivo === '1' ? true : false,
          bannerUnoTitulo: $response.response.bannerUnoTitulo,
          bannerUnoLink: $response.response.bannerUnoLink,
          bannerUnoImagen: $response.response.bannerUnoImagen,
          bannerDosActivo: $response.response.bannerDosActivo === '1' ? true : false,
          bannerDosTitulo: $response.response.bannerDosTitulo,
          bannerDosLink: $response.response.bannerDosLink,
          bannerDosImagen: $response.response.bannerDosImagen,
          bannerTresActivo: $response.response.bannerTresActivo === '1' ? true : false,
          bannerTresTitulo: $response.response.bannerTresTitulo,
          bannerTresLink: $response.response.bannerTresLink,
          bannerTresImagen: $response.response.bannerTresImagen,
          bannerCuatroActivo: $response.response.bannerCuatroActivo === '1' ? true : false,
          bannerCuatroTitulo: $response.response.bannerCuatroTitulo,
          bannerCuatroLink: $response.response.bannerCuatroLink,
          bannerCuatroImagen: $response.response.bannerCuatroImagen,
          bannerCincoActivo: $response.response.bannerCincoActivo === '1' ? true : false,
          bannerCincoTitulo: $response.response.bannerCincoTitulo,
          bannerCincoLink: $response.response.bannerCincoLink,
          bannerCincoImagen: $response.response.bannerCincoImagen,
          bannerSeisActivo: $response.response.bannerSeisActivo === '1' ? true : false,
          bannerSeisTitulo: $response.response.bannerSeisTitulo,
          bannerSeisLink: $response.response.bannerSeisLink,
          bannerSeisImagen: $response.response.bannerSeisImagen,
          bannerSinaDesktopImagen: $response.response.bannerSinaDesktopImagen,
          bannerSinaDesktopTitulo: $response.response.bannerSinaDesktopTitulo,
          bannerSinaMobileImagen: $response.response.bannerSinaMobileImagen,
          bannerSinaMobileTitulo: $response.response.bannerSinaMobileTitulo,
          bannerOfertasTitulo: $response.response.bannerOfertasTitulo,
          bannerOfertasImagen: $response.response.bannerOfertasImagen,
          bannerOfertasSemanalesTitulo: $response.response.bannerOfertasSemanalesTitulo,
          bannerOfertasSemanalesImagen: $response.response.bannerOfertasSemanalesImagen,
          bannerNovedadesTitulo: $response.response.bannerNovedadesTitulo,
          bannerNovedadesImagen: $response.response.bannerNovedadesImagen,
          bannerCampaniasTitulo: $response.response.bannerCampaniasTitulo,
          bannerCampaniasImagen: $response.response.bannerCampaniasImagen,
          bannerReciclablesTitulo: $response.response.bannerReciclablesTitulo,
          bannerReciclablesImagen: $response.response.bannerReciclablesImagen,
          stickySocialTelActivo: $response.response.stickySocialTelActivo === '1' ? true : false,
          stickySocialTelTexto: $response.response.stickySocialTelTexto,
          stickySocialTelUrl: $response.response.stickySocialTelUrl,
          stickySocialWhatsappActivo: $response.response.stickySocialWhatsappActivo === '1' ? true : false,
          stickySocialWhatsappTexto: $response.response.stickySocialWhatsappTexto,
          stickySocialWhatsappUrl: $response.response.stickySocialWhatsappUrl,
          stickySocialFacebookActivo: $response.response.stickySocialFacebookActivo === '1' ? true : false,
          stickySocialFacebookTexto: $response.response.stickySocialFacebookTexto,
          stickySocialFacebookUrl: $response.response.stickySocialFacebookUrl,
          stickySocialInstagramActivo: $response.response.stickySocialInstagramActivo === '1' ? true : false,
          stickySocialInstagramTexto: $response.response.stickySocialInstagramTexto,
          stickySocialInstagramUrl: $response.response.stickySocialInstagramUrl,
          stickySocialTwitterActivo: $response.response.stickySocialTwitterActivo === '1' ? true : false,
          stickySocialTwitterTexto: $response.response.stickySocialTwitterTexto,
          stickySocialTwitterUrl: $response.response.stickySocialTwitterUrl,
          stickySocialYoutubeActivo: $response.response.stickySocialYoutubeActivo === '1' ? true : false,
          stickySocialYoutubeTexto: $response.response.stickySocialYoutubeTexto,
          stickySocialYoutubeUrl: $response.response.stickySocialYoutubeUrl,
          mensajeCompraActivo: $response.response.mensajeCompraActivo === '1' ? true : false,
          mensajeCompraTitulo: $response.response.mensajeCompraTitulo,
          mensajeCompraMensaje: $response.response.mensajeCompraMensaje,
          switchAltasActivo: $response.response.switchAltasActivo === '1' ? true : false,
          switchComprasActivo: $response.response.switchComprasActivo === '1' ? true : false,
          switchContactosActivo: $response.response.switchContactosActivo === '1' ? true : false,
          mensajeModalLoginActivo: $response.response.mensajeModalLoginActivo === '1' ? true : false,
          mensajeModalLoginMensaje: $response.response.mensajeModalLoginMensaje,
          mensajeModalRegistroActivo: $response.response.mensajeModalRegistroActivo === '1' ? true : false,
          mensajeModalRegistroMensaje: $response.response.mensajeModalRegistroMensaje,
          idProductoRecomendado: Number.parseInt($response.response.idProductoRecomendado, 10),
        };
        this.data.updateConfiguracion(c);
      }
    })
    .catch(($error: any) => {
      this.data.log('problemas con la configuracion compra');
      this.data.log('getconfiguracion error compra', $error);
    });

    setTimeout(() => {
      if (this.inputCantidad && this.inputCantidad?.nativeElement) {
        this.inputSub = fromEvent(this.inputCantidad?.nativeElement, 'input')
        .pipe(debounceTime(300), takeUntil(this.destroy$)).subscribe(
          () => {
            const body = new URLSearchParams();
            const array = [];
            for (const item of this.carrito.lista) {
              if (item.cantidad > 0) {
                array.push({id_producto: item.id, cantidad: item.cantidad});
              }
            }
            body.set('lista', JSON.stringify(array));

            this.auth.post('carrito/update_cantidades', body)
            .then(($response) => {
              this.data.log('response carritoupdatecantidades compra debounced', $response);

              this.submitCupon();
            })
            .catch(($error) => {
              this.data.log('error carritoupdatecantidades compra debounced', $error);

              this.submitCupon();
            });
          },
        );
      }
    }, 2000);
  }

  public seleccionarTransporte($herramienta: any, $codigo: any) {
    if ($herramienta) {
      const item = $herramienta.itemsList._items.find(($item: any) => $item.value === $codigo);
      if (item) {
        $herramienta.select(item);
      }
    }
  }
  public seleccionarProvincia($herramienta: any, $codigo: any) {
    if ($herramienta) {
      const item = $herramienta.itemsList._items.find(($item: any) => $item.value === $codigo);
      if (item) {
        $herramienta.select(item);
      }
    }
  }

  removeCompraItem($item: any) {
    const body = new URLSearchParams();
    body.set('id_producto', $item.id);
    this.auth.post('carrito/eliminar_item', body)
    .then(($response) => {
      if (environment.production) {
        this.googleAnalyticsService.removeFromCart({ sku: $item.sku, descripcion: $item.descripcion, categoria: $item.categoria, precio: $item.precio, cantidad: $item.cantidad });
      }
      this.data.log('response carritoeliminaritem compra', $response);
      this.data.removeMessage($item);
      this.updateValue();

      this.submitCupon();
    })
    .catch(($error) => {
      this.data.log('error carritoeliminaritem compra', $error);

      this.submitCupon();
    });
  }
  updateValue() {
    let $carrito = 0;
    this.carrito.ventaSoloPorPedido = false;
    for (const c of this.carrito.lista) {
      const precio = c.precio;
      const cantidad = c.cantidad;
      $carrito += precio * cantidad;
      if (c.ventaSoloPorPedido) {
        this.carrito.ventaSoloPorPedido = true;
      }
    }
    this.carrito.subtotal = $carrito;

    this.submitCupon();
  }
  updateValueDelayed() {
    setTimeout(() => {
      let $carrito = 0;
      for (const c of this.carrito.lista) {
        const precio = c.precio;
        const cantidad = c.cantidad;
        $carrito += precio * cantidad;
        if (c.ventaSoloPorPedido) {
          this.carrito.ventaSoloPorPedido = true;
        }
      }
      this.carrito.subtotal = $carrito;

      this.submitCupon();
    }, 200);
  }
  updatePrecio($precio: any, $cantidad: any): string {
    const subtotal = $precio * $cantidad;
    return this.corregirPrecio(subtotal);
  }
  formatMoney(n: any, c: any = undefined, d: any = undefined, t: any = undefined) {
    let s;
    let i;
    let j;
    c = isNaN(c = Math.abs(c)) ? 2 : c,
    d = d == undefined ? ',' : d,
    t = t == undefined ? '.' : t,
    s = n < 0 ? '-' : '',
    i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
    j = (j = i.length) > 3 ? j % 3 : 0;

    return s + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) +
    (c ? d + Math.abs(n - +i).toFixed(c).slice(2) : '');
  }
  corregirPrecio($precio: any): string {
  return this.formatMoney($precio);
  }
  updateTotal($precio: any): string {
    const subtotal = $precio;
    return this.formatMoney(subtotal);
  }
  selectedForma($input: any) {
    this.datosCompra.entrega = $input;
  }
  datos_envio_error: string = '';
  addTransporte() {
    if (this.cargarTransporte) {
      this.datostransporte.add().then((response: any) => {
        this.datos_envio_error = '';
        this.cargarTransporte = false;
        this.data.log('addtransporte response compra:', response);
        const temp_transporte = {
          id: response['codigo'],
          text: this.datostransporte.form.nombreTransporte,
        };
        this.entrega.transporte.lista.push(temp_transporte);
        this.initialLista = [temp_transporte];
        this.refreshTransporte(temp_transporte);
      }).catch((error: any) => {
        Object.values(error.error.response).forEach(($error_item) => this.datos_envio_error += $error_item);
      });
      // this.transaccion.cambio(2)
    } else {
      this.datostransporte.update().then((response: any) => {
        this.datos_envio_error = '';
        this.editingEnvio = false;
      }).catch((error: any) => {
        if (error.error) {
          Object.values(error.error.response).forEach(($error_item) => this.datos_envio_error += $error_item);
        } else {
          this.datos_envio_error = 'Hubo un problema en la carga de los datos, aguarde un momento y vuelva a intentar.';
        }
    });

    }
  }
  delay(ms: any) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  carritoLoading: boolean = false;

  async printAll() {
    this.transaccion.cambio(1);
  }

  // Finalizar Compra
  async finalizarCompra() {
    if (!this.cuponLoading) {
      await this.submitCupon();
      await this.auth.getCarrito(true);
      if (this.carrito.lista.length) {
        // this.carritoLoading = true;
        this.modalLoading = true;

        const body = new URLSearchParams();
        const array = [];
        for (const item of this.carrito.lista) {
          if (item.cantidad > 0) {
            array.push({id_producto: item.id, cantidad: item.cantidad});
          }
        }
        body.set('lista', JSON.stringify(array));

        this.auth.post('carrito/update_cantidades', body)
        .then(($response) => {
          this.data.log('response carritoupdatecantidades compra', $response);
          if ((this.carrito.subtotal < this.config.montoEnvio || this.isDefaultRetiro) && this.datosCompra.agregado !== '1') {
            this.datosCompra.entrega = '1';
          } else {
            this.datosCompra.entrega = '0';
          }
          this.location.go('/compra/envio');
          this.data.rutaActual = '/compra/envio';
          this.printAll();

          this.carritoLoading = false;
          this.modalLoading = false;
        })
        .catch(($error) => {
          this.data.log('error carritoupdatecantidades compra', $error);

          this.carritoLoading = false;
          this.modalLoading = false;
        });
      }
    }
  }
  isChecked: any = {
    entrega_lunes:     false,
    entrega_martes:    false,
    entrega_miercoles: false,
    entrega_jueves:    false,
    entrega_viernes:   false,
    entrega_sabado:    false,
  };
  checkValue($value: any, $dia: string) {
    this.datos_envio[$dia] = $value;
  }
  clicked($dia: any) {
    this.isChecked[$dia] = !this.isChecked[$dia];
    this.datos_envio[$dia] = this.isChecked[$dia] ? 1 : 0;

  }
  texto_busqueda: string = '';
  ResultadoBusqueda: any;
  buscarPalabra(event: any) {
    if (event.target.value.length >= 3) {
      const body = new URLSearchParams();
      body.set('frase', this.texto_busqueda);
      this.auth.post('producto/busqueda', body)
      .then(($response) => {
          this.ResultadoBusqueda = $response.body.response.slice(0, 6);
      })
      .catch((error) => this.data.log('buscarpalabra error compra:', error));
    }
  }
  enterBusqueda(event: any) {
    if (event.keyCode == 13) {
      this.buscarTexto();
      // this.cerrarBusqueda() // No usar
    }
  }
  buscarTexto() {
    this.texto_busqueda = this.texto_busqueda.replaceAll('/', ' ');
    if (environment.production) {
      this.googleAnalyticsService.search(this.texto_busqueda);
      this.facebookPixelService.search(this.texto_busqueda);
    }
    let arraySecciones = [
      'ofertas',
      'novedades'
    ];
    let arrayFamilias = [
      'ofertas',
      'novedades',
      'limpieza',
      'bazar',
      'textil',
      'liquidos', 'líquidos',
      'jardin y riego', 'jardín y riego',
      'profesional',
      'mas productos', 'más productos'
    ]

    if (arraySecciones.indexOf(this.texto_busqueda.toLowerCase()) !== -1) {
      let toUrl = this.texto_busqueda.toLowerCase();
      this.router.navigateByUrl('/', {skipLocationChange: true})
      .then(() => {
        this.router.navigate(['/' + toUrl]);
      });
    } else if (arrayFamilias.indexOf(this.texto_busqueda.toLowerCase()) !== -1) {
      let toUrl = this.texto_busqueda.charAt(0).toUpperCase() + this.texto_busqueda.slice(1).toLowerCase();
      toUrl = toUrl.replaceAll('á', 'a');
      toUrl = toUrl.replaceAll('é', 'e');
      toUrl = toUrl.replaceAll('í', 'i');
      toUrl = toUrl.replaceAll('ó', 'o');
      toUrl = toUrl.replaceAll('ú', 'u');
      this.router.navigateByUrl('/', {skipLocationChange: true})
      .then(() => {
        this.router.navigate(['/' + toUrl]);
      });
    } else {
      let toUrl = this.texto_busqueda;
      this.router.navigateByUrl('/', {skipLocationChange: true})
      .then(() => {
        this.router.navigate(['/busqueda/' + toUrl]);
      });
    }
  }
  cerrarBusqueda(msg: any) {
    const precio = msg.precio;
    this.data.changeMessage(msg.cantidad ? msg.cantidad : 1, msg.nombreMostrar || msg.titulo, msg.tituloAdicional, precio, precio * (+msg.cantidad), msg.id,
      msg.codInterno, (msg.categorias && msg.categorias.length > 0) ? msg.categorias[0].nombre : '', msg.cantPack, msg.oferta, msg.urlImagen, msg.ventaSoloPorPedido == '1');
    this.ResultadoBusqueda = [];
  }
  descargarPedido($pedido: any) {
    this.data.log('descargarpedido pedido compra:', $pedido);
  }

  checkCarritoInit($value?: any) {
    if ($value === 0) {
      this.carritoLoading = true;
      this.carrito.lista = this.data.lista;
      this.updateValue();
      if (this.data.rutaActual != '/compra/finalizada') {
        if (environment.production) {
          this.googleAnalyticsService.beginCheckout(this.carrito.lista);
          this.facebookPixelService.beginCheckout(this.carrito.lista);
        }
        this.data.rutaActual = '/compra/carrito';
        this.location.go('/compra/carrito');

        setTimeout(() => {
          if (this.inputCantidad && this.inputCantidad?.nativeElement) {
            this.inputSub = fromEvent(this.inputCantidad?.nativeElement, 'input')
            .pipe(debounceTime(300), takeUntil(this.destroy$)).subscribe(
              () => {
                const body = new URLSearchParams();
                const array = [];
                for (const item of this.carrito.lista) {
                  if (item.cantidad > 0) {
                    array.push({id_producto: item.id, cantidad: item.cantidad});
                  }
                }
                body.set('lista', JSON.stringify(array));

                this.auth.post('carrito/update_cantidades', body)
                .then(($response) => {
                  this.data.log('response carritoupdatecantidades compra debounced', $response);

                  this.submitCupon();
                })
                .catch(($error) => {
                  this.data.log('error carritoupdatecantidades compra debounced', $error);

                  this.submitCupon();
                });
              },
            );
          }
        }, 2000);
      }
      this.carritoLoading = false;
    } else {
      this.carritoLoading = true;
      this.carrito.lista = this.data.lista;
      this.updateValue();
      this.carritoLoading = false;
    }
  }

  closeModal(event: string) {
    this.modalLoading = false;
  }

  toggleEditingEnvio() {
    this.editingEnvio = !this.editingEnvio;

    if (this.editingEnvio) {
      setTimeout(() => {
        if (this.datos_envio.cod_transporte) {
          this.seleccionarTransporte(this.ngSelectTransporte, this.datos_envio.cod_transporte);
        }
        if (this.datos_envio.domicilio_provincia) {
          this.seleccionarProvincia(this.ngSelectProvincia, this.datos_envio.domicilio_provincia);
        }
      }, 500);
    }
  }

  enterBusquedaAgregado(event: any) {
    if (event.keyCode == 13) {
      this.finalizarCompraAgregado();
    }
  }
  async finalizarCompraAgregado() {
    if (!this.cuponLoading) {
      this.showErrorAgregado = false;
      this.responseErrorAgregado = '';

      if (this.idPedidoAgregado && this.idPedidoAgregado > 0) {
        await this.submitCupon();
        await this.auth.getCarrito(true);
        if (this.carrito.lista.length) {
          // this.carritoLoading = true;
          this.modalLoading = true;

          const body = new URLSearchParams();
          const array = [];
          for (const item of this.carrito.lista) {
            if (item.cantidad > 0) {
              array.push({id_producto: item.id, cantidad: item.cantidad});
            }
          }
          body.set('lista', JSON.stringify(array));

          this.auth.post('carrito/update_cantidades', body)
          .then(($response) => {
            this.data.log('response carritoupdatecantidades compraagregado', $response);
            const body2 = new URLSearchParams();
            body2.set('id_pedido', JSON.stringify(this.idPedidoAgregado));
            this.auth.post('pedido/busqueda_agregado', body2)
            .then(($response2) => {
              this.data.log('response busquedaagregado compraagregado', $response2);

              if ($response2.body.status) {
                this.responseSuccessAgregado = $response2.body.response;
                this.responseSuccessAgregado.fechaPedido = this.responseSuccessAgregado.fechaPedido.date.split(' ')[0].split('-').reverse().join('/');

                this.datosCompra.agregado = '1';
                this.datosCompra.entrega = '0';

                this.location.go('/compra/envio');
                this.data.rutaActual = '/compra/envio';
                this.printAll();
              } else {
                this.showErrorAgregado = true;
                this.responseErrorAgregado = $response2.body.response;
              }
              this.carritoLoading = false;
              this.modalLoading = false;
            })
            .catch(($error2) => {
              this.data.log('error busquedaagregado compraagregado', $error2);
              this.carritoLoading = false;
              this.modalLoading = false;
            });
          })
          .catch(($error) => {
            this.data.log('error carritoupdatecantidades compraagregado', $error);
            this.carritoLoading = false;
            this.modalLoading = false;
          });
        }
      } else {
        this.showErrorAgregado = true;
        this.responseErrorAgregado = 'No se ingresó un ID de pedido válido.';
      }
    }
  }

  public revisarCantidad(e: any) {
    if (e.target && parseInt(e.target.value) < parseInt(e.target.min)) {
      e.target.value = e.target.min;
    }
  }

  slideActivate(slideEvent: NgbSlideEvent) {
    if (this.ngCarouselUno || this.ngCarouselDos) {
      if (slideEvent && slideEvent.source === NgbSlideEventSource.TIMER) {
        this.autoSlideEventCount++;
      } else {
        this.autoSlideEventCount = -1;
      }
    }
  }
  slideSwipeLeft(e: any) {
    this.autoSlideEventCount = -1;
    if (this.ngCarouselUno) {
      this.ngCarouselUno.next();
    }
    if (this.ngCarouselDos) {
      this.ngCarouselDos.next();
    }
  }
  slideSwipeRight(e: any) {
    this.autoSlideEventCount = -1;
    if (this.ngCarouselUno) {
      this.ngCarouselUno.prev();
    }
    if (this.ngCarouselDos) {
      this.ngCarouselDos.prev();
    }
  }
  slideMouseenter(e: any) {
    this.autoSlideEventCount = -1;
  }
  slideMouseleave(e: any) {
    this.autoSlideEventCount = -1;
  }

  reloadPage() {
    window.location.href = window.location.href;
  }
}

@Pipe({
  name: "sortInt"
})
export class ArraySortIntPipe  implements PipeTransform {
  transform(array: any, field: string): any[] {
    if (!Array.isArray(array)) {
      return [];
    }
    array.sort((a: any, b: any) => {
      return parseInt(a[field], 10) - parseInt(b[field], 10);
    });
    return array;
  }
}

@Pipe({
  name: "sort"
})
export class ArraySortPipe  implements PipeTransform {
  transform(array: any, field: string, field2?: string, field3?: string): any[] {
    if (!Array.isArray(array)) {
      return [];
    }
    array.sort((a: any, b: any) => {
      if (a[field] < b[field]) return -1;
      if (a[field] > b[field]) return 1;
      if (field2) {
        if (a[field2] < b[field2]) return -1;
        if (a[field2] > b[field2]) return 1;
      }
      if (field3) {
        if (a[field3] < b[field3]) return -1;
        if (a[field3] > b[field3]) return 1;
      }
      return 0;
    });
    return array;
  }
}

@Pipe({
  name: "sortProductos"
})
export class ArraySortProductosPipe  implements PipeTransform {
  transform(array: any, field: string, field2?: string, field3?: string): any[] {
    if (!Array.isArray(array)) {
      return [];
    }
    array.sort((a: any, b: any) => {
      if (a.producto[field] < b.producto[field]) return -1;
      if (a.producto[field] > b.producto[field]) return 1;
      if (field2) {
        if (a.producto[field2] < b.producto[field2]) return -1;
        if (a.producto[field2] > b.producto[field2]) return 1;
      }
      if (field3) {
        if (a.producto[field3] < b.producto[field3]) return -1;
        if (a.producto[field3] > b.producto[field3]) return 1;
      }
      return 0;
    });
    return array;
  }
}
