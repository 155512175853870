import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, Pipe, PipeTransform, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AutenticacionService } from '../autenticacion.service';
import { Producto, ProductoCompleto } from '../data';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { FacebookPixelService } from '../facebook-pixel.service';
import { SharedService } from '../shared.service';
import { DomSanitizer } from '@angular/platform-browser';
import { register, SwiperContainer } from 'swiper/element/bundle';
register();

@Component({
  selector: 'app-producto',
  templateUrl: './producto.component.html',
  styleUrls: ['./producto.component.scss']
})
export class ProductoComponent implements OnInit, AfterViewInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  config: any;
  public _success = new Subject<string>();
  public iva_usuario: string = '';
  staticAlertClosed = false;
  successMessage!: string;
  loginStatus: boolean = false;
  fullPath: any[] = [];
  producto: ProductoCompleto = new ProductoCompleto();
  relacionados!: Producto[];
  arrayCants: any[] = [];

  full_link: string = '';

  tituloProducto: string = '';

  clientOnlyRun: boolean = false;
  serverOnlyRun: boolean = false;

  constructor(
    private data: SharedService,
    // private http: HttpClient,
    private route: ActivatedRoute,
    private auth: AutenticacionService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private facebookPixelService: FacebookPixelService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.clientOnlyRun = true;
    } else {
      this.serverOnlyRun = true;
    }
  }
  respuesta = ($response: any)  => {
    this.relacionados = $response.response.productosRelacionados;
    this.producto = $response.response;
    const categoria = (this.producto['categorias'] && this.producto['categorias'].length) ? this.producto['categorias'][0] : '';
    const categoriaPadre = (categoria && categoria['padre']) ? categoria['padre'] : '';
    this.full_link = environment.APP_WEB_BASE + (categoriaPadre ? categoriaPadre.nombre.split(' ').join('-') : 'Categoria') + '/'
      + (categoria ? categoria.nombre.split(' ').join('-') : 'Subcategoria') + '/' + this.producto['titulo'] + '/' + this.producto['id'];
    this.producto.cantidad = this.producto['cantSugerida'] ? parseInt(this.producto['cantSugerida']) : 1;
    if (this.producto['cantPack'] !== '1') {
      this.producto.cantidad = this.producto['cantPack'] ? parseInt(this.producto['cantPack']) : 1;
      for (let i = 0; i < 20; i++) {
        this.arrayCants[i] = this.producto.cantidad * (i + 1);
      }
      for (let i = 0; i < 50; i++) {
        this.arrayCants[i + 20] = this.producto.cantidad * (i + 3) * 10;
      }
    }

    this.producto.comprado = false;
    this.fullPath = ['Limpieza', categoria ? categoria.padre.nombre : '', categoria ? categoria.nombre : ''];

    this.producto.precio_mostrado = this.producto.precio ? this.producto.precio.toString().replace(',', '.') : '0';
    this.producto.precio_base = this.producto.precioLista || this.producto.precioListaBase;
    if (this.producto.precio_base > 0 && this.producto.precio_base > this.producto.precio) {
      this.producto.descuento = this.calcularDescuento(this.producto.precio_base, this.producto.precio_mostrado);
      // Decidimos si mostrar el descuento a partir numero mayor o igual a 5% OFF
      if (this.producto.descuento >= 5) {
        this.producto.show_descuento = true;
      } else {
        this.producto.show_descuento = false;
      }
    } else {
      this.producto.descuento = undefined;
      this.producto.show_descuento = false;
    }

    this.data.lista.forEach((articulo_carrito) => {
      if (articulo_carrito.id === this.producto.id) {
        this.producto.comprado = true;
      }
    });
    if (environment.production) {
      this.googleAnalyticsService.viewItem({ sku: this.producto.codInterno, descripcion: this.producto.titulo, categoria: this.producto.categorias ? this.producto.categorias[0]?.nombre : undefined, precio: this.producto.precio, cantidad: this.producto.cantidad });
      this.facebookPixelService.viewItem({ sku: this.producto.codInterno, descripcion: this.producto.titulo, categoria: this.producto.categorias ? this.producto.categorias[0]?.nombre : undefined, precio: this.producto.precio, cantidad: this.producto.cantidad });
    }
  }
  ngOnInit(): void {
    this.data.updatePageTitle(environment.APP_NAME, 'Comprá con entrega a todo el país, hacé tu pedido online!');
    // subscribing to data on loginStatus
    this.data.currentLogin.pipe(takeUntil(this.destroy$)).subscribe(
      (status) => {
        this.loginStatus = status;
        this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
          const id = (+params['id2']);
          const id2 = (params['id']);
          this.tituloProducto = id2 + ', ' + id2 + ' al por mayor';
          this.data.updatePageTitle(id2 + ', ' + id2 + ' al por mayor | '+environment.APP_DOMAIN, 'Encontrá la mayor variedad de ' + id2 + ' al por mayor y al mejor precio en '+environment.APP_DOMAIN);
          // Client only code.
          if (this.clientOnlyRun) {
            const $public = this.loginStatus ? '' : 'public/';
            this.auth.get($public + 'producto/detalles/' + id)
            .then(this.respuesta)
            .catch(($error) => {
              this.data.log('currentloginstatus error producto', $error);
            });
          }
        });
      },
    );
    // Client only code.
    if (this.clientOnlyRun) {
      setTimeout(() => this.staticAlertClosed = true, 5000);
      this._success.pipe(takeUntil(this.destroy$)).subscribe((message) => this.successMessage = message);
      this._success.pipe(takeUntil(this.destroy$), debounceTime(5000)).subscribe(() => this.successMessage = '');
    }

    this.data.currentUser.pipe(takeUntil(this.destroy$)).subscribe(($user: any) => {
      if ($user && $user['c'] === '1') {
        this.iva_usuario = '';
      } else {
        if ($user) {
          switch ($user['codCategoriaIva']) {
            case 'CF': this.iva_usuario = 'UNIT I.V.A. incluido'; break;
            case 'INR': this.iva_usuario = 'UNIT I.V.A. incluido'; break;
            case 'RS': this.iva_usuario = 'UNIT I.V.A. incluido'; break;
            case 'RSS': this.iva_usuario = 'UNIT I.V.A. incluido'; break;
            case 'RI': this.iva_usuario = 'UNIT + I.V.A.'; break;
            case 'EX': this.iva_usuario = 'UNIT I.V.A. incluido'; break;
            case 'PCE': this.iva_usuario = 'UNIT I.V.A. incluido'; break;
            case 'PCS': this.iva_usuario = 'UNIT I.V.A. incluido'; break;
            case 'EXE': this.iva_usuario = 'UNIT Final'; break;
            case 'SNC': this.iva_usuario = 'UNIT I.V.A. incluido'; break;
            default: this.iva_usuario = 'UNIT + I.V.A.';
          }
        }
      }
    });

    // subscribing to config change
    this.data.currentConfig.pipe(takeUntil(this.destroy$)).subscribe(
      (configuracion: any) => {
        this.config = configuracion;
      },
    );
  }

  ngAfterViewInit() {
    // Client only code.
    if (this.clientOnlyRun) {
      let fotosIntervalAttemptsCount = 0;
      const intervalId = setInterval(() => {
        if (fotosIntervalAttemptsCount > 9) {
          clearInterval(intervalId);
        }

        // Inicializo Swiper
        try {
          // swiper element
          const swiperThumbnailsEl: SwiperContainer | null = document.querySelector('#producto-swiper-thumbnails');
          const swiperEl: SwiperContainer | null = document.querySelector('#producto-swiper-imagenes');

          if (swiperThumbnailsEl && swiperEl) {
            clearInterval(intervalId);
            // thumbnails swiper parameters
            const swiperThumbnailsParams = {
              init: true,
              scrollbar: false, // TODO: podria estar si ajustamos el css
              observeParents: true,
              observeSlideChildren: true,
              observer: true,
              slidesPerView: 'auto',
              spaceBetween: 5,
              freeMode: true,
              centerInsufficientSlides: true,
            }
            // swiper parameters
            const swiperParams = {
              init: true,
              navigation: true,
              pagination: false,
              scrollbar: false,
              slidesPerView: 1,
              // breakpoints: {
              //   768: {
              //     slidesPerView: 2,
              //   },
              //   992: {
              //     slidesPerView: 4,
              //   },
              // },
              observeParents: true,
              observeSlideChildren: true,
              observer: true,
              // on: {
              //   init() {
              //     // ...
              //   },
              // },
              autoHeight: true,
              thumbs: {
                swiper: swiperThumbnailsEl,
              },
            };

            // now we need to assign all parameters to Swiper element
            Object.assign(swiperThumbnailsEl, swiperThumbnailsParams);
            Object.assign(swiperEl, swiperParams);

            // and now initialize it
            swiperThumbnailsEl.initialize();
            swiperEl.initialize();
          } else {
            this.data.log('Product Swiper not found');
          }
        } catch (error) {
          this.data.log('Product Swiper initialization error:', error);
        }

        fotosIntervalAttemptsCount++;
      }, 200);
    }
  }

  calcularDescuento(precioBase: number, precioMostrado: number): number {

    const cleanPrecioBase = Number(precioBase.toString().replace(/[^0-9.-]+/g,""));
    const cleanPrecioMostrado = Number(precioMostrado.toString().replace(/[^0-9.-]+/g,""));

    return Math.floor((cleanPrecioBase - cleanPrecioMostrado) / cleanPrecioBase * 100);
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  alertClicked() {
    this.successMessage = '';
    this.data.toggleCarritoShow();
  }

  enterCheck() {
    this.newMessage(this.producto);
  }

  newMessage(msg: any) {
    if (this.loginStatus === true) {
      if (msg.cantidad) {
        if ((+msg.cantidad % +msg.cantPack === 0 && +msg.cantidad > +msg.cantMinima) || (+msg.cantMinima === +msg.cantidad)) {
          const body = new URLSearchParams();
          body.set('id_producto', msg.id);
          body.set('cantidad', msg.cantidad);
          this.auth.post('carrito/agregar_item', body)
          .then(($response) => {
            if (environment.production) {
              this.googleAnalyticsService.addToCart({ sku: msg.codInterno, descripcion: msg.titulo, categoria: msg.categorias ? msg.categorias[0]?.nombre : undefined, precio: msg.precio, cantidad: msg.cantidad });
              this.facebookPixelService.addToCart({ sku: msg.codInterno, descripcion: msg.titulo, categoria: msg.categorias ? msg.categorias[0]?.nombre : undefined, precio: msg.precio, cantidad: msg.cantidad });
            }
            this.data.log('response carritoagregaritem producto', $response);

            const response = this.data.addMessage(msg);
            if (response.value) {
              this._success.next(response.text);
            }
          })
          .catch(($error) => {
            this.data.log('error carritoagregaritem producto', $error);
          });
        } else {
          msg['incompleto'] = true;
        }
      }
    } else {
      this.data.toggleLoginModal();
    }
  }
  removeMessage(msg: any) {
    if (this.loginStatus === true) {
      const body = new URLSearchParams();
      body.set('id_producto', msg.id);
      this.auth.post('carrito/eliminar_item', body)
      .then(($response) => {
        if (environment.production) {
          this.googleAnalyticsService.removeFromCart({ sku: msg.codInterno, descripcion: msg.titulo, categoria: msg.categorias ? msg.categorias[0]?.nombre : undefined, precio: msg.precio, cantidad: msg.cantidad });
        }
        this.data.log('response carritoeliminaritem compra', $response);
        this.data.removeMessage(msg);
        msg.comprado = false;
      })
      .catch(($error) => {
        this.data.log('error carritoeliminaritem compra', $error);
      });
    } else {
      this.data.toggleLoginModal();
    }
  }

  public revisarCantidad(e: any) {
    if (e.target && parseInt(e.target.value) < parseInt(e.target.min)) {
      e.target.value = e.target.min;
    }
  }

}

@Pipe({
  name: 'safeHtmlProducto',
})
export class SafeHtmlPipeProducto implements PipeTransform {
  constructor(private sanitizer: DomSanitizer){}
  transform(html: any) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
@Pipe({
  name: 'safeUrlProducto',
})
export class SafeUrlPipeProducto implements PipeTransform {
  constructor(private sanitizer: DomSanitizer){}
  transform(value: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }
}
